import { Grid, Typography } from "@mui/material"
import logoBranco from "assets/Images/Logo/logo_branco.svg";
import { PiUsersThree } from "react-icons/pi";
import { HiDocumentDuplicate, HiOutlineMail } from "react-icons/hi";
import { LuWallet } from "react-icons/lu";
import { TbLogout2 } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import * as Styled from "./Sidebar.styled";
import { MdOutlineDiscount } from "react-icons/md";
import { useDispatch } from "react-redux";
import { logout } from "reducers/authentication";
import Moedas from "../../assets/Images/Moeda/moedas.svg"
import UsuariosIcon from "../../assets/Images/Moeda/usuario.svg"

export const Sidebar = () => {
    const navigate = useNavigate();

    const location = useLocation();

    const dispatch = useDispatch();

    const sair = () => {
        dispatch(logout());
        navigate("/")
    }

    return (
        <Styled.SidebarGrid xs={2}>
            <img src={logoBranco} />

            <Grid marginTop="80px">
                <Styled.ItemLista ativo={location.pathname === "/"} container onClick={() => navigate("/")}>
                    <PiUsersThree fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>B2B</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/b2c"} container onClick={() => navigate("/b2c")}>
                    <PiUsersThree fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>B2C</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/faturamento"} container onClick={() => navigate("/faturamento")}>
                    <LuWallet fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Faturamento</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/orcamentos"} container onClick={() => navigate("/orcamentos")}>
                    <HiOutlineMail fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Orçamentos</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/cupom"} container onClick={() => navigate("/cupom")}>
                    <MdOutlineDiscount fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Cupom</Typography>
                </Styled.ItemLista>
                
                <Styled.ItemLista ativo={location.pathname === "/usuarios"} container onClick={() => navigate("/usuarios")}>
                <img src={UsuariosIcon} />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Usuários</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/proposta-redacao"} container onClick={() => navigate("/proposta-redacao")}>
                    <HiDocumentDuplicate fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={500}>Texto Motivador</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/vestibulares"} container onClick={() => navigate("/vestibulares")}>
                    <HiDocumentDuplicate fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={500}>Vestibulares</Typography>
                </Styled.ItemLista>


                <Styled.ItemLista ativo={location.pathname === "/moedasb2c"} container onClick={() => navigate("/moedasb2c")}>
                    <img src={Moedas} />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Moedas B2C</Typography>
                </Styled.ItemLista>

                <Styled.ItemLista ativo={location.pathname === "/moedasb2b"} container onClick={() => navigate("/moedasb2b")}>
                    <img src={Moedas} />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Moedas B2B</Typography>
                </Styled.ItemLista>
                
            </Grid>
            <Grid mt="50px">
                <Styled.Sair container onClick={sair}>
                    <TbLogout2 fontSize="30px" />
                    <Typography fontSize="16px" marginLeft="10px" fontWeight={700}>Sair</Typography>
                </Styled.Sair>
            </Grid>
        </Styled.SidebarGrid>
    )
}