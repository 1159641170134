import { CupomChat, CupomPagamento } from "api/models/cupom";
import { AdmUsuarioEditDTO } from "api/models/Usuarios";
import { PlanoUsuarioGestao } from "api/models/gestao";
import { BoletoRequest, MoedaAlunoDTO, NotaMediaRequest, ObterAlunosNaoCadastradoRequest, PageRequest, TextosMotivadoresRequest, PaginationHeaders,Response } from "api/models/request";
import { EnviarArquivo, TextoMotivadorDTO } from "api/models/textoMotivador";
import { Vestibular } from "api/models/vestibular";
import { apiBackEnd } from "api/services/axiosApiCria";
import { SecretariaProps } from "pages/Dashboard/ModalAdicionarSecretaria/ModalAdicionarSecretaria.types";
import { AtualizarMoedasDTO } from "api/models/UsuarioMoeda";

export const adicionarGestao = async (gestao: SecretariaProps) => await apiBackEnd.post("admin/gestao", gestao);

export const obterListaGestao = async () => await apiBackEnd.get("admin/gestao");

export const calcularPlanos = async (plano: PlanoUsuarioGestao) => await apiBackEnd.post("admin/calculo-plano-gestao", plano);

export const gerarOrcamento = async (plano: PlanoUsuarioGestao) => await apiBackEnd.post("admin/orcamento", plano);

export const obterOrcamentos = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/orcamento", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const obterGridTodosAlunosUsuarios = async (request: ObterAlunosNaoCadastradoRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/obeter-todos-os-alunos-usuarios", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const obterGridTodosEscolaUsuarios = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/obeter-todos-os-escola-usuarios", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const obterGridTodosProfessoresUsuarios = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/obeter-todos-os-professores-usuarios", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}
export const obterGridTodosUsuariosGestao = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/obeter-todos-os-usuarios-gestao", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}
export const obterOrcamentoCalculo = async (id: number) => await apiBackEnd.get(`admin/orcamento/${id}`);

export const obterPagamentos = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/pagamento-gestao", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const gerarPagamentoGestao = async (idOrcamento: number, boleto: BoletoRequest) => await apiBackEnd.post(`admin/pagamento-gestao/${idOrcamento}`, boleto);

export const obterCuponsChat = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/obter-cupom-chat", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const obterCupons = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/cupom-pagamento", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const adicionarCupom = async (cupom: CupomPagamento) => await apiBackEnd.post("admin/cupom-pagamento", cupom)

export const adicionarCupomChat = async (cupom: CupomChat) => await apiBackEnd.post("admin/adicionar-cupom-chat", cupom)

export const atualizarCupom = async (idCupom: number, ativo: boolean, tipo: string) => await apiBackEnd.put(`admin/atualizar-cupom`, { idCupom, ativo, tipo });

export const enviarEmailBoleto = async (idGestao: number, idTransacao: string) => await apiBackEnd.post(`admin/email-pagamento-gestao/${idGestao}/${idTransacao}`);

export const obterEscolasGestao = async () => await apiBackEnd.get("admin/escola-gestao");

export const obterEscolasSolo = async () => await apiBackEnd.get("admin/escola-solo");

export const obterQuantidadeRedacoesCorrigidasProfessor = async (ano: number, idGestao: number) => await apiBackEnd.get(`admin/corrigidas-professor/${ano}/${idGestao}`);

export const ObterInfiGrafico = async (filtro: number) => await apiBackEnd.get(`admin/grafico-uso-moeda-aluno/${filtro}`);

export const ObterInfoGraficoRedacaoSimpleseCompleta = async () => await apiBackEnd.get(`admin/grafico-redacoes-simples-completas-alunos`);

export const obterInfoAlunoUsario = async (idUsu: number) => {
    const { data } = await apiBackEnd.get(`admin/pegando-dados-alunos/${idUsu}`);

    return data;
}

export const editarUsuAluno = async (aluno: AdmUsuarioEditDTO) => 
    await apiBackEnd.put(`admin/editar-usuario`, aluno);

export const editarUsuEscolaAdm = async (escola: AdmUsuarioEditDTO) => 
    await apiBackEnd.put(`admin/editar-usuario-escolar`, escola);

export const editarUsuGestaoAdm = async (gestao: AdmUsuarioEditDTO) => 
    await apiBackEnd.put(`admin/editar-usuario-gestao`, gestao);

export const editarUsuarioProfessorAdm = async (professor: AdmUsuarioEditDTO) => 
    await apiBackEnd.put(`admin/editar-usuario-professor`, professor);


export const obterInfoEscolaUsario = async (idEscola: number) => {
    const { data } = await apiBackEnd.get(`admin/pegando-dados-escola/${idEscola}`);

    return data;
}

export const obterInfoProfessorUsario = async (idProfessor: number) => {
    const { data } = await apiBackEnd.get(`admin/pegando-dados-professor/${idProfessor}`);

    return data;
}

export const obterInfoGestaoUsario = async (idGestao: number) => {
    const { data } = await apiBackEnd.get(`admin/pegando-dados-gestao/${idGestao}`);

    return data;
}
export const arquivoAmazon = async (arquivoAmazon: EnviarArquivo) => 
    await apiBackEnd.post(`admin/upload-file-amazon/${arquivoAmazon.idTextoMotivador}`, arquivoAmazon.file);

export const adicionarTextoMotivador = async (textoMotivador: TextoMotivadorDTO) => 
    await apiBackEnd.post(`admin/texto-motivador-vestibular`, textoMotivador);

export const atualizarTextoMotivador = async (textoMotivador: TextoMotivadorDTO) => 
    await apiBackEnd.put(`admin/texto-motivador-vestibular-pdf`, textoMotivador);

export const inativarTextoMotivador = async (idTextoMotivador: number) =>
    await apiBackEnd.delete(`/professor/texto-motivador/${idTextoMotivador}`)

export const obterTodosVestibularesAtivos = async () => 
    await apiBackEnd.get('/vestibular-universidade/obter-vestibulares-universidades-ativos');

export const obterTodosVestibulares = async () => 
    await apiBackEnd.get('/vestibular-universidade/obter-todos-vestibulares-universidades');

export const obterTodosVestibularesPaginados = async (request: PageRequest) => {
    const { data, headers } = await apiBackEnd.get("vestibular-universidade/obter-todos", {
        params: { ...request }
    });
    const pagination = JSON.parse(headers["x-pagination"]);
    return {
        itens: data,
        config: pagination
    }
}

export const obterTodosTemasVestibular = async ({
    idDominioTema,
    idVestibular,
    ...params
}: TextosMotivadoresRequest ) => {
    const { data, headers } = await apiBackEnd.get(`admin/obter-todos-temas/${idDominioTema}/${idVestibular}`, {
        params: {
            ...params
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const enviaExcluirVestibular = async (id: number) => await apiBackEnd.delete(`vestibular-universidade/deletar/${id}`);

export const atualizarVinculoTextoMotivadorVestibular = async (idTextoMotivador: number, idVestibular: number, novoIdVestibular: number) => 
    await apiBackEnd.put(`admin/texto-motivador-vestibular/${idTextoMotivador}/${idVestibular}/${novoIdVestibular}`);

export const adicionarVestibular = async (vestibular: Vestibular) => 
    await apiBackEnd.post(`vestibular-universidade/adicionar`, vestibular);

export const editarVestibular = async (vestibular: Vestibular) => 
    await apiBackEnd.put(`vestibular-universidade/atualizar`, vestibular);

export const enviarProcessaPDF = async (idTextoMotivador: number) => await apiBackEnd.post(`/admin/enviar-processa-pdf/${idTextoMotivador}`);

export const ObterDadosMoedasPorAlunoAdmin = async (request: ObterAlunosNaoCadastradoRequest) => {
    const { data, headers } = await apiBackEnd.get("admin/moedas-alunos-admin", {
        params: {
            ...request
        }
    });

    const pagination = JSON.parse(headers["x-pagination"]);

    return {
        itens: data,
        config: pagination
    }
}

export const ObterUsuarioMoeda = async (idUsuario: any) => await apiBackEnd.get(`escola/moedas-usuario/${idUsuario}`)

export const useObterDadosUsuario = async () => {
    const { data } = await apiBackEnd.get("/usuarios/obter-dados");

    return data;
};

export const RemanejarMoedas = async (request: AtualizarMoedasDTO) =>
    await apiBackEnd.put(`/professor/moedas-remanejar`, request)

export const ObterEscolasAdmin = async () => await apiBackEnd.get("admin/obter-escola-admin");

export const ObterImfoEscolaPorId = async (idEscola: number) =>
    await apiBackEnd.get(`/admin/escola-idescola/${idEscola}`);

export const obterInfoAlunoAdmin = async () => await apiBackEnd.get("admin/obeter-info-cards-alunos-admin");