import { GridColDef } from "@mui/x-data-grid";
import { atualizarCupom, obterCupons, obterCuponsChat } from "api/controllers/admin";
import { EnumPerfilUsuario, EnumTipoCompra, EnumTipoDesconto } from "api/models/enum";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import { PageContainer } from "components/PageContainer"
import { TituloPrincipal } from "components/Titulos/Titulos.styled";
import { useEffect, useState } from "react";
import { ModalAdicionarCupom } from "./ModalAdicionarCupom/ModalAdicionarCupom";
import { Button } from "components/Button";
import { CircularProgress, Grid } from "@mui/material";
import { IconeAdd, IconeDelete } from "components/Icons/Icons";
import { ModalAlert } from "components/ModalAlert";
import { toast } from "react-toastify";
import * as Styled from "./Cupom.styled";

export const Cupom = () => {
    const [modalAdicionarCupom, setModalAdicionarCupom] = useState(false);

    const [modalAtivar, setModalAtivar] = useState(false);
    const [ativar, setAtivar] = useState(false);

    const [modalDesativar, setModalDesativar] = useState(false);
    const [desativar, setDesativar] = useState(false);

    const [idCupom, setIdCupom] = useState(0);
    const [tipoCupom, setTipoCupom] = useState("Desconto");

    useEffect(() => {
        const atualizarMetodo = async () => {
            if (!ativar && !desativar) return;

            try {
                await atualizarCupom(idCupom, ativar, tipoCupom);
                toast.success(ativar ? "Cupom ativado" : "Cupom desativado");
            } catch (erro: any) {
                toast.error(erro.response?.data?.message || "Erro ao atualizar cupom");
            } finally {
                setModalAtivar(false);
                setModalDesativar(false);
                setAtivar(false);
                setDesativar(false);
            }
        };

        atualizarMetodo();
    }, [ativar, desativar]);

    const [cupons, setCupons] = useState<{ itens: any, config: any }>();

    const columns: GridColDef[] = [
        {
            field: "dataCadastro",
            headerName: "Data",
            valueGetter: (e) => new Date(e.value).toLocaleDateString("pt-BR"),
            flex: 1,
        },
        {
            field: "cupom",
            headerName: "Cupom",
            flex: 1
        },
        {
            field: "idTipoDesconto",
            headerName: "Tipo desconto",
            valueFormatter: (valor) => EnumTipoDesconto[valor.value],
            flex: 1
        },
        {
            field: "ativo",
            headerName: "Status",
            renderCell: (valor) => <Styled.Status status={valor.value ? "Ativo" : "Inativo"}>{valor.value ? "Ativo" : "Inativo"}</Styled.Status>,
            flex: 1
        },
        {
            field: "usoUnico",
            headerName: "Uso único",
            valueFormatter: (valor) => valor.value ? "Sim" : "Não",
            flex: 1
        },
        {
            field: "multiplicacao",
            headerName: "Desconto / Multiplicação moeda",
            flex: 1
        },
        {
            field: "idTipoCupom",
            headerName: "Tipo compra",
            valueFormatter: (valor) => EnumTipoCompra[valor.value],
            flex: 1
        },
        {
            field: "idPerfil",
            headerName: "Perfil",
            valueFormatter: (valor) => EnumPerfilUsuario[valor.value],
            flex: 1
        },
        {
            field: "id",
            headerName: "Ações",
            flex: 1,
            renderCell: (e) => {
                return <>
                    {e.row.ativo && <IconeDelete onClick={() => { setModalDesativar(true); setIdCupom(e.value) }} />}
                    {!e.row.ativo && <IconeAdd onClick={() => { setModalAtivar(true); setIdCupom(e.value) }} />}
                </>
            }
        }
    ];

    const columnsChat: GridColDef[] = [
        {
            field: "dataCadastro",
            headerName: "Data",
            valueGetter: (e) => new Date(e.value).toLocaleDateString("pt-BR"),
            flex: 1,
        },
        {
            field: "cupom",
            headerName: "Cupom",
            flex: 1
        },
        {
            field: "ativo",
            headerName: "Status",
            renderCell: (valor) => <Styled.Status status={valor.value ? "Ativo" : "Inativo"}>{valor.value ? "Ativo" : "Inativo"}</Styled.Status>,
            flex: 1
        },
        {
            field: "idPerfil",
            headerName: "Perfil",
            valueFormatter: (valor) => EnumPerfilUsuario[valor.value],
            flex: 1
        },
        {
            field: "nome",
            headerName: "Nome",
            flex: 1,
            renderCell: (params) => params.value ? params.value : "Nenhum usuário vinculado ainda"
        },
        {
            field: "id",
            headerName: "Ações",
            flex: 1,
            renderCell: (e) => {
                return <>
                    {e.row.ativo && <IconeDelete onClick={() => { setModalDesativar(true); setIdCupom(e.value) }} />}
                    {!e.row.ativo && <IconeAdd onClick={() => { setModalAtivar(true); setIdCupom(e.value) }} />}
                </>
            }
        }
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: cupons?.itens,
        columns: tipoCupom === "Desconto" ? columns : columnsChat,
        quantidadePaginas: cupons?.config.quantidadePaginas
    });

    const [isLoading, setIsLoading] = useState(false);

    const getCupons = async () => {
        setIsLoading(true);
        let resposta;
        if (tipoCupom === "Desconto") {
            resposta = await obterCupons({
                filtro: "",
                itensPorPagina: 10,
                ordem: ordem,
                paginaAtual: paginaAtual
            });
        } else {
            resposta = await obterCuponsChat({
                filtro: "",
                itensPorPagina: 10,
                ordem: ordem,
                paginaAtual: paginaAtual
            });
        }
        setCupons(resposta);
        setIsLoading(false);
    };    

    useEffect(() => {
        getCupons()
    }, [paginaAtual, ordem, tipoCupom])

    return (
        <PageContainer>

            <Grid item>
                <Grid container justifyContent="space-between">
                    <TituloPrincipal>Cupons</TituloPrincipal>
                    <Button onClick={() => setModalAdicionarCupom(true)}>Adicionar cupom</Button>
                </Grid>
                <Styled.GridBotoes>
                    <Styled.TextoTipos
                        active={tipoCupom === "Desconto"}
                        onClick={() => setTipoCupom("Desconto")}
                    >
                        Desconto
                    </Styled.TextoTipos>
                    <Styled.TextoTipos
                        active={tipoCupom === "Chat"}
                        onClick={() => setTipoCupom("Chat")}
                    >
                        Ativação do chat
                    </Styled.TextoTipos>
                </Styled.GridBotoes>
            </Grid>

            <br />
            {isLoading && 
            <Grid display={'flex'} justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                <Styled.Carregando>Carregando</Styled.Carregando> <CircularProgress />
            </Grid>
            }
            {!isLoading && cupons && Componente()}
            <ModalAdicionarCupom getCupons={() => getCupons()} tipoCupom={tipoCupom} handleClose={() => setModalAdicionarCupom(false)} open={modalAdicionarCupom} />
            <ModalAlert open={modalDesativar} tipo="excluir" texto="Deseja desativar este cupom?" textoBotao="Desativar" handleClose={() => setModalDesativar(false)} showButton setValue={setDesativar} />
            <ModalAlert open={modalAtivar} tipo="info" texto="Deseja reativar este cupom?" textoBotao="Reativar" handleClose={() => setModalAtivar(false)} showButton setValue={setAtivar} />
        </PageContainer>
    )
}