import { Cupom } from "pages/Cupom/Cupom";
import { B2C } from "pages/B2C/B2C";
import { Dashboard } from "pages/Dashboard/Dashboard";
import { Faturamento } from "pages/Faturamento/Faturamento";
import { Orcamentos } from "pages/Orcamentos/Orcamentos";
import { MoedasB2B } from "pages/TelaMoedasB2B/TelaMoedasB2B";
import { MoedasB2C } from "pages/TelaMoedasB2C/TelaMoedasB2C";
import { Usuarios } from "pages/Usuarios/Usuarios";
import { PropostaRedacao } from "pages/PropostaRedacao/PropostaRedacao";
import { Vestibulares } from "pages/Vestibulares/Vestibulares";
import { Route, Routes } from "react-router-dom";


const AdminBackEndRoute: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Dashboard/>} />
            <Route path="/b2c" element={<B2C/>} />
            <Route path="/faturamento" element={<Faturamento/>} />
            <Route path="/orcamentos" element={<Orcamentos/>} />
            <Route path="/usuarios" element={<Usuarios/>} />
            <Route path="/cupom" element={<Cupom/>} />
            <Route path="/moedasb2c" element={<MoedasB2C/>} />
            <Route path="/moedasb2b" element={<MoedasB2B/>} />
            <Route path="/proposta-redacao" element={<PropostaRedacao/>} />
            <Route path="/vestibulares" element={<Vestibulares/>} />
        </Routes>
    );
};

export default AdminBackEndRoute;