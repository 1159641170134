import { Box, Button, Grid, Select, Typography, keyframes, styled } from "@mui/material";
import { SearchBar } from "components/SearchBar";
import { TituloPrincipal } from "components/Titulos/Titulos.styled";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export const TituloPrincipalStyled = styled(TituloPrincipal)(({ theme }) => ({
   color: "#9030DD",
   margin: theme.spacing(2)
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
   width: "100%",
   backgroundColor: theme.palette.secondary.contrastText
}))

export const BotaoPesquisar = styled(SearchBar)(({ theme }) => ({
   backgroundColor: theme.palette.secondary.contrastText,
   borderRadius: theme.spacing(3.125),
   padding: theme.spacing(0.313),
   width: theme.spacing(22.188),

   "& svg": {
      color: theme.palette.secondary.main,
   },

}));

export const Resultado = styled(Typography)(({ theme }) => ({
   color: theme.palette.grey[600],
   fontWeight: 500,
   alignItems: "center",

}));

export const GridResultado = styled(Grid)(({ theme }) => ({
   paddingTop: theme.spacing(1),
   paddingBottom: theme.spacing(1)
}));

export const IconeVoltar = styled(ArrowBackIcon)(({ theme }) => ({
   cursor: "pointer",
   fontSize: theme.spacing(1.675)
}));

export const GridSaldo = styled(Grid)(({ theme }) => ({
   display: "Grid",
   justifyContent: "center",
   alignItems: "center",
   padding: theme.spacing(1),
   borderRadius: theme.spacing(1),
   backgroundColor: theme.palette.secondary.contrastText,

}));

export const GridBotao = styled(Grid)(({ theme }) => ({
   display: "flex",
   justifyContent: "end"

}));

export const BotaoSalvar = styled(Button)(({ theme }) => ({
   backgroundColor: theme.palette.secondary.main,
   color: theme.palette.secondary.contrastText,
   marginBottom: theme.spacing(1),
   display: "flex",
   alignItems: "left",
   fontWeight: 500,

   "&:hover ": {
      backgroundColor: theme.palette.secondary.dark,
      color: theme.palette.secondary.contrastText,
   }
}));

export const Moedas = styled(Typography)(({ theme }) => ({
   fontSize: theme.spacing(2),
   fontWeight: 500,

}));

export const Imagem = styled("img")(({ theme }) => ({
   width: "25%"

}));

export const MoedasDisponiveisCell = styled(Grid)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'flex-end',
   alignItems: 'center',
   padding: theme.spacing(1),
   borderRadius: theme.spacing(1),
   backgroundColor: theme.palette.secondary.contrastText,
   fontSize: theme.spacing(2),
   fontWeight: 500,
}));

export const PopoverBox = styled(Box)(({ theme }) => ({
   padding: theme.spacing(0.5),


}));

export const AlinhamentoIcone = styled(Typography)(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   padding: theme.spacing(0.5),
   color: theme.palette.secondary.light,
   fontWeight: 500,
   fontSize: theme.spacing(1.075)
}));

export const GridIcones = styled(Grid)(({ theme }) => ({
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   minWidth: 180

}));

export const GridFlex = styled(Grid)(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   marginTop: theme.spacing(2),
   justifyContent: "space-between"

}));
export const Saldo = styled(Typography)(({ theme }) => ({
   padding: theme.spacing(0.5),
   color: theme.palette.secondary.main,
   fontWeight: 500,
   fontSize: theme.spacing(1.5),
})
);

export const IconeAdicao = styled(AddIcon)(({ theme }) => ({
   backgroundColor: theme.palette.secondary.light,
   borderRadius: theme.spacing(3),
   color: theme.palette.secondary.contrastText,
   cursor: "pointer"

}));

export const IconeSub = styled(RemoveIcon)(({ theme }) => ({
   backgroundColor: theme.palette.secondary.light,
   borderRadius: theme.spacing(3),
   color: theme.palette.secondary.contrastText,
   cursor: "pointer"

}));

export const Lapis = styled('img')(({ theme }) => ({
   cursor: "pointer"

}));

export const GridIBotoes = styled(Grid)(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   justifyContent: "space-evenly",
   backgroundColor: theme.palette.grey[100],
   padding: theme.spacing(0.2),
   borderRadius: theme.spacing(0.3),
   marginTop: theme.spacing(0.2),
   minWidth: 180

}));

export const IconeFechar = styled(CloseIcon)(({ theme }) => ({
   color: theme.palette.secondary.light,
   cursor: "pointer"
}));

export const IconeAceitar = styled(CheckIcon)(({ theme }) => ({
   color: theme.palette.secondary.light,
   cursor: "pointer"

}));

export const TextoCard = styled(Typography)(({ theme }) => ({
   fontSize: theme.spacing(0.875),
   fontWeight: 600,
   color: theme.palette.grey[700],

}));

const rotate = keyframes`
 from { transform: rotate(0deg);}
 to {transform: rotate(360deg);}`;

export const AguardeLoading = styled(Typography)(({ theme }) => ({
   color: theme.palette.grey[600],
   fontWeight: 500,
   alignItems: "center",
   display: "flex",

   "svg": {
      animation: `${rotate} 2s linear infinite`,
      fontSize: theme.spacing(1.563)
   }
}));

export const LabelSelect = styled(Typography)(({ theme }) => ({
   fontWeight: 500,
   marginBottom: theme.spacing(0.313),
}));

export const GridSelect = styled(Grid)(({ theme }) => ({
   padding: theme.spacing(0.313)
}));

export const SelectStyles = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.contrastText
}));