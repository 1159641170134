import { Login } from "pages/Login/Login";
import { Route, Routes } from "react-router-dom";


const DeslogadoRoute: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Login/>} key="e-1" />,
        </Routes>
    );
};

export default DeslogadoRoute;