export type ModalEditarProps = {
    open: boolean;
    handleClose: () => void;
    refetchVestibular: () => Promise<void>;
    dadosEditar: any | undefined;
}

export type FormValues = {
    id: number;
    descricao: string;
    ativo: boolean;
    dataCadastro?: Date | undefined;
}

export const initialValues: FormValues = {
    id: 0,
    descricao: "",
    ativo: true,
    dataCadastro: undefined,
}