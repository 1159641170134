import { Grid, FormHelperText, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderEnable } from "reducers/layout";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Styled from  "./MeusDados.styled";
import { FormValues } from "./MeusDados.types";
import { toast } from "react-toastify";
import { ObterDadosDoUsuario } from "api/controllers/auth";
import { AdminUserDTO } from "api/models/Admin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getUserCredentials } from "reducers/authentication";
import { deepOrange } from "@mui/material/colors";

  export function MeusDados() {
    const [userData,setUserData]  = useState<AdminUserDTO>();
    const { username } = useSelector(getUserCredentials)
    const initialValues: FormValues = {  
        username: userData?.username,
        email: userData?.email}
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { handleSubmit, handleChange, handleBlur, errors, touched,  values, resetForm } = useFormik({
        initialValues,
        onSubmit
    
    });

    useEffect(() => {
        dispatch(setHeaderEnable(true));
        ObterDadosDoUsuario().then((value)=>{setUserData(value)});
    
    }, []);

    async function onSubmit(values: FormValues) {
        if (initialValues.username == values.username && initialValues.email == values.email) {
            toast.error("Nenhum campo foi alterado!")
            resetForm()
        }
        else {
            resetForm()
            toast.success("Perfil editado com sucesso!");
            }
            }

    
    return(
        <>
            
            <Styled.ItemContainer >
                    <form onSubmit={handleSubmit}>                           
                <Styled.AlinhamentoTextos > 
                    <Grid marginBottom="20%" width="20%">
                        <Styled.TypographyTitle>
                            <ArrowBackIcon onClick={() => navigate('/statusGerencial')} className="iconCursor"  style={{marginRight:'15px'}}/>
                            Meus Dados
                            </Styled.TypographyTitle> 
                        <Styled.LinkAlterarSenha to="/statusGerencial" >Alterar minha Senha </Styled.LinkAlterarSenha>
                    </Grid>
                
                        
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} md={4} sm={12} >
                    <Grid container>
                        <Avatar sx={{ bgcolor: deepOrange[500] }}>{username.charAt(0)}</Avatar>
                        <Styled.TextoDados >
                        {username}
                        </Styled.TextoDados>
                        </Grid>
                        <Styled.FormControlGeneric>
                            <Styled.FormLabelGeneric htmlFor="email">E-mail</Styled.FormLabelGeneric>
                            <Styled.InputTextEmail
                                name="email"
                                type="text"
                                id="email"
                                placeholder="exemplo@hotmail.com"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && !!errors.email}
                                inputProps={{
                                    "aria-labelledby": "nome-label"
                                }}
                            />
                            {touched.email && !!errors.email && (
                                <FormHelperText id="nemailome-label" error={touched.email && !!errors.email}>
                                    {errors.email}
                                </FormHelperText>
                            )}

                        </Styled.FormControlGeneric>
                    </Grid>

                    <Grid container style={{display: 'flex', justifyContent:'center', margin: 'auto'}}>
                        <Grid style={{display: 'grid'}}>
                            <Styled.BotaoContinuar type="submit"> Salvar Alterações <Styled.IconEdit /></Styled.BotaoContinuar>
                        </Grid>
                    </Grid>
                                    
                </Grid>
                </Styled.AlinhamentoTextos>
                </form>                
           </Styled.ItemContainer>
      
            
        </>
    )
}