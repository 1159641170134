import { styled } from '@mui/material/styles';
import type { LogoProps } from './Logo.types';

export const Wrapper = styled("div")<LogoProps>(({ theme, variant, size = "sm" }) => ({ 
   "& .SVGLogo": {
      width: theme.spacing(7.5),
      height: theme.spacing(7.5),
   },
   
   ...(variant === "dark" && ({
         "& .SVGLogo": {
            fill: "#1B98E0",
            "& .cls-1": {
               fill: "#fff",
            },
            "& .cls-2":{
              
               fill: "#00e800",
            },
         }
   })),

   ...(variant === "light" && ({
      "& .SVGLogo": {
         fill: "#fcfdfd",

         "& .cls-1": {
            fill: "#fff",
         },
         "& .cls-2":{
           
            fill: "#00e800",
         },
      }
   })),

   ...(variant === "medium" && ({
      "& .SVGLogo": {
         fill: "#fcfdfd",
       
         "& .cls-1": {
            fill: theme.palette.grey[500] ,
         },
         "& .cls-2":{
           
            fill: "#00e800",
         },
      }
   })),

   ...(size === "sm" && ({
      width: theme.spacing(7),
   })),

   ...(size === "md" && ({
      width: theme.spacing(5.625),
   })),

   ...(size === "lg" && ({
      
      width: theme.spacing(20),
   }))
 }));