import { Box, FormHelperText, Grid, Modal } from "@mui/material"
import { ModalProps, SecretariaProps } from "./ModalAdicionarSecretaria.types"
import { CloseOutlined } from "@mui/icons-material"
import * as Yup from "yup"
import { useFormik } from "formik"
import { InputPassword, InputText } from "components/Inputs"
import { Button } from "components/Button"
import { useEffect } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { adicionarGestao } from "api/controllers/admin"
import * as Styled from "./ModalAdicionarSecretaria.styled"
import { InputMask } from "components/Inputs/InputMask"
import { telefoneMaskFormatter } from "util/documento-helper"

const validationSchema = Yup.object({
    nome: Yup.string().required("Obrigatório"),
    cep: Yup.string().required("Obrigatório"),
    email: Yup.string().email().required("Obrigatório"),
    telefone: Yup.string().required("Obrigatório"),
    senha: Yup.string().required("Obrigatório"),
    numero: Yup.string().required("Obrigatório"),
    documento: Yup.string().required("Obrigatório"),
    limiteEscolas: Yup.number().required("Obrigatório").min(1, "Mínimo 1"),
    parceira: Yup.boolean()
})

export const ModalAdicionarSecretaria = ({ open, handleClose }: ModalProps) => {

    const initialValues: SecretariaProps = {
        cep: "",
        documento: "",
        email: "",
        nome: "",
        numero: "",
        senha: "",
        telefone: "",
        parceira: false,
        limiteEscolas: 0
    }

    async function onSubmit(values: SecretariaProps) {
        try {
            await adicionarGestao(values);
            toast.success("Secretaria adicionada com sucesso!")
            handleClose();
            resetForm();
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    const { handleChange, values, touched, errors, handleSubmit, setFieldValue, resetForm, isSubmitting } = useFormik({
        validationSchema,
        initialValues,
        onSubmit
    })

    useEffect(() => {
        let cep = values?.cep?.replace("_", '');
        if (cep.length === 9) {
            axios.get(`https://viacep.com.br/ws/${cep}/json`)
                .then((response) => {
                    if (response?.data?.erro) {
                        toast.error('CEP Inválido!')
                    } else {
                        setFieldValue("rua", response?.data?.logradouro)
                        setFieldValue("cidade", response?.data?.localidade)
                        setFieldValue("estado", response?.data?.uf)
                        setFieldValue("bairro", response?.data?.bairro)
                        setFieldValue("complemento", response?.data?.complemento)
                    }
                });
        }
    }, [values.cep])

    return (
        <Modal open={open}>
            <Styled.BoxModal>
                {!isSubmitting ?
                    <>
                        <Grid container display="flex" justifyContent="space-between">
                            <h3>Cadastrar nova secretaria</h3>
                            <CloseOutlined onClick={() => { handleClose(); resetForm() }} />
                        </Grid>
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Styled.GridFormControl xs={12} md={6}>
                                    Nome:
                                    <InputText
                                        placeholder="Ex.: Rua João Pessoa"
                                        name="nome"
                                        type="text"
                                        value={values.nome}
                                        onChange={handleChange} />
                                        
                                        {touched.nome && !!errors.nome && <FormHelperText id="nome-label" error>{errors.nome}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    E-mail:
                                    <InputText
                                        placeholder="Ex.: exemplo@criamail.com"
                                        name="email"
                                        type="text"
                                        value={values.email}
                                        onChange={handleChange} />
                                        
                                        {touched.email && !!errors.email && <FormHelperText id="email-label" error>{errors.email}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    Limite escolas:
                                    <InputText
                                        placeholder="Ex.: 15"
                                        name="limiteEscolas"
                                        type="number"
                                        value={values.limiteEscolas}
                                        onChange={handleChange} />
                                        
                                        {touched.limiteEscolas && !!errors.limiteEscolas && <FormHelperText id="limiteEscolas-label" error>{errors.limiteEscolas}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    CNPJ:
                                    <InputMask
                                        mask="99.999.999/9999-99"
                                        placeholder="Ex.: 99.999.999/9999-99"
                                        name="documento"
                                        maskPlaceholder=""
                                        type="text"
                                        value={values.documento}
                                        onChange={handleChange} />
                                        
                                        {touched.documento && !!errors.documento && <FormHelperText id="documento-label" error>{errors.documento}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    CEP:
                                    <InputMask
                                        mask="99999-999"
                                        placeholder="Ex.: 00000-00"
                                        name="cep"
                                        type="text"
                                        value={values.cep}
                                        onChange={handleChange} />
                                        
                                        {touched.cep && !!errors.cep && <FormHelperText id="cep-label" error>{errors.cep}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    Número:
                                    <InputText
                                        placeholder="Ex.: 104b"
                                        name="numero"
                                        type="text"
                                        value={values.numero}
                                        onChange={handleChange} />
                                        
                                        {touched.numero && !!errors.numero && <FormHelperText id="numero-label" error>{errors.numero}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    Telefone:
                                    <InputMask
                                        mask={telefoneMaskFormatter(values.telefone.replaceAll("_", ""))}
                                        placeholder="Ex.: 55 (11) 99999-9999"
                                        name="telefone"
                                        type="text"
                                        value={values.telefone}
                                        onChange={handleChange} />

                                        {touched.telefone && !!errors.telefone && <FormHelperText id="telefone-label" error>{errors.telefone}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    Senha:
                                    <InputPassword
                                        placeholder="EX.: 123456"
                                        name="senha"
                                        value={values.senha}
                                        onChange={handleChange} />

                                        {touched.senha && !!errors.senha && <FormHelperText id="cep-label" error>{errors.senha}</FormHelperText>}
                                </Styled.GridFormControl>
                                <Styled.GridFormControl xs={12} md={6}>
                                    <Styled.GridCentro container>
                                        Parceira:
                                        <Styled.InputCheck
                                            type="checkbox"
                                            name="parceira"
                                            onChange={(e) => setFieldValue("parceira", e.target.checked)}
                                            checked={values.parceira} />
                                    </Styled.GridCentro>
                                </Styled.GridFormControl>
                                <Button color="primary" type="submit">Adicionar secretaria</Button>
                            </Grid>
                        </form>
                    </> :
                    "Adicionando..."
                }
            </Styled.BoxModal>
        </Modal>
    )
}