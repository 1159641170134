export type ModalAdicionarProps = {
    open: boolean;
    handleClose: () => void;
    refetchVestibular: () => Promise<void>;
}

export type FormValues = {
    id: number;
    descricao: string;
    ativo: boolean;
    dataCadastro?: Date;
}

export const initialValues: FormValues = {
    id: 0,
    descricao: "",
    ativo: true,
    dataCadastro: undefined,
}