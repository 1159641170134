import { Box, Grid, MenuItem, Modal, Select } from "@mui/material"
import { ModalProps, SecretariaProps } from "./ModalAdicionarCupom.types"
import { CloseOutlined } from "@mui/icons-material"
import * as Yup from "yup"
import { useFormik } from "formik"
import { InputPassword, InputText } from "components/Inputs"
import { Button } from "components/Button"
import { useEffect } from "react"
import axios from "axios"
import { toast } from "react-toastify"
import { adicionarCupom, adicionarCupomChat } from "api/controllers/admin"
import * as Styled from "./ModalAdicionarCupom.styled"
import { InputMask } from "components/Inputs/InputMask"
import { telefoneMaskFormatter } from "util/documento-helper"
import { CupomChat, CupomPagamento } from "api/models/cupom"
import { EnumPerfilUsuario, EnumTipoCompra, EnumTipoDesconto } from "api/models/enum"

const validationSchemaDesconto = Yup.object({
    cupom: Yup.string().required("Obrigatório"),
    idPerfil: Yup.number().required("Obrigatório").test("valida-perfil", "Escolha um valor", x => x !== 0),
    idTipoCupom: Yup.number().required("Obrigatório").test("valida-tipo-cupom", "Escolha um valor", x => x !== 0),
    idTipoDesconto: Yup.number().required("Obrigatório").test("valida-tipo-desconto", "Escolha um valor", x => x !== 0),
    multiplicacao: Yup.number().required("Obrigatório").test("valida-multiplicacao", "Escolha um valor", x => x !== 0)
})

const validationSchemaChat = Yup.object({
    cupom: Yup.string().required("Obrigatório"),
    idPerfil: Yup.number().required("Obrigatório").test("valida-perfil", "Escolha um valor", x => x !== 0),
})

export const ModalAdicionarCupom = ({ open, handleClose, tipoCupom, getCupons }: ModalProps) => {

    const initialValues = tipoCupom === "Desconto"
    ? {
        cupom: "",
        idPerfil: 0,
        idTipoCupom: 0,
        idTipoDesconto: 0,
        multiplicacao: 0,
        usoUnico: false
      } 
    : {
        cupom: "",
        idPerfil: 0,
      };


    async function onSubmit(values: any) {
        try {
            if (tipoCupom === "Desconto") {
                await adicionarCupom(values);
            } else {
                await adicionarCupomChat(values);
            }
            toast.success("Cupom adicionado com sucesso!");
            getCupons();
            handleClose();
            resetForm();
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    const validationSchema = tipoCupom === "Desconto" ? validationSchemaDesconto : validationSchemaChat

    const { handleChange, values, touched, errors, handleSubmit, setFieldValue, resetForm, isSubmitting } = useFormik({
        validationSchema,
        initialValues,
        onSubmit
    })

    return (
        <Modal open={open}>
            <Styled.BoxModal>
                {!isSubmitting ?
                    <>
                        <Grid container display="flex" justifyContent="space-between">
                            <h3>Cadastrar cupom</h3>
                            <CloseOutlined onClick={() => { handleClose(); resetForm() }} />
                        </Grid>
                        <form onSubmit={handleSubmit}>
                            <Grid container>
                                <Styled.GridFormControl xs={12} md={6}>
                                    Cupom:
                                    <InputText
                                        placeholder="Ex.: #DESCONTO"
                                        name="cupom"
                                        type="text"
                                        value={values.cupom}
                                        onChange={handleChange}
                                        error={!!errors.cupom && touched.cupom} />
                                </Styled.GridFormControl>
                                
                                {tipoCupom === "Desconto" && (
                                    <>
                                        <Styled.GridFormControl xs={12} md={6}>
                                            Desconto / Multiplicação:
                                            <InputText
                                                placeholder="Desconto: < 0 - Multiplicação: > 0"
                                                name="multiplicacao"
                                                type="number"
                                                value={values.multiplicacao}
                                                onChange={handleChange}
                                                error={!!errors.multiplicacao && touched.multiplicacao} />
                                        </Styled.GridFormControl>

                                        <Styled.GridFormControl xs={12} md={6}>
                                            Tipo compra:
                                            <Select
                                                name="idTipoCupom"
                                                type="number"
                                                value={values.idTipoCupom}
                                                onChange={handleChange}
                                                style={{ width: "100%", fontSize: 14, borderRadius: "100px", height: "2.5rem" }}
                                                error={!!errors.idTipoCupom && touched.idTipoCupom}>
                                                <MenuItem value={0}>-- Selecione --</MenuItem>
                                                {Object.values(EnumTipoCompra).map((item, index) => typeof item === "string" && <MenuItem value={index + 1}>{item}</MenuItem>)}
                                            </Select>
                                        </Styled.GridFormControl>

                                        <Styled.GridFormControl xs={12} md={6}>
                                            Tipo desconto:
                                            <Select
                                                name="idTipoDesconto"
                                                type="number"
                                                value={values.idTipoDesconto}
                                                onChange={handleChange}
                                                style={{ width: "100%", fontSize: 14, borderRadius: "100px", height: "2.5rem" }}
                                                error={!!errors.idTipoDesconto && touched.idTipoDesconto}>
                                                <MenuItem value={0}>-- Selecione --</MenuItem>
                                                {Object.values(EnumTipoDesconto).map((item, index) => typeof item === "string" && <MenuItem value={index + 1}>{item}</MenuItem>)}
                                            </Select>
                                        </Styled.GridFormControl>

                                        <Styled.GridFormControl xs={12} md={6}>
                                            <Styled.GridCentro container>
                                                Uso único:
                                                <Styled.InputCheck
                                                    type="checkbox"
                                                    name="usoUnico"
                                                    onChange={(e) => setFieldValue("usoUnico", e.target.checked)}
                                                    checked={values.usoUnico} />
                                            </Styled.GridCentro>
                                        </Styled.GridFormControl>
                                    </>
                                )}

                                <Styled.GridFormControl xs={12} md={6}>
                                    Perfil:
                                    <Select
                                        name="idPerfil"
                                        type="number"
                                        value={values.idPerfil}
                                        onChange={handleChange}
                                        style={{ width: "100%", fontSize: 14, borderRadius: "100px", height: "2.5rem" }}
                                        error={!!errors.idPerfil && touched.idPerfil}>
                                        <MenuItem value={0}>-- Selecione --</MenuItem>
                                        {Object.values(EnumPerfilUsuario).map((item, index) => typeof item === "string" && <MenuItem value={index + 1} disabled={item !== 'Escola' && tipoCupom === "Chat"}>{item}</MenuItem>)}
                                    </Select>
                                </Styled.GridFormControl>

                                <Grid display={'flex'} width={'100%'} justifyContent={'center'}>
                                    <Button color="primary" type="submit">Adicionar cupom</Button>
                                </Grid>
                            </Grid>
                        </form>
                    </> :
                    "Adicionando..."
                }
            </Styled.BoxModal>
        </Modal>
    )
}