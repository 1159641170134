export function validarCNPJ(cnpj: string) {
    // Remover caracteres não numéricos
    cnpj = cnpj.replace(/[^\d]+/g, '');

    // Verificar se possui 14 dígitos
    if (cnpj.length !== 14) {
        return false;
    }

    // Verificar dígitos verificadores
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado.toString() !== digitos.charAt(0)) {
        return false;
    }

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
        soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado.toString() !== digitos.charAt(1)) {
        return false;
    }

    return true;
}

export const telefoneMaskFormatter = (value: string) => {
    if (!value || value.length === 0) return "55 (99) 99999-9999";

    if (value.replace(/\D/g, "").length <= 12) {
        return "55 (99) 9999-99999";
    }

    return "55 (99) 99999-9999";
};