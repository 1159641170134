import { Box, Container, FormControl, FormLabel, Grid, styled, Typography } from "@mui/material";
import { InputText } from "components/Inputs";
import { Link } from "react-router-dom";
import { InputMask } from "components/Inputs/InputMask/InputMask";
import { Button } from "components/Button";
import { MdOutlineEdit } from "react-icons/md";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AiOutlineClose } from "react-icons/ai";



export const ItemContainer = styled(Container)(({ theme }) => ({
   backgroundColor: theme.palette.secondary.contrastText ,
   placeContent: "center",
   padding: "5%",
   height: "50%"

}));

export const IconeClose = styled(AiOutlineClose)(({
 margin: "10px",

}));

export const FormControlGeneric = styled(FormControl)(({ theme }) => ({  
    display: 'flex',
    justifyContent: 'center',
    minWidth: theme.spacing(19.438),
    padding: "10px",
       
}));

export const FormLabelGeneric = styled(FormLabel)(({ theme }) => ({    
    display: "flex",
    fontSize: "15px",
    fontStyle: 'normal',
    fontWeight: 600, 
    color: '#13293D',  
}));


export const InputTextGeneric = styled(InputText)(({ theme }) => ({
    background: "#FFFFFF",
    border: `1px solid ${theme.palette.grey[200]}`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(20.375),
    padding:'10px',  
    height:'35px',
    fontSize:"14px",

  
    "@media (min-width: 1065px) and (max-width: 1350px)": {
      width: "80%",
  },
 
  "@media (min-width: 900px) and (max-width: 1060px)": {
      width: "65%",
   },
}));

export const InputTextEmail = styled(InputText)(({ theme }) => ({
   background: "#FFFFFF",
   border: `1px solid ${theme.palette.grey[200]}`,
   boxSizing: 'border-box',
   borderRadius: theme.spacing(20.375),
   padding:'10px',  
   height:'35px',
   fontSize:"14px", 


   "@media (min-width: 1065px) and (max-width: 1350px)": {
      width: "80%",
  },
 
  "@media (min-width: 900px) and (max-width: 1060px)": {
      width: "65%",
   },



}));


export const InputMaskGeneric = styled(InputMask)(({ theme }) => ({
    background: "#FFFFFF",
    border: `1px solid ${theme.palette.grey[200]}`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(20.375),
    padding:'10px',  
    height:'35px',
    fontSize:"14px",
 

    "@media (min-width: 1065px) and (max-width: 1350px)": {
      width: "80%",
  },
 
  "@media (min-width: 900px) and (max-width: 1060px)": {
      width: "65%",
   },
}));

export const BotaoContinuar = styled(Button)(({ theme }) =>({
   backgroundColor: theme.palette.primary.main,  
   color: theme.palette.primary.contrastText,
   marginTop: "10px",
   height:'38px',
   marginBottom: "25px", 

   "&:hover" :{
      backgroundColor: theme.palette.primary.light,  
      color: theme.palette.primary.contrastText,
     
   },   
}));

export const ItemContainerImagem = styled(Container)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'center',
   margin: 'auto',
   marginTop:'1%',
   textAlign:'center', 

}));

export const AlinhamentoTextos = styled("div")(({ theme }) => ({
   display: "flex",
   justifyContent: "space-around",
   alignItems: "center",
   marginTop: "30px",
   placeContent: "center",
   textAlign:'center', 
}));

export const LinkAlterarSenha = styled(Link)(({ theme }) => ({
   display: "flex",
   textAlign: "initial",
   textDecoration: "underline",
   fontSize:"14px",
   color: theme.palette.secondary.main, 
   
}));

export const TextoDados = styled(Typography)(({ theme }) => ({
   fontSize: "20px",
   fontWeight: 600,
}));

export const IconEdit = styled(MdOutlineEdit)(({ theme }) => ({
   marginLeft:'5px',
   width:'23px',
   height:'23px',
}));

export const TypographyTitle = styled(Typography)(({ theme }) => ({
   color: theme.palette.secondary.main,
   fontSize: '20px',
   fontWeight: '600',
   alignItems: "center",
   marginTop: '20px',
   display: 'flex',
   "& .iconCursor": {
       cursor: 'pointer'
   }
}));

   

