import { Grid, Typography, Button, Box, Select } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { TypesActive } from './statusGerencial.types';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export const TituloDash = styled(Typography)(({ theme }) => ({
   color: theme.palette.secondary.main,
   fontSize: theme.spacing(0.938),
   fontWeight: 600,
   marginBottom: `${theme.spacing(0.938)} !important`,

   [theme.breakpoints.up("lg")]: {
      display: "none"
   }
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
   zIndex: 1,
   display: "flex",
   width: "100%",
   "& div": {
      zIndex: 1
   },
   [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(8.125),

   },
   [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0.938),

   }

}));

export const ContainerContentFirst = styled(Grid)(({ theme }) => ({
   border: `${theme.spacing(0.063)} solid #d1d1d180`,
   borderRadius: theme.spacing(0.625),
   margin: 0,
   padding: theme.spacing(0.938),
   boxShadow: `${alpha(theme.palette.grey[900], 0.1)} 0px ${theme.spacing(1)} ${theme.spacing(1)} 0px, ${alpha(theme.palette.grey[900], 0.1)} ${theme.spacing(1)} 0px ${theme.spacing(1)} 0px, ${alpha(theme.palette.grey[900], 0.1)} 0px 0px 0`,
   background: theme.palette.secondary.contrastText,
   width:"64%",

   [theme.breakpoints.down("md")]: {
      width:"100%",

   },
   [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(0.938),

   }

}));
export const ContainerPlot = styled(Grid)(({ theme }) => ({
   border: `${theme.spacing(0.063)} solid #d1d1d180`,
   borderRadius: theme.spacing(0.625),
   height:'100%',
   margin: 2,
   padding: theme.spacing(0.938),
   boxShadow: `${alpha(theme.palette.grey[900], 0.1)} 0px ${theme.spacing(1)} ${theme.spacing(1)} 0px, ${alpha(theme.palette.grey[900], 0.1)} 0px 0px ${theme.spacing(1)}, ${alpha(theme.palette.grey[900], 0.1)} ${theme.spacing(1)} 0px ${theme.spacing(1)} 0px`,
   background: theme.palette.secondary.contrastText,
   [theme.breakpoints.up("xs")]: {
      marginTop: theme.spacing(0.938),

   }, 
   [theme.breakpoints.down("md")]: {
      width:"100%",

   },

}));


export const TituloContainer = styled(Typography)(({ theme }) => ({
   color: theme.palette.secondary.main,
   fontSize: theme.spacing(1.25),
   fontWeight: 600,
   marginBottom: theme.spacing(1.875),
}));

export const DescriptionTitle = styled(Typography)(({ theme }) => ({
   width: theme.spacing(50),
   color: theme.palette.secondary.main,
   marginBottom: theme.spacing(0.625),
   fontWeight: 600,

   [theme.breakpoints.down("sm")]: {
      maxWidth: theme.spacing(10),
   }

}));

export const TitleContentRedacao = styled(Typography)(({ theme }) => ({
   color: theme.palette.secondary.main,
   fontSize: theme.spacing(1.25),
   fontWeight: 600,
}));

export const BtnBranco = styled(Button)<TypesActive>(({ theme, isActive }) => ({
   color: "black",
   border: `${theme.spacing(0.063)} solid ${theme.palette.grey[300]}`,
   width: "100%",
   height: theme.spacing(3.125),
   borderRadius: theme.spacing(0.625),
   
   ...(!isActive && {
      background: theme.palette.secondary.contrastText,
   }),
   ...(isActive && {
      background: theme.palette.grey[100] ,
   })
}));

export const BtnNovaRedacaoResponsivo = styled(Grid)(({ theme }) => ({
   backgroundColor: theme.palette.primary.main,
   padding: theme.spacing(0.75),
   borderRadius: theme.spacing(3.125),

   [theme.breakpoints.up("md")]: {
      display: "none",
   },

   [theme.breakpoints.down("md")]: {
      display: "flex",
   },
}));

export const GridMinhasRedacoes = styled(Grid)(({ theme }) => ({
   alignItems: "center" ,
   justifyContent: "space-between" ,
   marginBottom: theme.spacing(0.938)
}));

export const GridEvolucao = styled(Grid)(({ theme }) => ({
   alignItems: "center" ,
   justifyContent: "space-between" ,
 
}));

export const NotificacaoTema = styled("span")(({ theme }) => ({
   backgroundColor: theme.palette.error.main,
   color: theme.palette.secondary.contrastText,
   padding: `0 ${theme.spacing(0.5)} 0px ${theme.spacing(0.5)}`,
   borderRadius: theme.spacing(2),
   margin: theme.spacing(0.188),
}));

export const GridPrincipal = styled(Grid)(({ theme }) => ({
   position: "relative",
   display: "flex",
   justifyContent: "center",

}));

export const TextoRedacao = styled(Typography)(({ theme }) => ({
   overflowX: "hidden", 
   textOverflow: "ellipsis", 
   whiteSpace: "nowrap",

}));

export const GridFlex = styled(Grid)(({ theme }) => ({
   display: "flex",

}));

export const GridFlexCenter = styled(Grid)(({ theme }) => ({
   display: "flex",
   alignItems: "center",

}));

export const GridFlexEnd = styled(Grid)(({ theme }) => ({
   display: "flex",
   alignItems: "center",
   justifyContent: "end"

}));
export const Item = styled(Typography)(({ theme }) => ({
   color: theme.palette.grey[500],
   fontSize: theme.spacing(0.875),
   textAlign: 'left',
}));

export const Value = styled(Typography)(({ theme }) => ({
   color: theme.palette.secondary.main,
   fontSize: theme.spacing(1.25),
   fontWeight: 600
}));

export const NovaRedacao = styled(Button)(({ theme }) => ({
   height: theme.spacing(2.813),
   minWidth: theme.spacing(13.75),
   borderRadius: theme.spacing(1.25),
   color: theme.palette.primary.contrastText,
   backgroundColor: theme.palette.primary.main,
   fontWeight: 600,

   [theme.breakpoints.down("sm")]: {
      width: `${theme.spacing(3.125)}!important`,
   },

   "&:hover": {
      backgroundColor: theme.palette.primary.dark,
   },

   [theme.breakpoints.up("md")]: {
      display: "flex",
   },

   [theme.breakpoints.down("md")]: {
      display: "none",
   },
}));

export const GridDesempenho = styled(Grid)(({ theme }) => ({
   justifyContent: "space-between"
}));

export const GridMelhorNota = styled(Grid)(({ theme }) => ({
   [theme.breakpoints.up("md")]: {
      justifyContent: "start",

   },

   [theme.breakpoints.up("sm")]: {
      justifyContent: "center",

   },

}));

export const GridRedacaoMelhorar = styled(Grid)(({ theme }) => ({
   marginTop: theme.spacing(3.125)

}));

export const GridMenorNota = styled(Grid)(({ theme }) => ({
   [theme.breakpoints.up("md")]: {
      justifyContent: "start",

   },

   [theme.breakpoints.up("sm")]: {
      justifyContent: "end",

   },

}));

export const GridNotas = styled(Grid)(({ theme }) => ({
   [theme.breakpoints.up("lg")]: {
      display: "flex",
   },

   [theme.breakpoints.down("lg")]: {
      display: "none",
   },
}));

export const GridNota = styled(Grid)(({ theme }) => ({
   [theme.breakpoints.up("lg")]: {
      display: "none",
   },

   [theme.breakpoints.down("lg")]: {
      display: "flex",
   },
}));



export const BotoesBrancos = styled(Grid)(({ theme }) => ({
   gap: theme.spacing(0.5),
   
}));

export const Card = styled("div")(({ theme }) => ({
   background: "white !important",
   borderRadius: theme.spacing(1),
   boxShadow: `${alpha(theme.palette.grey[900], 0.1)} 0px ${theme.spacing(0.25)} ${theme.spacing(1)},${alpha(theme.palette.grey[900], 0.2)} 0px ${theme.spacing(0.25)} ${theme.spacing(1)}`,
   display: "flex",
   padding: theme.spacing(1.25),
   alignItems: "center",
   height: theme.spacing(7.5)
}));

export const DivCard = styled("div")(({ theme }) => ({
   textAlign: "initial",
   margin: theme.spacing(0.125),
   
}));

export const TituloCardFlex = styled("p")(({ theme }) => ({
   fontSize: theme.spacing(0.938),
   color: theme.palette.secondary.main,
   fontWeight: "600",

   [theme.breakpoints.up("lg")]: {
      display: "flex",
   },

   [theme.breakpoints.down("lg")]: {
      display: "none",
   },

}));

export const TituloCardNone = styled("p")(({ theme }) => ({
   fontSize: theme.spacing(0.938),
   color: theme.palette.secondary.main,
   fontWeight: "600",

   [theme.breakpoints.up("lg")]: {
      display: "none",
   },

   [theme.breakpoints.down("lg")]: {
      display: "flex",
   },

}));

export const TituloCard = styled("p")(({ theme }) => ({
   fontSize: theme.spacing(0.938),
   color: theme.palette.secondary.main,
   fontWeight: "600"
}));

export const DescricaoCard = styled("p")(({ theme }) => ({
   fontSize: theme.spacing(1.875),
   color: theme.palette.grey[600],
   fontWeight: "600",

}));

export const Imagem = styled("img")(({theme }) => ({
   height: theme.spacing(3.375),
   width: theme.spacing(3.375),
   paddingRight: theme.spacing(0.625),
}));

export const GridCard = styled(Grid)(() => ({
   display: "flex",
   justifyContent: "space-between",


}));
export const GridDivisaoCard = styled(Grid)(({ theme }) => ({
   marginBottom: theme.spacing(0.938),
   width:"24%",

   [theme.breakpoints.down("md")]: {
    
      width:"100%",
   }

}));

export const Div = styled(Grid)(({ theme }) => ({
   position: "absolute",
   width: "100%",
   height: theme.spacing(11.25),
   paddingLeft: theme.spacing(3.75),
   paddingTop: theme.spacing(6.25),
   background: theme.palette.secondary.main,
   display: "flex",
   "& p": {
      color: theme.palette.secondary.contrastText,
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: theme.spacing(1),
   },
   [theme.breakpoints.down("lg")]: {
      display: "none"
   }

}));

export const BoxImagem = styled(Box)(({ theme }) => ({
   display: "flex",
   justifyContent: "center",
   marginTop: theme.spacing(2.5),
}));

export const TextoImagem = styled(Typography)(({ theme }) => ({
   display: "flex",
   justifyContent: "center",
   fontSize: theme.spacing(0.813),
   color: theme.palette.grey[500] ,

}));

export const TextoImagemTop = styled(Typography)(({ theme }) => ({
   display: "flex",
   justifyContent: "center",
   fontSize: theme.spacing(0.813),
   color: theme.palette.grey[500] ,
   marginTop: theme.spacing(0.625)

}));


export const Seta = styled(ArrowForwardIcon)(() => ({
   cursor: "pointer",
}));

export const Container = styled(Grid)(({ theme }) => ({
   background: "white",
   padding: theme.spacing(0.938),
   border: `${theme.spacing(0.063)} solid #d1d1d180`,
   borderRadius: theme.spacing(0.625),
   boxShadow: `${alpha(theme.palette.grey[900], 0.2)} 0px ${theme.spacing(0.25)} ${theme.spacing(1)},${alpha(theme.palette.grey[900], 0.2)} 0px ${theme.spacing(0.25)} ${theme.spacing(1)}`,
   marginBottom: theme.spacing(1.25)
}));

export const ContainerSemDados = styled("div")(({ theme }) => ({
   width: "100%",
   flexDirection: "column",
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   "p": {
      fontSize: theme.spacing(0.875),
      color: theme.palette.grey[500],
   },
   "img": {
      width: "10%",
   }
}));
export const BtnPorCompetencia = styled(Button)<TypesActive>(({ theme, isActive = false }) => ({
   color: theme.palette.grey[500] ,
   border: `${theme.spacing(0.063)} solid ${theme.palette.grey[500] }`,
   height: theme.spacing(3.483),
   margin: theme.spacing(0.5),
   fontWeight: 500,
   borderRadius: theme.spacing(0.625),
   ...(isActive == false && {
      background: theme.palette.secondary.contrastText,
   }),
   ...(isActive == true && {
      backgroundColor: theme.palette.grey[100] ,
   }),

   '&:hover': {
      backgroundColor: theme.palette.grey[100] ,
   },
}));

export const Filtro = styled(Select)(({ theme }) => ({
   borderRadius: theme.spacing(3.125),
   height: theme.spacing(3.125),
   width: "80%",
   fontSize: theme.spacing(0.938),
   margin: theme.spacing(0.625),

}));



