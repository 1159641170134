import { Box, Checkbox, FormLabel, Grid, styled, Typography } from "@mui/material";
import { InputText } from "components/Inputs";
import { InputMask } from "components/Inputs/InputMask";

export const FormControlGeneric = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
}));

export const FormLabelGeneric = styled(FormLabel)(({ theme }) => ({
    display: "flex",
    fontStyle: "normal",
    fontWeight: 500,
    color: theme.palette.grey[500],
    fontSize: theme.spacing(1)
}));

export const FormLabelGenericTextoTermo = styled(FormLabel)(({ theme }) => ({
    fontSize: theme.spacing(0.875)
}));

export const StyledInputMask = styled(InputMask)(({ theme }) => ({
     border:"none",
   padding: theme.spacing(0.315),
   height: theme.spacing(2.5),
   width: theme.spacing(15),
   color: theme.palette.grey[900],
   fontSize: theme.spacing(0.875),
   borderBottom: `2px solid #9030DD`,
   "&::-webkit-calendar-picker-indicator": {
       filter: 'brightness(0) saturate(100%) invert(41%) sepia(89%) saturate(1039%) hue-rotate(174deg) brightness(98%) contrast(80%)',
   },
   "&:focus": {
       outline: "none",
       borderBottom: `2px solid #9030DD`,
   },
}));

export const StyledInputText = styled(InputText)(({ theme }) => ({
    border: "none",
    padding: theme.spacing(0.315),
    height: theme.spacing(2.5),
    width: theme.spacing(15),
    color: theme.palette.grey[900],
    fontSize: theme.spacing(0.875),
    borderBottom: `2px solid #9030DD`,
    "&::-webkit-calendar-picker-indicator": {
        filter: 'brightness(0) saturate(100%) invert(41%) sepia(89%) saturate(1039%) hue-rotate(174deg) brightness(98%) contrast(80%)',
    },
    "&:focus": {
        outline: "none",
        borderBottom: `2px solid #9030DD`,
    },
}));