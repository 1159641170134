import { IconButton, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Icon = styled(IconButton)(({ theme }) => ({
    padding: '5',
    margin: '5',
    color: theme.palette.secondary.main,  
}));

export const MenuItemCentro = styled(MenuItem)<{ tipo: "normal" | "sair" }>(({ theme, tipo }) => ({
    display: "flex",
    justifyContent: "center",
    fontSize: "0.9rem",
    ...(tipo === "normal" && {
        color: theme.palette.grey[900]
    }),
    ...(tipo === "sair" && {
        color: theme.palette.error.main
    })
}));