import { AppBar, Box, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "components/Button";
import { RiMenuLine } from "react-icons/ri";

export const AppBarProfessor = styled(AppBar)(({ theme }) => ({
    display: "flex",
    background: theme.palette.secondary.main,
    position: "fixed",
    zIndex: 2,
    height: theme.spacing(5),
    alignItems: "center",
    justifyContent: "center"
}));

export const GridValidacaoTela = styled(Grid)<{ responsivo: boolean }>(({ theme, responsivo }) => ({
    justifyContent: "space-between",
    alignItems: "center",
    ...(responsivo ? {
        display: "flex",
        [theme.breakpoints.up("lg")]: {
            display: "none"
        }
    } : {
        display: "none",
        [theme.breakpoints.up("lg")]: {
            display: "flex"
        }
    })
}));

export const GridCentro = styled(Grid)({
    display: "flex",
    alignItems: "center"
});

export const GridFinal = styled(GridCentro)({
    justifyContent: "end"
});

export const GridFinalProfessor = styled(GridFinal)(({ theme }) => ({
    [theme.breakpoints.up("xl")]: {
        width: "16%"
    },
    [theme.breakpoints.between("lg", "xl")]: {
        width: "20%"
    },
    [theme.breakpoints.down("lg")]: {
        width: "26%"
    }
}));

export const AppBarAluno = styled(AppBar)(({ theme }) => ({
    background: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    zIndex: 2,
    height: theme.spacing(6.25),
    justifyContent: "center",
    boxShadow: "none",
    [theme.breakpoints.down("lg")]: {
        height: theme.spacing(5)
    }
}));

export const MenuContent = styled(Toolbar)(() => ({
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
}));

export const GridMenu = styled(Grid)(() => ({
    justifyContent: "space-between",
    alignItems: "center",
    height: "100%",
}));

export const GridItem = styled(Grid)(() => ({
    width: "fit-content",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
}));

export const GridItemAluno = styled(GridItem)(({ theme }) => ({
    [theme.breakpoints.up("lg")]: {
        width: "55%"
    }
}));

export const BoxMenu = styled(Box)(({ theme }) => ({
    display: "flex",
    background: "white",
    alignItems: "center",
    color: theme.palette.grey[900],
    height: theme.spacing(3),
    borderRadius: theme.spacing(5),
    padding: theme.spacing(0.313),
    maxWidth: theme.spacing(11)
}));

export const NomeUsuario = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.75),
    fontWeight: "600",
    maxWidth: theme.spacing(5.5),
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
}));

export const Icon = styled(IconButton)(({ theme }) => ({
    padding: "0",
    margin: "0",
    color: theme.palette.secondary.main,
}));

export const IconResponsivo = styled(RiMenuLine)({
    color: "white"
})

export const IconHeader = styled(IconButton)(({ theme }) => ({
    "&:focus": {
        borderRadius: "0",
        borderBottom: `${theme.spacing(0.188)} solid`,
        borderColor: theme.palette.primary.main
    },
}));

export const Menu = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.contrastText
}));

export const Moedas = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.dark,
    height: theme.spacing(2.5),
    padding: theme.spacing(0.938),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontWeight: 700,
    fontSize: theme.spacing(0.875),
    color: theme.palette.secondary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
    }
}));

export const ImagemMoedas = styled("img")(({ theme }) => ({
    width: theme.spacing(2)
}));

export const LogoImagem = styled("img")(({ theme }) => ({
    width: theme.spacing(9.375),
    [theme.breakpoints.down("md")]: {
        width: theme.spacing(5)
    }
}))