import { Grid, Modal } from "@mui/material";
import { ModalTextoMotivadorProps } from "./ModalTextoMotivador.types";
import { BoxModal } from "components/ModalAlert/ModalAlert.styles";
import { CloseOutlined } from "@mui/icons-material";


export const ModalTextoMotivador = ({ open, handleClose, idTextoMotivador, textoApoio }: ModalTextoMotivadorProps) => {

    return (
        <Modal open={open}>
            <BoxModal style={{ maxHeight: "80vh", width: "40vw" }}>
                <Grid container display="flex" justifyContent="space-between" item={true}>
                    <h3>Texto motivador</h3>
                    <CloseOutlined onClick={handleClose} style={{cursor: 'pointer'}}/>
                </Grid>
                <div>
                    <iframe
                        src={textoApoio}
                        title="Texto motivador"
                        style={{
                            width: "100%",
                            height: "70vh",
                            border: "none"
                        }}
                    >
                        {idTextoMotivador}

                    </iframe>
                </div>
            </BoxModal>
        </Modal>
    )

}
