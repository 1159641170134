import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserCredentials } from "api/models/token";
import { queryClient } from "api/services/queryClient";
import { RootState } from "reducers/index";

interface Auth {
    isLoggedIn: boolean;
    userCredentials: UserCredentials;
}

const initialUserState: UserCredentials = {
    username: "",
    token: "",
    refreshToken: "",
    perfil: ""
};

const initialState: Auth = {
    isLoggedIn: false,
    userCredentials: initialUserState
};

const slice = createSlice({
    name: "authentication",
    initialState,
    reducers: {
        setUserCredentials: (
            state,
            { payload }: PayloadAction<UserCredentials>
        ) => {
            state.userCredentials = payload;

            state.isLoggedIn = true;
        },
        setIsLoggedIn: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.isLoggedIn = payload
        },
        logout(state) {
            sessionStorage.removeItem("access_token");
            localStorage.removeItem("username");
            localStorage.removeItem("refreshToken");
            localStorage.removeItem("perfil");

            state.isLoggedIn = false;
            state.userCredentials = initialUserState;

            queryClient.clear();
        },
        setToken: (state) => {
            state.userCredentials.token = localStorage.getItem("access_token") || "";
            state.userCredentials.username = localStorage.getItem("username") || "";
            state.userCredentials.refreshToken = localStorage.getItem("refreshToken") || "";
            state.userCredentials.perfil = (localStorage.getItem("perfil") as "AdminIa" | "AdminBackEnd" | undefined) ?? "";
        }
    },
});

export const { logout, setUserCredentials, setToken, setIsLoggedIn } =
    slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state: RootState) =>
    state.authentication.userCredentials;
export const getIsLoggedIn = (state: RootState) =>
    state.authentication.isLoggedIn;
export const getUserCredentials = (state: RootState) =>
    state.authentication.userCredentials;

