import { Grid, Tooltip } from "@mui/material"
import { Button } from "components/Button"
import { PageContainer } from "components/PageContainer"
import { TituloPrincipal } from "components/Titulos/Titulos.styled"
import { useEffect, useState } from "react"
import { toast } from "react-toastify";
import * as Styled from "./Vestibulares.styled"
import { GridColDef } from "@mui/x-data-grid"
import { enviaExcluirVestibular, obterTodosVestibularesPaginados } from "api/controllers/admin"
import { useDebounce } from "hooks/useDebounce"
import { DataGridComponent } from "components/DataGrid/DataGrid"
import { BiLoaderCircle } from "react-icons/bi"
import { IconeDelete, IconeEdit } from "components/Icons/Icons"
import { ModalAlert } from "components/ModalAlert"
import { ModalAdicionarVestbular } from "./ModalAdicionarVestibular/ModalAdicionarVestibular"
import { ModalEditarVestibular } from "./ModalEditarVestibular/ModalEditarVestibular"


export const Vestibulares = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [dados, setVestibulares] = useState<{ itens: any, config: any }>();
    const [filtro, setFiltro] = useState("");
    const filtroDebounced = useDebounce(filtro);

    const [modalAdicionarVestibular, setModalAdicionarVestibular] = useState(false);

    const [modalEditarVestibular, setModalEditarVestibular] = useState(false);
    const [dadosEditarSelecionado, setDadosEditarSelecionado] = useState(null);

    const abrirModalEditar = (dados: any) => {
        setDadosEditarSelecionado(dados)
        setModalEditarVestibular(true)
    }

    const fecharModalEditar = () => {
        setDadosEditarSelecionado(null)
        setModalEditarVestibular(false)
    }

    const [modalExcluir, setModalExcluir] = useState(false);
    const [excluir, setExcluir] = useState(false);
    const [idVestibular, setIdVestibular] = useState(0);

    useEffect(() => {
        const chamaExcluir = async () => {
            try {
                await enviaExcluirVestibular(idVestibular);
                setModalExcluir(false);
                toast.success("Vestibular excluido com sucesso");
                await buscar()
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            } finally {
                setExcluir(false);
            }
        }

        if (excluir)
            chamaExcluir();
    }, [excluir])

    const columns: GridColDef[] = [
        {
            field: "descricao",
            headerName: "Descrição",
            flex: 1
        },
        {
            field: "dataCadastro",
            headerName: "Data Cadastro",
            valueGetter: (e) => new Date(e.value).toLocaleDateString("pt-BR"),
            flex: 1
        },
        {
            field: "ativo",
            headerName: "Status",
            flex: 1,
            renderCell: (s) =>
                <Styled.Status status={!!s.value}>
                    {s.value ? "Ativo" : "Inativo"}
                </Styled.Status>,
        },
        {
            field: "id",
            headerName: "Ações",
            flex: 1,
            renderCell: (e) => {
                return <>
                    {e.row.id &&
                        <Tooltip title="Editar">
                            <span>
                                <IconeEdit onClick={() => abrirModalEditar(e.row)} />
                            </span>
                        </Tooltip>
                    }
                    &nbsp;
                    {e.row.id &&
                        <Tooltip title="Excluir">
                            <span>
                                <IconeDelete onClick={() => { setModalExcluir(e.row); setIdVestibular(e.value) }} />
                            </span>
                        </Tooltip>
                    }
                </>
            }
        }
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: dados?.itens,
        columns,
        quantidadePaginas: dados?.config.quantidadePaginas
    });


    const buscar = async () => {
        try {
            setIsLoading(true);
            const resposta = await obterTodosVestibularesPaginados({
                filtro: filtroDebounced ? `Descricao:${filtroDebounced}` : "",
                itensPorPagina: 5,
                ordem: ordem,
                paginaAtual: paginaAtual
            });
            setVestibulares(resposta)
            setIsLoading(false);
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        (async () => {
            await buscar();
        })()
    }, [paginaAtual, filtroDebounced, ordem]);


    return (
        <PageContainer>
            <Grid container justifyContent="space-between" item={true}>
                <TituloPrincipal>Vestibulares e universidades</TituloPrincipal>
                <Button onClick={() => setModalAdicionarVestibular(true)}>Adicionar</Button>
            </Grid>
            <br />
            <Styled.BoxAlinhamento>
                <Grid container item xs={12} md={12}>
                    <Styled.GridResultados >
                        {isLoading ? (
                            <Styled.AguardeLoading>Aguarde &nbsp; <BiLoaderCircle /></Styled.AguardeLoading>
                        ) : (
                            <>
                                {dados && <span>Mostrando {dados.itens.length + (dados.config.paginaAtual - 1) * dados.config.itensPorPagina} de {dados.config.quantidadeTotalItens} resultados</span>}
                            </>
                        )}
                    </Styled.GridResultados>
                </Grid>
                <Grid item xs={12} md={12} >
                    <Styled.BotaoPesquisar
                        value={filtro}
                        setValue={setFiltro}
                        placeholder="Pesquisar por descrição..."
                    />
                </Grid>
            </Styled.BoxAlinhamento>
            <br />
            {!isLoading && dados && Componente()}
            <ModalAdicionarVestbular handleClose={() => setModalAdicionarVestibular(false)} open={modalAdicionarVestibular} refetchVestibular={buscar} />
            <ModalEditarVestibular handleClose={() => fecharModalEditar()} open={modalEditarVestibular} refetchVestibular={buscar} dadosEditar={dadosEditarSelecionado} />
            <ModalAlert open={modalExcluir} tipo="excluir" texto="Deseja excluir este vestibular?" textoBotao="Excluir" handleClose={() => setModalExcluir(false)} showButton setValue={setExcluir} />
        </PageContainer>
    )
}