import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledPageContainer = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.down("md")]: {
        padding: theme.spacing(2, 2, 0),
    },
    [theme.breakpoints.up("md")]: {
        padding: theme.spacing(2, 2, 0),
    },
}));
