import { Grid, Typography, styled } from "@mui/material";
import { SearchBar } from "components/SearchBar/SearchBar";
import { TituloPrincipal } from "components/Titulos/Titulos.styled";



export const tituloPricRojo = styled(TituloPrincipal)(({ theme }) => ({
    color:"#9030DD"
}))
export const GridItens = styled(Grid)(({ theme }) => ({
    borderRadius: theme.spacing(0.5),
    display:"dsiplay",
    backgroundColor:theme.palette.secondary.contrastText,
    height: theme.spacing(8.5),
    padding: theme.spacing(1),
    justifyContent: "space-between",
    width: theme.spacing(14.438),
    flexDirection:"column",
}))
export const GridgraficoContainer = styled(Grid)(({ theme }) => ({
    border:"1px solid black",
    borderRadius: theme.spacing(0.5),
    display:"flex",
    backgroundColor:"#fffff",
    height: theme.spacing(20),
    padding: theme.spacing(1)
}))

export const Gridgrafico = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1)
}))

export const GridItensContainer = styled(Grid)(({ theme }) => ({
    display:"flex",
    flexDirection:"row",
    gap:"15px"
}))

export const GridText = styled(Typography)(({ theme }) => ({
    fontSize:theme.spacing(1),
    lineHeight:"14px",
    fontWeight: "600",
    marginBottom:theme.spacing(2)
}))

export const GridSubText = styled(Typography)(({ theme }) => ({
    fontSize:theme.spacing(1.5),
    fontWeight: "600",
    lineHeight: theme.spacing(2.25),
    color: theme.palette.grey[500]
}))

export const TextTaxaDeConversao = styled(Grid)(({ theme }) => ({
    backgroundColor: "#6FCF9766",
    color:"#2B6C4A",
    width:theme.spacing(3.813),
    height: theme.spacing(1.625),
    fontSize: theme.spacing(0.75),
    lineHeight:theme.spacing(1.125),
    fontFamily: "Poppins",
    fontWeight: "600",
    padding: theme.spacing(0.25),
    borderRadius:theme.spacing(0.5),
    display: "flex",

}))

export const GridContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.secondary.contrastText
}))

export const BotaoPesquisar = styled(SearchBar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(3.125),
    padding: theme.spacing(0.313),
    width: theme.spacing(22.188),
 
    "& svg": {
       color: theme.palette.secondary.main,
    },
 
 }));

 export const GridSelect = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
       width: "100%",
    },
    margin: theme.spacing(0.5)
 }));