import { CloseOutlined } from "@mui/icons-material"
import { Button, Grid, Link, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"
import { BoxModal } from "pages/Dashboard/ModalAdicionarSecretaria/ModalAdicionarSecretaria.styled"
import { useEffect, useState } from "react"
import { ModalBoletoProps } from "./ModalBoleto.types"
import { obterFaturasBoleto } from "api/controllers/pagamento"
import { options } from "pages/Orcamentos/Orcamentos.type"
import { GrSend } from "react-icons/gr";
import { toast } from "react-toastify"
import { enviarEmailBoleto } from "api/controllers/admin"

export const ModalBoleto = ({ open, handleClose, idPagarme, idGestao }: ModalBoletoProps) => {
    const [faturas, setFaturas] = useState<Array<any>>();

    useEffect(() => {
        (async () => {
            if (idPagarme) {
                const { data } = await obterFaturasBoleto(idPagarme);

                setFaturas(data);
            }
        })()
    }, [idPagarme]);

    const enviarEmail = async (idTransacao: string) => {
        try {
            await enviarEmailBoleto(idGestao, idTransacao);
            toast.success("E-mail enviado para gestão com sucesso")
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    return (
        <Modal open={open}>
            <BoxModal style={{ maxHeight: "80vh", width: "40vw" }}>
                <Grid container display="flex" justifyContent="space-between">
                    <h3>Faturas boleto</h3>
                    <CloseOutlined onClick={handleClose} />
                </Grid>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Preço</TableCell>
                                <TableCell align="center">PDF Boleto</TableCell>
                                <TableCell align="center">Status</TableCell>
                                <TableCell align="center">Enviar por e-mail</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {faturas?.map(item => {
                                if (item) {
                                    return (
                                        <TableRow>
                                            <TableCell align="center">R$ {(item.amount / 100).toLocaleString("pt-BR", options)}</TableCell>
                                            <TableCell align="center"><Link target="_blank" href={item.pdf}>Acessar PDF</Link></TableCell>
                                            <TableCell align="center">{item.status}</TableCell>
                                            <TableCell align="center"><GrSend fontSize={30} cursor="pointer" onClick={async () => await enviarEmail(item.id)} /></TableCell>
                                        </TableRow>
                                    )
                                }
                            }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </BoxModal>
        </Modal>
    )
}