import { Box, Grid, Popover, MenuItem, Select } from "@mui/material";
import { PageContainer } from "components/PageContainer";
import * as Styled from "./TelaMoedasB2B.styled";
import { useEffect, useState } from "react";
import { EscolaGestaoDTO } from "api/models/Admin";
import { ObterDadosMoedasPorAlunoAdmin, ObterEscolasAdmin, ObterImfoEscolaPorId, RemanejarMoedas } from "api/controllers/admin";
import { toast } from "react-toastify";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import { useDebounce } from "hooks/useDebounce";
import { AtualizarMoedasDTO, EscolaTurmaDTO } from "api/models/UsuarioMoeda";
import Lapis from "../../assets/Images/Moeda/Edicao.svg";
import { Escola } from "api/models/Usuarios";
import Imagem from "../../assets/Images/Moeda/game-icons_coins.svg";

export const MoedasB2B = () => {
    const [alunosUsu, setAlunosUsu] = useState<{ itens: any, config: any }>();
    const [escolas, setEscolas] = useState<Array<Escola>>([]);
    const [selectEscola, setSelectEscola] = useState(0);

    const [isLoading, setIsLoading] = useState(false);
    const [loadingSolicitacao, setLoadingSolicitacao] = useState(false);

    const [filtro, setFiltro] = useState("");
    const filtroDebounced = useDebounce(filtro);

    const [isPreviousData, setIsPreviousData] = useState<boolean>(false);

    // Implementação popover e captura valores
    const [moedasAluno, setMoedasAluno] = useState<number | null>(null);
    const [abrir, setAbrir] = React.useState<HTMLDivElement | null>(null);

    // Capturar idAluno, coluna dataGrid
    const [dadosEscola, setDadosEscola] = useState<EscolaTurmaDTO>();
    const [alteracaoDadosEscola, setAlteracaoDadosEscola] = useState<EscolaTurmaDTO>();
    const [idAluno, setIdAluno] = useState(0);
    const [idTurma, setIdTurma] = useState(0);

    const open = Boolean(abrir);

    useEffect(() => {
        ObterEscolasAdmin().then(val => setEscolas(val.data));
    }, []);

    const adicionarMoedas = () => {
        if (moedasAluno !== null && (dadosEscola?.qtdeDisponivelRemanejamento ?? 0) > 0) {
            setMoedasAluno(moedaAluno => (moedaAluno !== null ? moedaAluno + 1000 : null));
            setDadosEscola(moedaEscola => ({
                ...moedaEscola!,
                qtdeDisponivelRemanejamento: ((moedaEscola?.qtdeDisponivelRemanejamento ?? 0) - 1000) as number,
            }));
        } else {
            toast.warning('A escola não possui saldo de moedas suficiente!');
        }
    };

    const subtrairMoedas = () => {
        if (moedasAluno !== null && moedasAluno >= 1000) {
            setMoedasAluno(moedaAluno => (moedaAluno !== null ? moedaAluno - 1000 : null));
            setDadosEscola(moedaEscola => ({
                ...moedaEscola!,
                qtdeDisponivelRemanejamento: ((moedaEscola?.qtdeDisponivelRemanejamento ?? 0) + 1000) as number,
            }));
        } else {
            toast.warning('Todas as moedas disponíveis foram retiradas!');
        }
    };

    const obterDadosMoedas = async () => {
        setIsLoading(true);
        const resposta = await ObterDadosMoedasPorAlunoAdmin({
            itensPorPagina: 5,
            ordem: ordem,
            paginaAtual: paginaAtual,
            filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
            idEscola: selectEscola
        });
        setIsLoading(false);
        setAlunosUsu(resposta);
        setIsPreviousData(false);
    };

    const fecharResetar = () => {
        if (dadosEscola?.qtdeDisponivelRemanejamento !== alteracaoDadosEscola?.qtdeDisponivelRemanejamento) {
            setDadosEscola(alteracaoDadosEscola);
        }
        setAbrir(null);
    };

    const fecharManter = () => {
        setAbrir(null);
    };

    useEffect(() => {
        const obterDadosEscola = async () => {
            try {
                const dados = await ObterImfoEscolaPorId(selectEscola);
                setDadosEscola(dados.data);
                setAlteracaoDadosEscola(dados.data);

            } catch (error: any) {
                toast.error(error.response.data.message)
            }
        };
        obterDadosEscola();
    }, [selectEscola]);

    const atualizarMoedasEFechar = async () => {
        try {
            setLoadingSolicitacao(true);
            fecharManter();
            await atualizarMoedas();
        } catch (error: any) {
            toast.error(error.response?.data?.message || 'An unexpected error occurred.');
        } finally {
            setLoadingSolicitacao(false);
        }
    };

    // Envio troca de moedas
    const atualizarMoedas = async () => {
        try {
            const dadosAtualizacao: AtualizarMoedasDTO = {
                idAluno: idAluno,
                idTurma: idTurma,
                qntdeMoedaEscola: dadosEscola?.qtdeDisponivelRemanejamento ?? 0,
                qntdeMoedaAluno: moedasAluno ?? 0,
                perfilRemanejou: "Professor"
            };

            console.log('Atualizar Moedas Data:', dadosAtualizacao);

            await RemanejarMoedas(dadosAtualizacao);
            toast.success('Moedas alteradas com sucesso!');
            await obterDadosMoedas();
            setDadosEscola(dadosEscola);
            setAlteracaoDadosEscola(dadosEscola);

        } catch (error: any) {
            console.error('Error updating coins:', error);
            toast.error(error.response?.data?.message || 'Failed to update coins');
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'nome',
            headerName: 'Aluno',
            description: 'Nome do Aluno',
            type: 'ellipsis',
            align: 'left',
            flex: 2.5,
        },
        {
            field: 'moedasRecebidas',
            headerName: 'Moedas recebidas',
            description: 'Quantidade de Moedas recebidas',
            type: 'ellipsis',
            align: 'left',
            flex: 2.5,
        },
        {
            field: 'moedasGastas',
            headerName: 'Moedas usadas',
            description: 'Quantidade de moedas gastas',
            type: 'ellipsis',
            align: 'left',
            flex: 2.5,
        },
        {
            field: 'qtdeMoedas',
            headerName: "Disponíveis",
            description: 'Quantidade de Moedas disponíveis',
            align: 'left',
            flex: 1.5,
        },
        {
            field: 'idTurma',
            headerName: "Id Turma",
            description: 'Id Turma',
            align: 'left',
            flex: 1.5,
        },
        {
            align: 'left',
            field: 'id',
            headerName: 'Remanejar',
            description: 'Ícone das Moedas disponíveis',
            flex: 1,
            renderCell: (params: GridRenderCellParams<any>) => (
                <React.Fragment>
                    <Box
                        onClick={(event) => {
                            const moedasValue = Number(params.row.qtdeMoedas);
                            const idValue = Number(params.row.id);
                            const idTurnaValue = Number(params.row.idTurma);
                            setMoedasAluno(moedasValue);
                            setIdAluno(idValue);
                            setIdTurma(idTurnaValue);
                            setAbrir(event.currentTarget);
                        }}
                    >
                        <Styled.Lapis src={Lapis} alt="Lápis" />
                    </Box>
                </React.Fragment>
            ),
        },
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: alunosUsu?.itens,
        columns,
        quantidadePaginas: alunosUsu?.config.quantidadePaginas,
    });

    useEffect(() => {
        obterDadosMoedas();
    }, [paginaAtual, ordem, filtroDebounced, selectEscola]);

    return (
        <PageContainer>
            <Styled.TituloPrincipalStyled>Distribuição de moedas B2B</Styled.TituloPrincipalStyled>
            <Styled.GridSaldo>
                <Styled.TextoCard>Saldo Remanejamento</Styled.TextoCard>
                <Styled.GridFlex>
                    <Styled.Imagem src={Imagem} />&nbsp;&nbsp;
                    <Styled.Moedas>{dadosEscola?.qtdeDisponivelRemanejamento}</Styled.Moedas>
                </Styled.GridFlex>
            </Styled.GridSaldo>
            <Styled.GridFlex>
                <Styled.GridSelect xs={12} sm={6} md={4} lg={10}>
                    <Styled.LabelSelect>Escola</Styled.LabelSelect>
                    <Styled.SelectStyles onChange={(e: any) => setSelectEscola(e.target.value as number)} value={selectEscola}>
                        <MenuItem key={0} value={0}>Selecione uma Escola</MenuItem>
                        {escolas?.map(function (escola) {
                            return <MenuItem key={escola.id} value={escola.id}>{escola.nome}</MenuItem>
                        })}
                    </Styled.SelectStyles>
                </Styled.GridSelect>
                <Grid>
                    <Styled.BotaoPesquisar
                        value={filtro}
                        placeholder="Pesquisar por nome do aluno..."
                        setValue={setFiltro}
                    />
                </Grid>
            </Styled.GridFlex>
            <Grid container>
                {!isLoading && alunosUsu && (
                    <Styled.GridContainer item>
                        {Componente()}
                    </Styled.GridContainer>
                )}
            </Grid>

            <Popover
                open={open}
                anchorEl={abrir}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{
                    style: {
                        borderRadius: 10,
                    },
                }}
            >
                <Styled.PopoverBox>
                    {moedasAluno !== null && (
                        <React.Fragment>
                            <Styled.GridIcones>
                                <div>
                                    <Styled.Saldo>
                                        {moedasAluno}
                                    </Styled.Saldo>
                                </div>
                                <div>
                                    <Styled.AlinhamentoIcone><Styled.IconeAdicao onClick={adicionarMoedas} />  &nbsp; 1000</Styled.AlinhamentoIcone>
                                    <Styled.AlinhamentoIcone><Styled.IconeSub onClick={subtrairMoedas} />  &nbsp; 1000</Styled.AlinhamentoIcone>
                                </div>
                            </Styled.GridIcones>
                            <Styled.GridIBotoes>
                                <Styled.IconeFechar onClick={fecharResetar} />
                                <Styled.IconeAceitar onClick={atualizarMoedasEFechar} />
                            </Styled.GridIBotoes>
                        </React.Fragment>
                    )}
                </Styled.PopoverBox>
            </Popover>
        </PageContainer>
    );
};
