
import { Grid, MenuItem } from '@mui/material';
import { useEffect, useState, PureComponent } from 'react';
import * as Styled from "./B2Cgraficobarras.styles";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { toast } from 'react-toastify';
import { ObterInfiGrafico } from 'api/controllers/admin';
import { MoedasAlunoGrafico } from 'api/models/Admin';



const CustomTooltip = ({
    active,
    payload
}: TooltipProps<ValueType, NameType>) => {
    if (active && payload) {
        const day = payload[0].payload;
        return (
            <div
                key={day.name}
                style={{
                    padding: "6px",
                    backgroundColor: "white",
                    border: "1px solid grey"
                }}
            >
                <b>{day.name}</b>
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px" }}>
                        <p style={{ color: "#2D9CDB" }}>Quantidade de moeda disponivel: {day.moedasDisponivel}</p>
                        <p style={{ color: "#03db03" }}>Quantidade Gasta: {day.moedasGasta}</p>
                        <p style={{ color: "#03db03" }}>Quantidade Total Recebido: {day.totalMoedaRecebida}</p>
                    </div>

                </div>
            </div>
        );
    }
    return null;
};


export const B2CgraficoBarras = () => {
    const [obterInfiGrafico, setObterInfiGrafico] = useState<MoedasAlunoGrafico[]>();
    const [filtro, setfiltroOutros] = useState(365);

    useEffect(() => {
        const dadosGraficoOutros = async () => {
            try {

                const data = await ObterInfiGrafico(filtro)
                setObterInfiGrafico(data.data);
            } catch (error: any) {
                toast.error(error.response.data.message)
            }
        };
        dadosGraficoOutros();
    }, [filtro]);

    const data = obterInfiGrafico?.map(item => ({
        moedasDisponivel: item.moedasDisponivel,
        moedasGasta: item.moedasGasta,
        totalMoedaRecebida: item.totalMoedaRecebida,
    }));

    return (
        <Styled.Container container xs={12} md={12} sm={12}>
            <Styled.ContainerSelect>
                <Styled.TitleContentRedacao>Uso de moedas</Styled.TitleContentRedacao>

                <Styled.Filtro defaultValue={365}>
                    <MenuItem onClick={() => setfiltroOutros(30)} value={30}>Último Mês</MenuItem>
                    <MenuItem onClick={() => setfiltroOutros(60)} value={60}>Último Bimestre</MenuItem>
                    <MenuItem onClick={() => setfiltroOutros(180)} value={180}>Último Semestre</MenuItem>
                    <MenuItem onClick={() => setfiltroOutros(365)} value={365}>Último Ano</MenuItem>
                </Styled.Filtro>
            </Styled.ContainerSelect>

            <>
                {obterInfiGrafico && obterInfiGrafico?.length > 0 ? (
                    <>
                        <Styled.GridResponsivo width={400} height={300}>
                            <BarChart
                                width={100}
                                height={300}
                                data={data}
                                barSize={30}
                                margin={{
                                    top: 20,
                                    right: 0,
                                    left: 60,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="1 1" />
                                <XAxis dataKey="name" />
                                <YAxis />
                                <Tooltip />
                                <Legend />
                                <Bar dataKey="moedasDisponivel" name="Disponiveis" fill="#8AF93A" stackId="a" />
                                <Bar dataKey="moedasGasta" name="Usadas" fill="#F5BD33" stackId="a" />
                                <Bar dataKey="totalMoedaRecebida" name="Recebidas" fill="#69279D" stackId="a" />
                            </BarChart>
                        </Styled.GridResponsivo>
                    </>

                ) : (
                    <Styled.ContainerSemDados>
                        <p>Ainda não há dados no sistema.</p>
                    </Styled.ContainerSemDados>
                )}
            </>
        </Styled.Container>
    )
}