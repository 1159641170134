import { Grid, Paper, Table, TableCell, TableContainer, TableHead, TableRow, TableBody} from '@mui/material';
import { useDispatch, useSelector  } from 'hooks/useRedux';
import * as Styled from "./statusGerencial.styled";
import { getUserCredentials } from "reducers/authentication";
import { PageContainer } from 'components/PageContainer';
import { AlunosRegistradosDTO, ProfessoresRegistradosDTO, UsuariosRegistradosDTO, EscolasRegistradasDTO, NovosUsuariosTimeSeriesDTO, StatusDTO, redacoesAIPorTempoDTO, redacoesProfessorPorTempoDTO } from 'api/models/statusGerencialModel';
import { useEffect, useState } from "react";
import { ObterNovosUsuariosTimeSeries, ObterNumeroDeAlunosRegistrados, ObterNumeroDeEscolasRegistradas, ObterNumeroDeProfessoresRegistrados, ObterNumeroDeUsuariosRegistrados, ObterRedacoesAITimeSeries, ObterStatusPROD, ObterStatusUAT, ObterredacoesProfessorPorTempo } from 'api/controllers/statusGerencialController';
import { setHeaderEnable } from 'reducers/layout';
import Plot from 'react-plotly.js';

function createDataRow(
  service: string,
  uat: number|string,
  prod: number|string,) {
  return { service, uat, prod};
}

function barPlot(
  xaxis:any,
  yaxis:any,
  plotName:string,
  plotTitle:string,){
  return <Plot 
  data={[{x: xaxis,y: yaxis,type:'bar'}]} 
  config ={{
    scrollZoom:true,
    toImageButtonOptions: {
      format:'png', 
      filename:plotName,
      height:500,
      width:700,
      scale:1
    },displayModeBar: true,displaylogo: false,responsive: true}}
  layout={{xaxis: {tickangle: -45},yaxis: {title: plotTitle}}} />}

const AGUARDANDO_REQ = 'Aguardando requisição...'

export function StatusGerencial() {

  const dispatch = useDispatch();
  const { username } = useSelector(getUserCredentials)
  const [numeroDeUsuariosRegistrados,setNumeroDeUsuariosRegistrados]  = useState<UsuariosRegistradosDTO>();
  const [numeroDeAlunosRegistrados,setNumeroDeAlunosRegistrados]  = useState<AlunosRegistradosDTO>();
  const [numeroDeProfessoresRegistrados,setNumeroDeProfessoresRegistrados]  = useState<ProfessoresRegistradosDTO>();
  const [numeroDeEscolasRegistradas,setNumeroDeEscolasRegistradas]  = useState<EscolasRegistradasDTO>();
  const [novosUsuariosTimeSeries, setNovosUsuariosTimeSeries] = useState<NovosUsuariosTimeSeriesDTO>();
  const [redacoesAIPorTempo, setredacoesAIPorTempo] = useState<redacoesAIPorTempoDTO>();
  const [redacoesProfessorPorTempo, setredacoesProfessorPorTempo] = useState<redacoesProfessorPorTempoDTO>();
  const [APIstatusUAT,setAPIstatusUAT]  = useState<StatusDTO>();
  const [APIstatusPROD,setAPIstatusPROD]  = useState<StatusDTO>();
  useEffect(() => {
    ObterNumeroDeUsuariosRegistrados().then((value)=>{setNumeroDeUsuariosRegistrados(value)});
    ObterNumeroDeAlunosRegistrados().then((value)=>{setNumeroDeAlunosRegistrados(value)});
    ObterNumeroDeProfessoresRegistrados().then((value)=>{setNumeroDeProfessoresRegistrados(value)});
    ObterNumeroDeEscolasRegistradas().then((value)=>{setNumeroDeEscolasRegistradas(value)});
    ObterNovosUsuariosTimeSeries().then((value)=>{setNovosUsuariosTimeSeries(value)});
    ObterStatusUAT().then((value)=>{setAPIstatusUAT(value)});
    ObterStatusPROD().then((value)=>{setAPIstatusPROD(value)});
    ObterRedacoesAITimeSeries().then((value)=>{setredacoesAIPorTempo(value)});
    ObterredacoesProfessorPorTempo().then((value)=>{setredacoesProfessorPorTempo(value)});
  }, []);
  useEffect(() => {
    dispatch(setHeaderEnable(true));

  }, []);

  const rows = [
    createDataRow('Competencia1',APIstatusUAT?.competenciaum||AGUARDANDO_REQ, APIstatusPROD?.competenciaum||AGUARDANDO_REQ),
    createDataRow('Competencia2', APIstatusUAT?.competenciadois||AGUARDANDO_REQ, APIstatusPROD?.competenciadois||AGUARDANDO_REQ),
    createDataRow('Competencia3', APIstatusUAT?.competenciatres||AGUARDANDO_REQ, APIstatusPROD?.competenciatres||AGUARDANDO_REQ),
    createDataRow('Competencia4', APIstatusUAT?.competenciaquatro||AGUARDANDO_REQ, APIstatusPROD?.competenciaquatro||AGUARDANDO_REQ),
    createDataRow('Competencia5', APIstatusUAT?.competenciacinco||AGUARDANDO_REQ, APIstatusPROD?.competenciacinco||AGUARDANDO_REQ),
    createDataRow('Notas', APIstatusUAT?.notas||AGUARDANDO_REQ, APIstatusPROD?.notas||AGUARDANDO_REQ),
    createDataRow('Homonimas', APIstatusUAT?.palavrasambiguas||AGUARDANDO_REQ, APIstatusPROD?.palavrasambiguas||AGUARDANDO_REQ),
    createDataRow('Introduções', APIstatusUAT?.analiseintro||AGUARDANDO_REQ, APIstatusPROD?.analiseintro||AGUARDANDO_REQ),
    createDataRow('Utilidades', APIstatusUAT?.utilidades||AGUARDANDO_REQ, APIstatusPROD?.utilidades||AGUARDANDO_REQ),
    createDataRow('Login', APIstatusUAT?.login||AGUARDANDO_REQ, APIstatusPROD?.login||AGUARDANDO_REQ),

  ];
  return (
    <Styled.GridPrincipal container >

      <Styled.Div >
        <p >
          Saudações, {username}. Este é o Sistema de Observalidade CRIA.
        </p>

      </Styled.Div>
      <PageContainer>
        <Styled.TituloDash>Dashboard</Styled.TituloDash>
        <Styled.GridContainer container >
          <Styled.GridCard container >
            <Styled.GridDivisaoCard item  >
              <Styled.Card>
                <Styled.DivCard>
                <Styled.TituloCard>Usuários Registrados</Styled.TituloCard>
                {numeroDeUsuariosRegistrados &&
                    <Styled.DescricaoCard>{numeroDeUsuariosRegistrados.numeroDeUsuariosRegistrados}</Styled.DescricaoCard>

                  }
                </Styled.DivCard>
              </Styled.Card>
            </Styled.GridDivisaoCard>

            <Styled.GridDivisaoCard item >
              <Styled.Card>
                <Styled.DivCard>
                  <Styled.TituloCard>Alunos Registrados</Styled.TituloCard>
                  {numeroDeAlunosRegistrados &&
                    <Styled.DescricaoCard>{numeroDeAlunosRegistrados.numeroDeAlunosRegistrados}</Styled.DescricaoCard>

                  }
                </Styled.DivCard>
              </Styled.Card>
            </Styled.GridDivisaoCard>

            <Styled.GridDivisaoCard item   >
              <Styled.Card>
                <Styled.DivCard>
                  <Styled.TituloCardFlex>Professores Registrados</Styled.TituloCardFlex>
                  {numeroDeProfessoresRegistrados &&
                    <Styled.DescricaoCard>{numeroDeProfessoresRegistrados.numeroDeProfessoresRegistrados}</Styled.DescricaoCard>

                  }
                </Styled.DivCard>
              </Styled.Card>
            </Styled.GridDivisaoCard>
            <Styled.GridDivisaoCard item  >
              <Styled.Card>
                <Styled.DivCard>
                  <Styled.TituloCardFlex >Escolas Registradas</Styled.TituloCardFlex>
                  {numeroDeEscolasRegistradas &&
                    <Styled.DescricaoCard>{numeroDeEscolasRegistradas.numeroDeEscolasRegistradas}</Styled.DescricaoCard>

                  }
                </Styled.DivCard>
              </Styled.Card>
            </Styled.GridDivisaoCard>
          </Styled.GridCard>

          <Grid container spacing={2}>
          <Grid item xs={6}>
          <Styled.ContainerPlot>
              <Styled.TituloContainer>Novos Usuarios</Styled.TituloContainer>
              {barPlot(
                novosUsuariosTimeSeries?.novosUsuariosTimeSeries.dates,
                novosUsuariosTimeSeries?.novosUsuariosTimeSeries.values,
                'NovosUsuariosTimeSeries',
                'Novos usuários registrados')}
              </Styled.ContainerPlot>
                       </Grid>

                       
            <Grid item xs={6}>
            <Styled.ContainerPlot>
              <Styled.TituloContainer>Status de Aplicações</Styled.TituloContainer>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Serviço </TableCell>
                      <TableCell align="right">UAT</TableCell>
                      <TableCell align="right">Produção</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.service}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {row.service}
                        </TableCell>
                        <TableCell align="right">{row.uat}</TableCell>
                        <TableCell align="right">{row.prod}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer></Styled.ContainerPlot></Grid>

              <Grid container spacing={2}></Grid>
              <Grid item xs={6}>
              <Styled.ContainerPlot>
              <Styled.TituloContainer>Redações Corrigidas pelo CRIA</Styled.TituloContainer>
              {barPlot(
                redacoesAIPorTempo?.redacoesAIPorTempo.dates,
                redacoesAIPorTempo?.redacoesAIPorTempo.values,
                'redacoesAITimeSeries',
                'Número de redações corrigidas pelo CRIA')}
               </Styled.ContainerPlot>
                       </Grid>
                       <Grid item xs={6}>
                       <Styled.ContainerPlot>
              <Styled.TituloContainer>Redações Corrigidas pelos professores</Styled.TituloContainer>
              {barPlot(
                redacoesProfessorPorTempo?.redacoesProfessorPorTempo.dates,
                redacoesProfessorPorTempo?.redacoesProfessorPorTempo.values,
                'redacoesProfessorPorTempo',
                'Número de redações corrigidas pelos professores')}
              </Styled.ContainerPlot>
                       </Grid></Grid>
        
        </Styled.GridContainer>
      </PageContainer>
    </Styled.GridPrincipal>
  );
};