import { Box } from "@mui/material";
import { useSelector } from "hooks/useRedux";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserCredentials } from "reducers/authentication";
import HeaderMenu from "./HeaderMenu/HearderMenu";
import * as Styled from "./HeaderBar.styled";
import { NavLinkProps } from "./HeaderBar.types";
import logo from "assets/Images/Logo/logo_negativo.svg";

export function HeaderBar({ isActive }: NavLinkProps) {
    const [drawerAbrir, setDrawerAbrir] = useState(false);

    const navigate = useNavigate();

    const { username, perfil } = useSelector(getUserCredentials);

    const toggleDrawer = (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent) => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' ||
                    (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setDrawerAbrir(open);
        }

    return (
        <Box>
            <Styled.AppBarAluno>
                <Styled.MenuContent>
                    <Styled.GridValidacaoTela container responsivo={false}>
                        <Styled.GridMenu container>
                            <Styled.GridItemAluno>
                                <Styled.LogoImagem src={logo} />
                                {perfil === "AdminIa" &&
                                    <>
                                        <NavLink to='/statusGerencial'>
                                            <Styled.IconHeader
                                                edge="start"
                                                color="inherit"
                                                aria-label="logo"
                                            >
                                                <Styled.Menu>Status Gerencial</Styled.Menu>
                                            </Styled.IconHeader>
                                        </NavLink>
                                        <NavLink to='/vocabularios'>
                                            <Styled.IconHeader
                                                edge="start"
                                                color="inherit"
                                                aria-label="logo"
                                            >
                                                <Styled.Menu>Vocabularios</Styled.Menu>
                                            </Styled.IconHeader>
                                        </NavLink>
                                        <NavLink to='/statusGerencial'>
                                            <Styled.IconHeader
                                                edge="start"
                                                color="inherit"
                                                aria-label="logo"
                                            >
                                                <Styled.Menu>Temas e Redações</Styled.Menu>
                                            </Styled.IconHeader>
                                        </NavLink>
                                        <NavLink to='/statusGerencial'>
                                            <Styled.IconHeader
                                                edge="start"
                                                color="inherit"
                                                aria-label="logo"
                                            >
                                                <Styled.Menu>Modelos</Styled.Menu>
                                            </Styled.IconHeader>
                                        </NavLink>
                                        <NavLink to='/statusGerencial'>
                                            <Styled.IconHeader
                                                edge="start"
                                                color="inherit"
                                                aria-label="logo"
                                            >
                                                <Styled.Menu>Catálogo de Dados</Styled.Menu>
                                            </Styled.IconHeader>
                                        </NavLink>
                                    </>

                                }
                            </Styled.GridItemAluno>

                            <Styled.GridFinal lg={5} xl={4} >


                                <Styled.BoxMenu>
                                    <Box>
                                        <abbr title={username}>
                                            <Styled.NomeUsuario>
                                                {username}
                                            </Styled.NomeUsuario>
                                        </abbr>
                                    </Box>

                                    <HeaderMenu />
                                </Styled.BoxMenu>
                            </Styled.GridFinal>
                        </Styled.GridMenu>
                    </Styled.GridValidacaoTela>

                    <Styled.GridValidacaoTela container responsivo>
                        <Styled.LogoImagem onClick={() => navigate("/")} src={logo} />
                        <Styled.Icon
                            size="large"
                            aria-label="show more"
                            aria-controls="teste"
                            aria-haspopup="true"
                            onClick={toggleDrawer(!drawerAbrir)}
                            color="inherit"
                        >
                            <Styled.IconResponsivo />
                        </Styled.Icon>

                    </Styled.GridValidacaoTela>
                </Styled.MenuContent>
            </Styled.AppBarAluno>
        </Box>
    );
}