import { Grid } from "@mui/material";
import { useSelector } from "hooks/useRedux";
import { headerEnable } from "reducers/layout";
import type { LayoutProps } from "./Layout.types";
import * as Styled from "./Layout.styled";
import { getIsLoggedIn, getUserCredentials } from "reducers/authentication";
import { HeaderBar } from "components/HeaderBar";
import { Sidebar } from "components/Sidebar/Sidebar";

export function Layout({ children }: LayoutProps) {
  const headerIsEnable = useSelector(headerEnable);
  const isLoggedIn = useSelector(getIsLoggedIn);
  const { perfil } = useSelector(getUserCredentials);
  
  if (isLoggedIn && headerIsEnable) {
    return (
      <>
        <Styled.StyledLayoutContainer >
          {perfil === "AdminIa" && <HeaderBar />}
          <Grid container>
            {perfil === "AdminBackEnd" && <Sidebar />}
            <Styled.containerContentAluno isMargin={perfil === "AdminIa"} xs={perfil === "AdminBackEnd" ? 10 : 12}>
              {children}
            </Styled.containerContentAluno>
          </Grid>
        </Styled.StyledLayoutContainer>
      </>
    );
  } else if (!headerIsEnable) {
    return (
      <Styled.StyledLayoutContainer>
        {children}
      </Styled.StyledLayoutContainer>
    )
  }
  else {
    return (
      <Styled.StyledLayoutContainer>
        {children}
      </Styled.StyledLayoutContainer>
    )
  }
}