import { Grid, Modal, Typography } from "@mui/material"
import { PageContainer } from "components/PageContainer"
import { TituloPrincipal } from "components/Titulos/Titulos.styled"
import * as Styled from "./B2C.styled";
import { B2CgraficoBarras } from "./B2Cgraficobarras";
import { B2CgraficoLine } from "./B2CgraficoLine";
import { useEffect, useState } from "react";
import { CardsInfoAlunoAdmin, EscolaGestaoDTO } from "api/models/Admin";
import { obterInfoAlunoAdmin, obterEscolasGestao, obterGridTodosAlunosUsuarios } from "api/controllers/admin";
import { toast } from "react-toastify";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import SubidaIcon from "../../assets/Images/Icones/subir.png"
import { useDebounce } from "hooks/useDebounce";

export const B2C = () => {

    const [infoCards, setInfocards] = useState<CardsInfoAlunoAdmin>()
    const [alunosUsu, setAlunosUsu] = useState<{ itens: any, config: any }>();

    const [isLoading, setIsLoading] = useState(false);

    const [filtro, setFiltro] = useState("");
    const filtroDebounced = useDebounce(filtro);

    useEffect(() => {
        const BuscarInfo = async () => {
            try {
                const { data } = await obterInfoAlunoAdmin();
                setInfocards(data);
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            }
        }
        BuscarInfo()
    }, [])

    const columns: GridColDef[] = [
        {
            field: "nome",
            headerName: "Nome",
            flex: 1
        },
        {
            field: "idUsuario",
            headerName: "Id Usuario",
            flex: 1
        },
        {
            field: "email",
            headerName: "E-mail",
            flex: 1
        },
        {
            field: "telefone",
            headerName: "Celular",
            flex: 1
        }
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: alunosUsu?.itens,
        columns,
        quantidadePaginas: alunosUsu?.config.quantidadePaginas,
    });

    useEffect(() => {
        (async () => {
            const resposta = await obterGridTodosAlunosUsuarios({
                itensPorPagina: 5,
                ordem: ordem,
                paginaAtual: paginaAtual,
                filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
                idEscola: 1,
            });

            setAlunosUsu(resposta);
        })()
    }, [paginaAtual, ordem, filtroDebounced])

    return (
        <PageContainer>
            <Styled.tituloPricRojo>B2C</Styled.tituloPricRojo>

            <Styled.GridItensContainer>
                <Styled.GridItens lg={4}>
                    <Styled.GridText>Cadastros / Login</Styled.GridText>
                    <Styled.GridSubText>{infoCards?.cadastro} / {infoCards?.login}</Styled.GridSubText>
                </Styled.GridItens>
                <Styled.GridItens lg={4}>
                    <Styled.GridText>Total de vendas</Styled.GridText>
                    <Styled.GridSubText>{infoCards?.vtotalDeUsuQcomprou}</Styled.GridSubText>
                </Styled.GridItens>
                <Styled.GridItens lg={4}>
                    <Styled.GridText>Moedas usadas</Styled.GridText>
                    <Styled.GridSubText>{infoCards?.totalMoedasUsadas}</Styled.GridSubText>
                </Styled.GridItens>
                <Styled.GridItens lg={4}>
                    <Styled.GridText>Redações feitas</Styled.GridText>
                    <Styled.GridSubText>{infoCards?.redacoesFeitas}</Styled.GridSubText>
                </Styled.GridItens>
            </Styled.GridItensContainer>

            <Styled.GridItensContainer>

                <Styled.Gridgrafico xs={12} lg={6}>
                    <B2CgraficoLine />
                </Styled.Gridgrafico>
                <Styled.Gridgrafico xs={12} lg={6}>
                    <B2CgraficoBarras />
                </Styled.Gridgrafico>
            </Styled.GridItensContainer>

            <Styled.BotaoPesquisar
                value={filtro}
                placeholder="Pesquisar por nome do aluno..."
                setValue={setFiltro}
            />

            <Grid container>
                {!isLoading && alunosUsu &&
                    <Styled.GridContainer item>
                        {Componente()}
                    </Styled.GridContainer>
                }
            </Grid>
        </PageContainer>
    )
}
