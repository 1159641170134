import { inputBaseClasses } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactInputMask from "react-input-mask";

export const StyledInputText = styled(ReactInputMask)(({ theme }) => ({
    border: `${theme.spacing(0.0625)} solid ${theme.palette.grey[200]}`,
    background: "white",
    boxSizing: "border-box",
    borderRadius: theme.spacing(20.375),
    padding: theme.spacing(0.625),
    height: theme.spacing(2.5),
    fontSize: theme.spacing(0.875),
    fontWeight: 500,
    width: "100%",
    [`&.${inputBaseClasses.focused}`]: {
        border: `1px solid ${theme.palette.primary.light}`
    },
    [`&.${inputBaseClasses.error}`]: {
        border: `1px solid ${theme.palette.error.main}`,
        color: theme.palette.error.main,
    },
    [`&.${inputBaseClasses.disabled}`]: {
        background: "black !important"
    }
}));
