import { IconButton } from "@mui/material";
import { InputText } from "components/Inputs";
import { RiCloseLine } from "react-icons/ri";
import * as Styled from "./SearchBar.styled";
import { SearchBarProps } from "./SearchBar.types";

export const SearchBar = ({ value, setValue, placeholder, iconVariant = "primary", ...rest }: SearchBarProps) => {
    return (
        <InputText
            {...rest}
            value={value}
            onChange={(ev) => setValue(ev.target.value)}
            placeholder={placeholder || "Procurar..."}
            startAdornment={<Styled.SearchIcon variant={iconVariant} />}
            endAdornment={(
                <IconButton
                    title="Limpar"
                    aria-label="limpar"
                    size="small"
                    style={{ visibility: value ? 'visible' : 'hidden' }}
                    onClick={() => setValue("")}
                >
                    <RiCloseLine />
                </IconButton>
            )}
        />
    )
}