import { Box, Button, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';


export const BoxModal = styled(Box)(({ theme }) => ({
    background: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2)
}))

export const Icone = styled("img")(({ theme }) => ({
    cursor: 'pointer',
    width: '85px',
    height: '85px',
    padding: '8px',
    borderRadius: '100%',
    margin: "20px",
}));

export const ConteudoModalAlert = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontWeight: 600,
    fontSize: "16px",
    margin: "5%"
}));

export const GridModalAlert = styled(Grid)(({ theme }) => ({
    "& .show": {
        display: "flex",
        width: "100%",
        justifyContent: "center"
    },
    "& .hide": {
        display: "none"
    },
    display: "flex",
    alignItems: "center !important",
    justifyContent: "center !important"
}));

export const Botao = styled(Button)(({ theme }) => ({
    width: "100%",
    borderRadius: "24px",
    margin: "10px",
    fontWeight: 600
}));

export const BotaoInfo = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.contrastText,
    width: "100%",
    borderRadius: "24px",
    margin: "10px",
    fontWeight: 600
}));

export const GridCentro = styled(Grid)({
    display: "flex",
    justifyContent: "center"
})