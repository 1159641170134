export type ModalEditarProps = {
    open: boolean;
    handleClose: () => void;
    refetchPropostas: () => Promise<void>;
    dadosEditar: any | undefined;
}

export type FormValues = {
    idAssunto: number;
    tema: string;
    instrucoes: string;
    disponivel: boolean;
    vestibularId?: number;
    novoestibularId?: number;
}

export const initialValues: FormValues = {
    idAssunto: 0,
    tema: "",
    instrucoes: "",
    disponivel: false,
    vestibularId: 0,
}