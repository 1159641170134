import { Grid, Typography, styled } from "@mui/material";
import { SearchBar } from "components/SearchBar";
import { TituloPrincipal } from "components/Titulos/Titulos.styled";

export const TituloPrincipalStyled = styled(TituloPrincipal)(({ theme }) => ({
    color:"#9030DD"
}));

export const GridContainer = styled(Grid)(({ theme }) => ({
    width: "100%",
    backgroundColor: theme.palette.secondary.contrastText
}))

export const BotaoPesquisar = styled(SearchBar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(3.125),
    padding: theme.spacing(0.313),
    width: theme.spacing(22.188),
 
    "& svg": {
       color: theme.palette.secondary.main,
    },
 
 }));

 export const ContainerSemDados = styled(Grid)(({ theme }) => ({
    display:"flex",
    flexDirection:"column",
    alignItems:"center",
    justifyContent:"center",
    width: "100%",
    "p": {
       fontSize: theme.spacing(1),
       color: theme.palette.grey[500],
    },
    "img": {
        width: "6%",
     }
 }));