import { useFormik } from "formik";
import { initialValues, ModalEditarProps } from "./ModalEditarVestibular.types";
import { Vestibular } from "api/models/vestibular";
import * as Yup from "yup";
import * as Styled from "./ModalEditarVestibular.styled";
import { toast } from "react-toastify";
import { Grid, Modal } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { Button } from "components/Button";
import { InputText } from "components/Inputs";
import { editarVestibular } from "api/controllers/admin";
import { useEffect, useState } from "react";


const validationSchema = Yup.object({
    descricao: Yup.string().required("Insira um vestibular ou universidade!"),
})

export const ModalEditarVestibular = ({ open, handleClose, refetchVestibular, dadosEditar }: ModalEditarProps) => {

    const [vestibular, setVestibular] = useState<Vestibular>();

    const { handleSubmit, values, errors, touched, handleChange, setFieldValue, isSubmitting, resetForm } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    async function onSubmit(values: Vestibular) {
        try {     
            await editarVestibular(values);
            toast.success("Vestibular atualizado com sucesso!")
            handleClose();
            resetForm();
            refetchVestibular()
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    const setValues = (data: any) => {
        if (data) {
            setFieldValue("id", data.id)
            setFieldValue("descricao", data.descricao)
            setFieldValue("ativo", data.ativo)

            setVestibular(data)
        }
    }

    useEffect(() => {
        if (open && dadosEditar){
            setValues(dadosEditar)
        }
    }, [open, dadosEditar])

    return (
        <Modal open={open}>
        <Styled.BoxModal>
            {!isSubmitting ?
                <>
                    <Grid container display="flex" justifyContent="space-between">
                        <h3>Editar vestibular ou universidade</h3>
                        <CloseOutlined onClick={() => { handleClose(); resetForm() }} />
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid container item={true}>
                            <Styled.GridFormControl xs={12} md={12}>
                                Descrição:
                                <InputText
                                    placeholder="Ex.: Enem"
                                    name="descricao"
                                    type="text"
                                    value={values.descricao}
                                    onChange={handleChange}
                                    error={!!errors.descricao && touched.descricao} />
                            </Styled.GridFormControl>
                            <br />
                            <Grid xs={12} md={6} display="flex" alignItems="end" padding="0.5rem">
                                <Button style={{ width: "100%" }} color="primary" type="submit">Editar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </> :
                "Editando..."
            }
        </Styled.BoxModal>
    </Modal>
    )

}