import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxModal = styled(Box)(({ theme }) => ({
    background: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2)
}))

export const GridFormControl = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(0.5)
}));
