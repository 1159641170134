import { StyledEngineProvider, ThemeProvider } from "@mui/material";
import ReactDOM from "react-dom/client";
import "styles/index.scss";
import { theme } from "styles/theme";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./reducers/index";
import React from "react";

const root = ReactDOM.createRoot(document.getElementById('root') as Element)
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                        <Provider store={store}><App /></Provider>
                </ThemeProvider>
            </StyledEngineProvider>
        </BrowserRouter>
    </React.StrictMode>);