import { Grid } from "@mui/material";
import { useState, useEffect, SetStateAction } from "react";
import { obterGridTodosAlunosUsuarios, obterGridTodosProfessoresUsuarios, obterGridTodosUsuariosGestao } from "api/controllers/admin";
import { obterGridTodosEscolaUsuarios } from "api/controllers/admin";
import { PageContainer } from "components/PageContainer";
import { TituloPrincipal } from "components/Titulos/Titulos.styled";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ModalEdit } from "./ModalEdit/ModalEdit";
import * as Styled from "./Usuarios.styled";
import { ModalEditEscola } from "./ModalEditEscola";
import { ModalEditProfessor } from "./ModalEditProfessor/ModalEditProfessor";
import { ModalEditGestao } from "./ModalEditGestao";
import { useDebounce } from "hooks/useDebounce";
import { useLocation } from "react-router-dom";


export function Usuarios() {
    const [alunosUsu, setAlunosUsu] = useState<{ itens: any, config: any }>();
    const [escolaUsu, setEscolaUsu] = useState<{ itens: any, config: any }>();
    const [gestaoUsu, setGestaoUsu] = useState<{ itens: any, config: any }>();
    const [professorUsu, setProfessorUsu] = useState<{ itens: any, config: any }>();

    const [isLoading, setIsLoading] = useState(false);
    const [selectedButton, setSelectedButton] = useState('aluno');

    const [idUsu, setIdUsu] = useState(0)

    const handleModaleditAlunoOpen = () => setEditModalAlunoOpen(false);
    const [editModalAlunoOpen, setEditModalAlunoOpen] = useState(false);

    const handleModaleditEscolaOpen = () => setEditModalEscolaOpen(false);
    const [editModalEscolaOpen, setEditModalEscolaOpen] = useState(false);

    const handleModaleditProfessorOpen = () => setEditModalProfessorOpen(false);
    const [editModalProfessorOpen, setEditModalProfessorOpen] = useState(false);

    const handleModalEditGestaoOpen = () => setEditModalEditGestaoOpen(false);
    const [editModalEditGestaoOpen, setEditModalEditGestaoOpen] = useState(false);

    const [filtro, setFiltro] = useState("");
    const filtroDebounced = useDebounce(filtro);

    const [isPreviousData, setIsPreviousData] = useState<boolean>(false);

    const handleFiltroChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setFiltro(event.target.value);
    };

    const columns: GridColDef[] = [
        {
            field: "nome",
            headerName: "Nome",
            flex: 1
        },
        {
            field: "idUsuario",
            headerName: "Id Usuario",
            flex: 1
        },
        {
            field: "email",
            headerName: "E-mail",
            flex: 1
        },
        {
            field: "telefone",
            headerName: "Celular",
            flex: 1
        },
        {
            type: 'acoes',
            align: 'right',
            field: 'id',
            headerName: 'AÇÕES',
            description: 'Ações',
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => (
                <React.Fragment>
                    <Grid container>

                        {params.value &&
                            <>
                                <EditOutlinedIcon onClick={() => {
                                    setIdUsu(params.row.id);
                                    if (selectedButton === 'aluno') {
                                        setEditModalAlunoOpen(true);
                                    } else if (selectedButton === 'escola') {
                                        setEditModalEscolaOpen(true);
                                    } else if (selectedButton === 'gestao') {
                                        setEditModalEditGestaoOpen(true);
                                    }
                                }} />

                                &nbsp;
                            </>
                        }

                    </Grid>
                </React.Fragment>
            ),
        }
    ];

    const columnsProfessor: GridColDef[] = [
        {
            field: "nome",
            headerName: "Nome",
            flex: 1
        },
        {
            field: "idUsuario",
            headerName: "Id Usuario",
            flex: 1
        },
        {
            field: "cpf",
            headerName: "CPF",
            flex: 1
        },
        {
            field: "telefone",
            headerName: "Celular",
            flex: 1
        },
        {
            type: 'acoes',
            align: 'right',
            field: 'id',
            headerName: 'AÇÕES',
            description: 'Ações',
            flex: 0.8,
            renderCell: (params: GridRenderCellParams) => (
                <React.Fragment>
                    <Grid container>

                        {params.value &&
                            <>
                                <EditOutlinedIcon onClick={() => {
                                    setIdUsu(params.row.id);
                                    setEditModalProfessorOpen(true);

                                }} />

                                &nbsp;
                            </>
                        }

                    </Grid>
                </React.Fragment>
            ),
        }
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: alunosUsu?.itens,
        columns,
        quantidadePaginas: alunosUsu?.config.quantidadePaginas,
    });

    const { Componente: ComponenteEscola, ordem: ordemEscola, paginaAtual: paginaAtualEscola } = DataGridComponent({
        data: escolaUsu?.itens,
        columns,
        quantidadePaginas: escolaUsu?.config.quantidadePaginas
    });
    const { Componente: ComponenteProfessor, ordem: ordemProfessor, paginaAtual: paginaAtualProfessor } = DataGridComponent({
        data: professorUsu?.itens,
        columns: columnsProfessor,
        quantidadePaginas: professorUsu?.config.quantidadePaginas
    });
    const { Componente: ComponenteGestao, ordem: ordemGestao, paginaAtual: paginaAtualGestao } = DataGridComponent({
        data: gestaoUsu?.itens,
        columns,
        quantidadePaginas: gestaoUsu?.config.quantidadePaginas
    });

    const fetchData = async () => {
        setIsLoading(true);
        try {
            let resposta;
            if (selectedButton === 'aluno') {
                resposta = await obterGridTodosAlunosUsuarios({
                    itensPorPagina: 6,
                    ordem: ordem,
                    paginaAtual: paginaAtual,
                    filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
                });
                setAlunosUsu(resposta);
                setIsPreviousData(false);
            } else if (selectedButton === 'escola') {
                resposta = await obterGridTodosEscolaUsuarios({
                    filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
                    itensPorPagina: 6,
                    ordem: ordemEscola,
                    paginaAtual: paginaAtualEscola
                });
                setEscolaUsu(resposta);
                setIsPreviousData(false);
            } else if (selectedButton === 'professor') {
                resposta = await obterGridTodosProfessoresUsuarios({
                    filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
                    itensPorPagina: 6,
                    ordem: ordemProfessor,
                    paginaAtual: paginaAtualProfessor
                });
                setProfessorUsu(resposta);
                setIsPreviousData(false);
            } else if (selectedButton === 'gestao') {
                resposta = await obterGridTodosUsuariosGestao({
                    filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
                    itensPorPagina: 6,
                    ordem: ordemGestao,
                    paginaAtual: paginaAtualGestao
                });
                setGestaoUsu(resposta);
                setIsPreviousData(false);
            }
        } catch (error) {
            console.error("Erro ao obter dados:", error);
        }
        setIsLoading(false);
    };



    useEffect(() => {
        (async () => {
            await fetchData()

        })()
    }, [selectedButton, paginaAtual, ordem, ordemEscola, paginaAtualEscola, ordemProfessor, paginaAtualProfessor, ordemGestao, paginaAtualGestao, filtroDebounced]);

    const handleButtonClick = (buttonName: SetStateAction<string>) => {
        setSelectedButton(buttonName);
    };

    return (
        <PageContainer>
            <Grid>
                <TituloPrincipal>Usuarios</TituloPrincipal>
            </Grid>

            <Styled.ButtonUsuarios
                isActive={selectedButton === "aluno"}
                onClick={() => handleButtonClick('aluno')}
            >
                Aluno
            </Styled.ButtonUsuarios>
            <Styled.ButtonUsuarios
                isActive={selectedButton === "escola"}
                onClick={() => handleButtonClick('escola')}
            >
                Escola
            </Styled.ButtonUsuarios>
            <Styled.ButtonUsuarios
                isActive={selectedButton === "professor"}
                onClick={() => handleButtonClick('professor')}
            >
                Professor
            </Styled.ButtonUsuarios>
            <Styled.ButtonUsuarios
                isActive={selectedButton === "gestao"}
                onClick={() => handleButtonClick('gestao')}
            >
                Gestao
            </Styled.ButtonUsuarios>
            <Grid>

                <Styled.BotaoPesquisar
                    value={filtro}
                    onChange={handleFiltroChange}
                    placeholder="Pesquisar por nome do aluno..."
                    setValue={setFiltro}
                />


                {selectedButton === 'aluno' && (
                    <Grid container>
                        {!isLoading && alunosUsu &&
                            <Grid container mt={5}>
                                {Componente()}
                            </Grid>
                        }
                    </Grid>
                )}

                {selectedButton === 'escola' && (
                    <Grid container>
                        {!isLoading && escolaUsu &&
                            <Grid container mt={5}>
                                {ComponenteEscola()}
                            </Grid>
                        }
                    </Grid>
                )}
                {selectedButton === 'professor' && (
                    <Grid container>
                        {!isLoading && professorUsu &&
                            <Grid container mt={5}>
                                {ComponenteProfessor()}
                            </Grid>
                        }
                    </Grid>
                )}
                {selectedButton === 'gestao' && (
                    <Grid container>
                        {!isLoading && gestaoUsu &&
                            <Grid container mt={5}>
                                {ComponenteGestao()}
                            </Grid>
                        }
                    </Grid>
                )}
            </Grid>
            <ModalEdit idUsu={idUsu} open={editModalAlunoOpen} handleClose={handleModaleditAlunoOpen} fetchData={fetchData} />
            <ModalEditEscola idUsu={idUsu} open={editModalEscolaOpen} handleClose={handleModaleditEscolaOpen} fetchData={fetchData} />
            <ModalEditProfessor idUsu={idUsu} open={editModalProfessorOpen} handleClose={handleModaleditProfessorOpen} fetchData={fetchData} />
            <ModalEditGestao idUsu={idUsu} open={editModalEditGestaoOpen} handleClose={handleModalEditGestaoOpen} fetchData={fetchData} />
        </PageContainer>

    )
}
