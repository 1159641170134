import { BeatLoader } from "react-spinners";
import * as Styled from "./Button.styled";
import { ButtonProps } from "./Button.types";

export function Button({ children, size = "md", variant = "normal", color = "primary", isLoading = false, ...rest }: ButtonProps) {
    return (
        <Styled.Button size={size} variant={variant} color={color} {...rest}>
            {isLoading && <BeatLoader color="#FFFFFF" size="8px" />}
            {!isLoading && children}
        </Styled.Button>
    )
}

