import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import authentication from "reducers/authentication";
import layout from "reducers/layout";
import escola from "./escola";

export const createStore = () =>
    configureStore({
        reducer: {
            authentication,
            layout,
            escola
        },
    });

export const store = createStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

export type State = ReturnType<typeof store.getState>;

export default store;
