import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Escola } from "../api/models/Usuarios";
import { PlanoEscolaTransacoesDTO } from "api/models/planoEscola";
import { RootState } from "reducers";

interface EscolaReducers {
    edicaoPlano: boolean,
    loadingCadastroEscola: boolean,
    planoEscola?: PlanoEscolaTransacoesDTO,
    escola?: Escola
}

const initialState: EscolaReducers = {
    edicaoPlano: false,
    loadingCadastroEscola: false,
    planoEscola: undefined,
    escola: undefined
}

const slice = createSlice({
    name: "escola",
    initialState,
    reducers: {
        setEdicaoPlano: (state, { payload }: PayloadAction<boolean>) => { state.edicaoPlano = payload },
        setLoadingCadastroEscola: (state, { payload }: PayloadAction<boolean>) => { state.loadingCadastroEscola = payload },
        setPlanoEscola: (state, { payload }: PayloadAction<PlanoEscolaTransacoesDTO | undefined>) => { state.planoEscola = payload },
        setEscola: (state, { payload }: PayloadAction<Escola>) => { state.escola = payload }
    }
});

export const { setEdicaoPlano, setLoadingCadastroEscola, setPlanoEscola, setEscola } = slice.actions;

export default slice.reducer;

export const getEdicaoPlano = (state: RootState) => state.escola.edicaoPlano;

export const getLoadingCadastroEscola = (state: RootState) => state.escola.loadingCadastroEscola;

export const getPlanoEscola = (state: RootState) => state.escola.planoEscola;

export const getEscola = (state: RootState) => state.escola.escola;