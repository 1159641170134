
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "reducers/index";

interface Layout {
    headerEnable: boolean;
    micEnable: boolean;
    alreadyLoggedIn: boolean;
    isHeaderDashboardProfessor: boolean;
    idTurma: number;
    isSideBarEnabled: boolean;
    modalReportarErro: boolean;
    atualizaMoedas: boolean;
}

const initialState: Layout = {
    headerEnable: true,
    micEnable: true,
    alreadyLoggedIn: false,
    isHeaderDashboardProfessor: false,
    idTurma: 0,
    isSideBarEnabled: true,
    modalReportarErro: false,
    atualizaMoedas: false,
}

const slice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setHeaderEnable: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.headerEnable = payload;
        },
        setMicEnable: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.micEnable = payload;
        },
        setAlreadyLoggedIn: (
            state,
            { payload }: PayloadAction<boolean>
        ) => {
            state.alreadyLoggedIn = payload;
        },
        setIsSideBarEnabled: (state, { payload }: PayloadAction<boolean>) => {
            state.isSideBarEnabled = payload;
        },
        setModalReportarErro: (state, { payload }: PayloadAction<boolean>) => {
            state.modalReportarErro = payload;
        },
        setAtualizaMoedas: (state, { payload }: PayloadAction<boolean>) => {
            state.atualizaMoedas = payload;
        },
    },
    
});

export const { setHeaderEnable, setMicEnable, setAlreadyLoggedIn, setAtualizaMoedas, setIsSideBarEnabled, setModalReportarErro } =
    slice.actions;

export default slice.reducer;

export const headerEnable = (state: RootState) =>
    state.layout.headerEnable

export const micEnable = (state: RootState) =>
    state.layout.micEnable

export const alreadyLoggedIn = (state: RootState) =>
    state.layout.alreadyLoggedIn

export const isHeaderDashboardProfessor = (state: RootState) =>
    state.layout.isHeaderDashboardProfessor
    
export const getIdTurma= (state: RootState) =>
    state.layout.idTurma

export const getIsSideBarEnabled = (state: RootState) =>
    state.layout.isSideBarEnabled

export const getModalReportarErro = (state: RootState) =>
    state.layout.modalReportarErro

export const getAtualizarMoedas = (state: RootState) =>
    state.layout.atualizaMoedas