import { styled } from "@mui/material/styles";
import { RiSearchLine } from "react-icons/ri";

export const SearchIcon = styled(RiSearchLine)<{ variant: "primary" | "gray" }>(
    ({ theme, variant }) => ({
        color:
            variant === "primary"
                ? theme.palette.primary.main
                : theme.palette.grey[500] ,
        fontSize: theme.spacing(1.5),
        marginRight: theme.spacing(0.625),
    })
);
