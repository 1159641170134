import { Grid, Modal, Typography } from "@mui/material";
import { FormValues, ModalEditEscolaProps } from "./ModalEditEscola.types";
import * as Styled from "../ModalEdit/ModalEdit.styles";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { editarUsuEscolaAdm, obterInfoEscolaUsario } from "api/controllers/admin";
import { AdmUsuarioEditDTO } from "api/models/Usuarios";
import { telefoneMaskFormatter } from "../../../util/mask-formatters";
import { EnderecoForm } from "components/EnderecoForm/EnderecoForm";
import { initialValues } from "components/EnderecoForm/EnderecoForm.types";

export function ModalEditEscola({ idUsu, open, handleClose, fetchData }: ModalEditEscolaProps) {
    const [escolaUsu, setEscolaUsu] = useState<AdmUsuarioEditDTO>();

    useEffect(() => {
        const fetchEscolaUsu = async () => {
            try {
                const escola = await obterInfoEscolaUsario(idUsu);
                setEscolaUsu(escola);
                resetForm({ values: escola });
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        };

        if (open) {
            fetchEscolaUsu();
        }
    }, [idUsu, open]);


    const onSubmit = async (values: FormValues) => {
        if (values.id === escolaUsu?.id &&
            values.idUsuario === escolaUsu?.idUsuario &&
            values.nome === escolaUsu?.nome &&
            values.email === escolaUsu?.email &&
            values.telefone === escolaUsu?.telefone &&
            values.descricao === escolaUsu?.descricao &&
            values.qtdeMoedas === escolaUsu?.qtdeMoedas &&
            values.cep === escolaUsu?.cep &&
            values.rua === escolaUsu?.rua &&
            values.bairro === escolaUsu?.bairro &&
            values.cidade === escolaUsu?.cidade &&
            values.estado === escolaUsu?.estado &&
            values.numero === escolaUsu?.numero) {
            toast.error("Nenhum campo foi alterado!");
        } else {
            const request = {
                id: values.id ?? 0,
                idUsuario: values.idUsuario ?? 0,
                nome: values.nome ?? "",
                email: values.email ?? "",
                telefone: values.telefone ?? "",
                descricao: values.descricao ?? "",
                qtdeMoedas: values.qtdeMoedas ?? 0,
                cep: values.cep ?? "",
                rua: values.rua ?? "",
                bairro: values.bairro ?? "",
                cidade: values.cidade ?? "",
                estado: values.estado ?? "",
                numero: values.numero ?? ""
            };

            try {
                await editarUsuEscolaAdm(request);
                toast.success("Dados alterados com sucesso!");
                handleClose();
                fetchData();
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        }
    };
        
    const formik = useFormik({
        initialValues,
        onSubmit
    });

    const { handleSubmit, handleChange, handleBlur, values, resetForm, setFieldValue } = formik;

    return (
        <Modal open={open} onClose={handleClose}>
            <Styled.Container>
                <Typography variant="h6" component="h2">
                    Editar perfil
                </Typography>
    
                <form onSubmit={handleSubmit}>
                    <Styled.GridDados>
                        <Grid >
                            <Styled.TextoInformacao>Nome completo</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="nome"
                                type="text"
                                id="nome"
                                value={values.nome || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>E-mail</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="email"
                                type="text"
                                id="email"
                                value={values.email || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>Saldo</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="qtdeMoedas"
                                type="text"
                                id="qtdeMoedas"
                                value={values.qtdeMoedas || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>Celular</Styled.TextoInformacao>
                            <Styled.InputTextGenericTelefone
                                mask={telefoneMaskFormatter(values.telefone ?? "")}
                                name="telefone"
                                type="text"
                                id="telefone"
                                value={values.telefone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <EnderecoForm formik={formik} />
                        <Styled.mtTop2 xs={12}>
                            <Grid container justifyContent="space-evenly">
                                <Styled.BotaoCancelar
                                    onClick={() => {
                                        handleClose();
                                        resetForm();
                                    }}>
                                    Cancelar
                                </Styled.BotaoCancelar>
                                <Styled.BotaoSalvar color="secondary" variant="normal" type="submit">
                                    Salvar alterações
                                </Styled.BotaoSalvar>
                            </Grid>
                        </Styled.mtTop2>
                    </Styled.GridDados>
                </form>
            </Styled.Container>
        </Modal>
    );
}
