import { Card, Checkbox, Grid, Tooltip } from "@mui/material"
import { Button } from "components/Button"
import { PageContainer } from "components/PageContainer"
import { useEffect, useState } from "react"
import { ModalAdicionarSecretaria } from "./ModalAdicionarSecretaria/ModalAdicionarSecretaria"
import { TituloPrincipal } from "components/Titulos/Titulos.styled"
import { toast } from "react-toastify"
import { obterEscolasGestao, obterEscolasSolo, obterQuantidadeRedacoesCorrigidasProfessor } from "api/controllers/admin"
import { EscolaGestaoDTO } from "api/models/Admin"
import { CardEscolas } from "./CardEscolas/CardEscolas"
import * as Styled from "./Dashboard.styled"
import { AreaChart, XAxis, YAxis, CartesianGrid, Area, ResponsiveContainer } from "recharts"
import { setEmitFlags } from "typescript"

export const Dashboard = () => {
    const [modalSecretaria, setModalSecretaria] = useState(false);

    const [tipoCard, setTipoCard] = useState<"secretaria" | "escola">("secretaria");

    const [escolasGestao, setEscolasGestao] = useState<Array<Array<EscolaGestaoDTO>>>();

    const [enviou, setEnviou] = useState(true);
    const [corrigiu, setCorrigiu] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await obterEscolasGestao();

                setEscolasGestao(data);
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            }
        })()
    }, [])

    const [escolasSolo, setEscolasSolo] = useState<Array<EscolaGestaoDTO>>();

    useEffect(() => {
        (async () => {
            try {
                const { data } = await obterEscolasSolo();

                setEscolasSolo(data);
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            }
        })()
    }, [])

    const [corrigidas, setCorrigidas] = useState<Array<{ mes: string, enviou: number, corrigiu: number }>>();

    useEffect(() => {
        (async () => {
            try {
                const { data } = await obterQuantidadeRedacoesCorrigidasProfessor(2023, 0);

                setCorrigidas(data);
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            }
        })()
    }, [])

    return (
        <PageContainer>
            <TituloPrincipal>B2B</TituloPrincipal>
            <Grid container>
                <Styled.GridEntre container>
                    <Grid xs={6} container>
                        <Styled.Opcao ativo={tipoCard === "secretaria"} onClick={() => setTipoCard("secretaria")}>Secretarias</Styled.Opcao>
                        <Styled.Opcao ativo={tipoCard === "escola"} onClick={() => setTipoCard("escola")}>Escolas solo</Styled.Opcao>
                    </Grid>
                    {tipoCard === "secretaria" && <Button onClick={() => setModalSecretaria(true)}>Adicionar secretaria</Button>}
                </Styled.GridEntre>
                <Styled.GridGestao xs={6}>
                    <h3>Correções de redações</h3>
                    <Grid container>
                        <ResponsiveContainer width="100%" height={250}>
                            <AreaChart data={corrigidas}
                                margin={{ top: 10, right: 25, left: -30, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="corrigu" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="30%" stopColor="#9747FF" stopOpacity={0.8} />
                                        <stop offset="100%" stopColor="#ffff" stopOpacity={0.2} />
                                    </linearGradient>
                                    <linearGradient id="enviou" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="0%" stopColor="#00E800" stopOpacity={0.8} />
                                        <stop offset="100%" stopColor="#00E800" stopOpacity={0.2} />
                                    </linearGradient>
                                </defs>
                                <XAxis interval={0} tickLine={false} tickFormatter={(e: string) => e.substring(0, 3).toUpperCase()} dataKey="mes" />
                                <YAxis tickCount={5} tickLine={false} />
                                <CartesianGrid />
                                <Area hide={!corrigiu} type="bump" dataKey="corrigiu" stroke="#9030DD" strokeWidth={1} fillOpacity={1} fill="url(#corrigu)" />
                                <Area hide={!enviou} type="bump" dataKey="enviou" stroke="#00E800" strokeWidth={1} fillOpacity={1} fill="url(#enviou)" />
                            </AreaChart>
                        </ResponsiveContainer>
                        <Styled.GridFinal container>
                            <Styled.GridCentro>
                                <Styled.CheckBoxLegenda1 checked={corrigiu} onChange={(e) => setCorrigiu(e.target.checked)} size="small" />
                                <Styled.Legenda>Corrigidas pelo professor</Styled.Legenda>
                            </Styled.GridCentro>
                            <Styled.GridCentro>
                                <Styled.CheckBoxLegenda2 checked={enviou} onChange={(e) => setEnviou(e.target.checked)} size="small" />
                                <Styled.Legenda>Enviadas para o professor</Styled.Legenda>
                            </Styled.GridCentro>
                        </Styled.GridFinal>
                    </Grid>
                </Styled.GridGestao>
                {tipoCard === "secretaria" && escolasGestao?.map(item => {
                    return (
                        <Styled.GridGestao container>
                            <Grid xs={12}>
                                <h3>{item[0].nomeGestao}</h3>
                            </Grid>
                            <Styled.GridScroll>
                                <Styled.GridItens>
                                    {item.map((escola, index) => <CardEscolas item={escola} index={index} />)}
                                </Styled.GridItens>
                            </Styled.GridScroll>
                        </Styled.GridGestao>
                    )
                })}
                {tipoCard === "escola" &&
                    <Styled.GridGestao container>
                        <Grid xs={12}>
                            <h3>Escolas solo</h3>
                        </Grid>
                        <Styled.GridScroll>
                            <Styled.GridItens>
                                {escolasSolo?.map((escola, index) => <CardEscolas item={escola} index={index} />)}
                            </Styled.GridItens>
                        </Styled.GridScroll>
                    </Styled.GridGestao>
                }
            </Grid>
            <ModalAdicionarSecretaria open={modalSecretaria} handleClose={() => setModalSecretaria(false)} />
        </PageContainer>
    )
}