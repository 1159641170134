import { Select, styled } from "@mui/material";
import { IoEye } from "react-icons/io5";
import { MdPayment } from "react-icons/md";

export const IconePagamento = styled(MdPayment)(({ theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    cursor: "pointer"
}));

export const IconeOlho = styled(IoEye)(({ theme }) => ({
    background: theme.palette.secondary.main,
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    color: "white",
    cursor: "pointer"
}));

export const SelectFiltro = styled(Select)(({ theme }) => ({
    borderRadius: theme.spacing(10),
    fontSize: 14,
    marginLeft: 20,
    width: theme.spacing(13)
}))