import { Box, Grid, styled } from "@mui/material";

export const BoxModal = styled(Box)(({ theme }) => ({
    background: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2)
}))

export const GridFormControl = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(0.5)
}));

export const InputCheck = styled("input")(({ theme }) => ({
    margin: 0,
    marginLeft: theme.spacing(0.5)
}));

export const GridCentro = styled(Grid)({
    display: "flex",
    alignItems: "center"
})