import { Grid } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid";
import { obterPagamentos } from "api/controllers/admin";
import { EnumStatusPlano } from "api/models/enum";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import { PageContainer } from "components/PageContainer"
import { TituloPrincipal } from "components/Titulos/Titulos.styled"
import { IconeOlho } from "pages/Orcamentos/Orcamentos.styled";
import { useEffect, useState } from "react";
import * as Styled from "./Faturamento.styled";
import { ModalBoleto } from "./ModalBoleto/ModalBoleto";

export const Faturamento = () => {
    const [pagamentos, setPagamentos] = useState<{ itens: any, config: any }>();

    const [idPagarme, setIdPagarme] = useState("");

    const [idGestao, setIdGestao] = useState(0);

    const [modalBoleto, setModalBoleto] = useState(false);

    const abrirModal = (idSubs: string, idGestaoModal: number) => {
        setIdPagarme(idSubs);
        setIdGestao(idGestaoModal);
        setModalBoleto(true);
    }

    const columns: GridColDef[] = [
        {
            field: "dataCadastro",
            headerName: "Data",
            valueGetter: (e) => new Date(e.value).toLocaleDateString("pt-BR"),
            flex: 1
        },
        {
            field: "nome",
            headerName: "Gestão",
            flex: 1
        },
        {
            field: "email",
            headerName: "E-mail",
            flex: 1
        },
        {
            field: "descricao",
            headerName: "Plano",
            flex: 1
        },
        {
            field: "idStatusPlano",
            headerName: "Status",
            renderCell: (param) => {
                return <Styled.Status status={param.value}>{EnumStatusPlano[param.value]}</Styled.Status>
            },
            flex: 1
        },
        {
            field: "id",
            headerName: "Ações",
            flex: 1,
            renderCell: (param) => param.row.descricao !== "Parceira" && <IconeOlho onClick={() => abrirModal(param.row.idPlanoPagarme, param.row.idGestao as number)} />
        }
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: pagamentos?.itens,
        columns,
        quantidadePaginas: pagamentos?.config.quantidadePaginas
    });

    useEffect(() => {
        (async () => {
            const resposta = await obterPagamentos({
                filtro: "",
                itensPorPagina: 10,
                ordem: ordem,
                paginaAtual: paginaAtual
            });

            setPagamentos(resposta);
        })()
    }, [paginaAtual, ordem])

    return (
        <PageContainer>
            <TituloPrincipal>Faturamento</TituloPrincipal>
            <Grid container mt={5}>
                {pagamentos &&
                    <>
                        {Componente()}
                    </>
                }
            </Grid>
            <ModalBoleto idPagarme={idPagarme} idGestao={idGestao} open={modalBoleto} handleClose={() => setModalBoleto(false)} />
        </PageContainer>
    )
}