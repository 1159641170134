import { Box, Grid, styled, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { IconType } from "react-icons";
import { createElement } from "react";
import { Button } from "components/Button";
import { MdOutlineDescription } from "react-icons/md";

export const BoxModal = styled(Box)(({ theme }) => ({
    background: "white",
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(2)
}))

export const GridFormControl = styled(Grid)(({ theme }) => ({
    padding: theme.spacing(0.5)
}));

export const GridCentro = styled(Grid)({
    display: "flex",
    alignItems: "center"
})

export const IconGenerico = ({ icon }: { icon: IconType | OverridableComponent<any> }) => createElement(icon, { style: {
    color: "black",
    fontSize: "1.5rem",
    marginRight: "0.725rem",
}});

export const Texto = styled("p")({
    display: "flex",
    alignItems: "center",
});

export const ArquivoGrid = styled(Grid)(({ theme }) => ({
    background: theme.palette.info.light,
    justifyContent: "left",
    alignItems: "center",
    padding: theme.spacing(0.3125),
    borderRadius: theme.spacing(0.625)
}));

export const Textos = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.875),
    color: theme.palette.primary.main,
    fontWeight: 500,
    padding: theme.spacing(0.3125),
    textAlign: "justify"
}));


export const NomeArquivo = styled(Textos)(({ theme }) => ({
    marginBottom: 0,
    textAlign: "left",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: theme.spacing(1),
}))

export const TextoTrocarArquivo = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    textDecoration: "underline",
    fontWeight: 600,
    fontSize: theme.spacing(0.875),
    cursor: "pointer"
}));

export const GridColumn = styled(Grid)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        width: "49%"
    }
}));

export const BotaoCarregar = styled(Button)({
    padding: 10,
    width: "100%"
});

export const IconeProposta = styled(MdOutlineDescription)(({ theme }) => ({
    cursor: "pointer",
    marginRight: theme.spacing(0.5),
    fontSize: theme.spacing(1.25)
}));

export const Descricao = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(1),
    color: theme.palette.grey[700],
    fontSize: theme.spacing(1),
    fontWeight: 500,
    textAlign: "start",
    display: "flex",
    alignItems: "center"
}));

export const IconeInformacao = styled(InfoIcon)(({ theme }) => ({
    cursor: "pointer",
    padding: theme.spacing(0.125),
    color: theme.palette.info.main
}));