import { Grid, Modal, Typography } from "@mui/material";
import { FormValues, ModalEditAlunosProps } from "./ModalEditTurma.types";
import * as Styled from "./ModalEdit.styles";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { editarUsuAluno, obterInfoAlunoUsario } from "api/controllers/admin";
import { AdmUsuarioEditDTO } from "api/models/Usuarios";
import { telefoneMaskFormatter } from "../../../util/mask-formatters";
import { EnderecoForm } from "components/EnderecoForm/EnderecoForm";
import { initialValues } from "components/EnderecoForm/EnderecoForm.types";

export function ModalEdit({ idUsu, open, handleClose, fetchData }: ModalEditAlunosProps) {

    const [alunosUsu, setAlunosUsu] = useState<AdmUsuarioEditDTO>();

    useEffect(() => {
        const fetchAlunosUsu = async () => {
            try {
                const alunos = await obterInfoAlunoUsario(idUsu);
                setAlunosUsu(alunos);
                resetForm({ values: alunos });
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        };

        if (open) {
            fetchAlunosUsu();
        }
    }, [idUsu, open]);

    const onSubmit = async (values: FormValues) => {
        if (values.id === alunosUsu?.id &&
            values.idUsuario === alunosUsu?.idUsuario &&
            values.nome === alunosUsu?.nome &&
            values.email === alunosUsu?.email &&
            values.telefone === alunosUsu?.telefone &&
            values.descricao === alunosUsu?.descricao &&
            values.qtdeMoedas === alunosUsu?.qtdeMoedas &&
            values.cep === alunosUsu?.cep &&
            values.rua === alunosUsu?.rua &&
            values.bairro === alunosUsu?.bairro &&
            values.cidade === alunosUsu?.cidade &&
            values.estado === alunosUsu?.estado &&
            values.numero === alunosUsu?.numero) {
            toast.error("Nenhum campo foi alterado!");
        } else {

            const request = {
                id: values.id ?? 0,
                idUsuario: values.idUsuario ?? 0,
                nome: values.nome ?? "",
                email: values.email ?? "",
                telefone: values.telefone ?? "",
                descricao: values.descricao ?? "",
                qtdeMoedas: values.qtdeMoedas ?? 0,
                cep: values.cep ?? "",
                rua: values.rua ?? "",
                bairro: values.bairro ?? "",
                cidade: values.cidade ?? "",
                estado: values.estado ?? "",
                numero: values.numero ?? ""
            };

            try {
                await editarUsuAluno(request);
                toast.success("Dados alterados com sucesso!");
                handleClose();
                fetchData();
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        }
    };

    
    
    const formik = useFormik({
        initialValues,
        onSubmit
    });
    
    const { handleSubmit, handleChange, handleBlur, values, resetForm } = formik;

    return (
        <Modal open={open} onClose={handleClose}>
            <Styled.Container>
                <Typography variant="h6" component="h2">
                    Editar perfil
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Styled.GridDados>
                        <Grid>
                            <Styled.TextoInformacao>Nome completo</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="nome"
                                type="text"
                                id="nome"
                                value={values.nome || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>E-mail</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="email"
                                type="text"
                                id="email"
                                value={values.email || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>Saldo</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="qtdeMoedas"
                                type="text"
                                id="qtdeMoedas"
                                value={values.qtdeMoedas || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>Celular</Styled.TextoInformacao>
                            <Styled.InputTextGenericTelefone
                                mask={telefoneMaskFormatter(values.telefone ?? "")}
                                name="telefone"
                                type="text"
                                id="telefone"
                                value={values.telefone}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <EnderecoForm formik={formik} />
                        <Styled.mtTop2 xs={12}>
                            <Grid container justifyContent="space-evenly">
                                <Styled.BotaoCancelar onClick={() => { handleClose(); resetForm() }}>
                                    Cancelar
                                </Styled.BotaoCancelar>
                                <Styled.BotaoSalvar type="submit">
                                    Salvar alterações
                                </Styled.BotaoSalvar>
                            </Grid>
                        </Styled.mtTop2>
                    </Styled.GridDados>
                </form>
            </Styled.Container>
        </Modal>
    );
}
