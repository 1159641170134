import { PageContainer } from "components/PageContainer";
import { Grid, MenuItem, Select, Tooltip } from "@mui/material";
import { Button } from "components/Button";
import { useEffect, useState } from "react";
import { TituloPrincipal } from "components/Titulos/Titulos.styled";
import { ModalAdicionarProposta } from "./ModalAdicionarProposta/ModalAdicionarProposta";
import { GridColDef } from "@mui/x-data-grid";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import { enviarProcessaPDF, inativarTextoMotivador, obterTodosTemasVestibular, obterTodosVestibulares } from "api/controllers/admin";
import { toast } from "react-toastify";
import * as Styled from "./PropostaRedacao.styled";
import React from "react";
import { BiLoaderCircle } from "react-icons/bi";
import { useDebounce } from "hooks/useDebounce";
import { Vestibular } from "api/models/vestibular";
import { IconeDelete, IconeEdit } from "components/Icons/Icons";
import { ModalTextoMotivador } from "./ModalTextoMotivador/ModalTextoMotivador";
import { ModalEditarProposta } from "./ModalEditarProposta/ModalEditarProposta";
import { ModalAlert } from "components/ModalAlert";

export const PropostaRedacao = () => {
    const [modalAdicionarProposta, setModalAdicionarProposta] = useState(false);
    const [modalEditarProposta, setModalEditarProposta] = useState(false);
    const [dadosEditarSelecionado, setDadosEditarSelecionado] = useState(null);

    const [modalTextoMotivador, setModalTextoMotivador] = useState(false);
    const [idTextoMotivador, setIdTextoMotivador] = useState(0);
    const [textoApoio, setTextoApoio] = useState("");

    const abrirModalTextoMotivador = (idTextoMotivador: number, textoApoio: string) => {
        setIdTextoMotivador(idTextoMotivador);
        setTextoApoio(textoApoio);
        setModalTextoMotivador(true);
    }

    const abrirModalEditarProposta = (dados: any) => {
        dados.id = Number(dados.id.split('-')[0])
        setDadosEditarSelecionado(dados)
        setModalEditarProposta(true)
    }

    const fecharModalEditarProposta = (dados: any) => {
        setDadosEditarSelecionado(null)
        setModalEditarProposta(false)
    }

    const [filtro, setFiltro] = useState("");
    const filtroDebounced = useDebounce(filtro);

    const [vestibulares, setVestibulares] = useState<Array<Vestibular>>();

    const [selectVestibular, setSelectVestibular] = useState(0);
    const [select, setSelectedValue] = useState(0);

    function handleChangeId(event: any) {
        setSelectedValue(event.target.value);
    }

    function handleChangeVestiubular(event: any) {
        setSelectVestibular(event.target.value);
    }

    const columns: GridColDef[] = [
        {
            field: "tema",
            headerName: "TEMA",
            description: "Tema",
            flex: 2,
            minWidth: 100,
            renderCell: (e) => (
                <React.Fragment>
                    <Tooltip title={e.value} enterTouchDelay={0}>
                        <Styled.DescricaoStatus disableRipple={true} >
                            <Styled.StatusTexto>{e.value.length > 25 ? `${e.value.slice(0, 25)}...` : e.value}</Styled.StatusTexto>
                        </Styled.DescricaoStatus >
                    </Tooltip>
                </React.Fragment>
            )
        },
        {
            field: "vestibular",
            headerName: "Vestibular",
            flex: 1
        },
        {
            field: "ativo",
            headerName: "Status",
            renderCell: (s) =>
                <Styled.Status status={!!s.value}>
                    {s.value ? "Ativo" : "Inativo"}
                </Styled.Status>,
            flex: 1
        },
        {
            field: "processado",
            headerName: "Processado",
            renderCell: (p) => {
                let texto = "Não";
                let status: "sim" | "nao" | "textoNaoExtraido" = "nao";
                if (p.value === true) {
                    if (p.row.textoExtraido !== null && p.row.textoExtraido !== undefined) {
                        texto = "Sim";
                        status = "sim";
                    } else {
                        texto = "Não extraido";
                        status = "textoNaoExtraido";
                    }
                }
                return (
                    <Styled.SimNao status={status}>
                        {texto}
                    </Styled.SimNao>
                );
            },

            flex: 1
        },
        {
            field: "validado",
            headerName: "Validado",
            renderCell: (v) => {
                let statusText;
                switch (v.value) {
                    case 1:
                        statusText = "Aprovado";
                        break;
                    case 2:
                        statusText = "Recusado";
                        break;
                    default:
                        statusText = "Aguardando";
                        break;
                }
                return (
                    <Styled.Validado status={v.value}>
                        {statusText}
                    </Styled.Validado>
                );
            },
            flex: 1
        },
        {
            field: "dataCadastro",
            headerName: "Cadastro",
            valueGetter: (e) => new Date(e.value).toLocaleDateString("pt-BR"),
            flex: 1
        },
        {
            field: "id",
            headerName: "Ações",
            flex: 1,
            renderCell: (e) => {
                return <>
                    {e.row.textoApoio ? (
                        <Tooltip title="Visualizar pdf texto motivador">
                            <span>
                                <Styled.IconeOlho onClick={() => abrirModalTextoMotivador(e.row.id, e.row.textoApoio)} />
                            </span>
                        </Tooltip>
                    ) : (
                        <Tooltip title="Não há texto motivador carregado">
                            <span>
                                <Styled.IconeOlho style={{ opacity: 0.5, pointerEvents: 'none' }} />
                            </span>
                        </Tooltip>
                    )}
                    &nbsp;
                    {e.row.id &&
                        <Tooltip title="Editar">
                            <span>
                                <IconeEdit onClick={() => abrirModalEditarProposta(e.row)} />
                            </span>
                        </Tooltip>
                    }
                    &nbsp;
                    {e.row.id &&
                        <Tooltip title="Excluir">
                            <span>
                                <IconeDelete onClick={() => { setModalExcluir(e.row); setIdInativarTextoMotivador(e.value.split('-')[0]) }} />
                            </span>
                        </Tooltip>
                    }
                    &nbsp;
                    {
                        ((!e.row.textoExtraido && !e.row.processado && e.row.validado === 1) || (!e.row.textoExtraido && e.row.processado && e.row.validado === 1))  ?
                            <Tooltip title="Processar novamente">
                                <span>
                                    <Styled.IconeReprocessar onClick={() => { setModalReprocessar(e.row); setIdTextoReprocessar(e.value.split('-')[0]) }} />
                                </span>
                            </Tooltip>
                            : null
                    }
                </>
            }
        }
    ];

    const [textos, setTextos] = useState<{ itens: any, config: any }>();
    const ajusteTextos = textos?.itens.map((item: { id: any; vestibularId: any; }) => ({
        ...item
        , id: `${item.id}-${item.vestibularId}`
    }))

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: ajusteTextos,
        columns,
        quantidadePaginas: textos?.config.quantidadePaginas
    });

    const [isRefetching, setIsRefetching] = useState(false);

    const buscar = async () => {
        try {
            setIsRefetching(true);
            const vest = await obterTodosVestibulares()
            setVestibulares(vest.data)

            const resposta = await obterTodosTemasVestibular({
                paginaAtual: paginaAtual,
                idDominioTema: select ?? 0,
                idVestibular: selectVestibular,
                filtro: filtroDebounced ? `Tema:${filtroDebounced}` : "",
                itensPorPagina: 5,
                ordem: ordem
            });

            setTextos(resposta);
            setIsRefetching(false);
        } catch (error: any) {
            toast.error(error.response.data.message);
        }
    }

    useEffect(() => {
        (async () => {
            await buscar();
        })()
    }, [paginaAtual, filtroDebounced, ordem, selectVestibular, select]);

    const [modalExcluir, setModalExcluir] = useState(false);
    const [excluir, setExcluir] = useState(false);
    const [idInativarTextoMotivador, setIdInativarTextoMotivador] = useState(0);

    useEffect(() => {
        const chamaInativar = async () => {
            try {
                await inativarTextoMotivador(idInativarTextoMotivador);
                setModalExcluir(false);
                toast.success("Texto motivador inativado com sucesso");
                await buscar()
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            } finally {
                setExcluir(false);
                setIdInativarTextoMotivador(0)
            }
        }

        if (excluir)
            chamaInativar();
    }, [excluir])

    const [modalReprocessar, setModalReprocessar] = useState(false);
    const [reprocessar, setReprocessar] = useState(false);
    const [idTextoReprocessar, setIdTextoReprocessar] = useState(0);

    useEffect(() => {
        const chamaReprocessar = async () => {
            try {
                await enviarProcessaPDF(idTextoReprocessar);
                setModalReprocessar(false);
                toast.success("Texto motivador enviado para reprocessamento!");
                await buscar()
            } catch (erro: any) {
                toast.error(erro.response.data.message);
            } finally {
                setReprocessar(false);
                setIdTextoReprocessar(0)
            }
        }

        if (reprocessar)
            chamaReprocessar();
    }, [reprocessar])

    return (
        <>
            <PageContainer>
                <Grid container justifyContent="space-between" item={true}>
                    <TituloPrincipal>Proposta de redação</TituloPrincipal>
                    <Button onClick={() => setModalAdicionarProposta(true)}>Adicionar proposta</Button>
                </Grid>
                <br />
                <Styled.BoxAlinhamento>
                    <Grid container item xs={8} md={4}>
                        <Styled.GridResultados >
                            {isRefetching ? (
                                <Styled.AguardeLoading>Aguarde &nbsp; <BiLoaderCircle /></Styled.AguardeLoading>
                            ) : (
                                <>
                                    {textos && <Styled.SubTitulo>Mostrando {textos.itens.length + (textos.config.paginaAtual - 1) * textos.config.itensPorPagina} de {textos.config.quantidadeTotalItens} resultados</Styled.SubTitulo>}
                                </>
                            )}
                        </Styled.GridResultados>
                    </Grid>
                    <Grid container justifyContent="space-between" item={true}>
                        <Styled.GridSelect xs={12} md={12}>
                            <Select
                                name="vestibularId"
                                type="number"
                                style={{ width: "25%", fontSize: 14, borderRadius: "100px", height: "2.5rem", margin: 2 }}
                                onChange={handleChangeVestiubular}
                                value={selectVestibular}

                            >
                                <MenuItem value={0} selected>-- Busca por vestibular --</MenuItem>
                                {vestibulares !== null && vestibulares !== undefined && vestibulares.map(function (v) {
                                    return <MenuItem id="vestibularId" key={v.id} value={v.id}>{v.descricao}</MenuItem>
                                })}
                            </Select>
                            <Select
                                type="number"
                                style={{ width: "25%", fontSize: 14, borderRadius: "100px", height: "2.5rem", margin: 2 }}
                                onChange={handleChangeId}
                                value={select}
                            >
                                <MenuItem value={0} >-- Busca por área --</MenuItem>
                                <MenuItem value={7}> <Styled.Cultura /> Cultura</MenuItem>
                                <MenuItem value={8}> <Styled.Economia /> Economia</MenuItem>
                                <MenuItem value={9}> <Styled.Educacao /> Educação</MenuItem>
                                <MenuItem value={10}> <Styled.Lazer /> Lazer</MenuItem>
                                <MenuItem value={11}> <Styled.MeioAmbiente /> Meio Ambiente</MenuItem>
                                <MenuItem value={12}> <Styled.Politica /> Politica</MenuItem>
                                <MenuItem value={13}> <Styled.Preconceito /> Preconceito</MenuItem>
                                <MenuItem value={14}> <Styled.Religiao /> Religião</MenuItem>
                                <MenuItem value={15}> <Styled.Seguranca /> Segurança</MenuItem>
                                <MenuItem value={16}> <Styled.Social /> Social</MenuItem>
                                <MenuItem value={17}> <Styled.Tecnologia /> Tecnologia</MenuItem>
                                <MenuItem value={18}> <Styled.Trabalho /> Trabalho</MenuItem>
                                <MenuItem value={19}> <Styled.Violencia /> Violência</MenuItem>
                                <MenuItem value={20}> <Styled.Saude /> Saúde</MenuItem>
                                <MenuItem value={21}> <Styled.Outros /> Outros</MenuItem>
                            </Select>
                            <Styled.BotaoPesquisar
                                value={filtro}
                                setValue={setFiltro}
                                placeholder="Pesquisar por tema..."
                            />
                        </Styled.GridSelect>
                    </Grid>
                </Styled.BoxAlinhamento>
                <br />
                {!isRefetching && textos && Componente()}
            </PageContainer>
            <ModalAdicionarProposta handleClose={() => setModalAdicionarProposta(false)} open={modalAdicionarProposta} refetchPropostas={buscar} />
            <ModalEditarProposta handleClose={() => fecharModalEditarProposta(false)} open={modalEditarProposta} refetchPropostas={buscar} dadosEditar={dadosEditarSelecionado} />
            <ModalTextoMotivador open={modalTextoMotivador} handleClose={() => setModalTextoMotivador(false)} idTextoMotivador={idTextoMotivador} textoApoio={textoApoio} />
            <ModalAlert open={modalExcluir} tipo="excluir" texto="Deseja inativar este texto motivador?" textoBotao="Excluir" handleClose={() => setModalExcluir(false)} showButton setValue={setExcluir} />
            <ModalAlert open={modalReprocessar} tipo="info" texto="Deseja reprocessar este texto motivador?" textoBotao="Reprocessar" handleClose={() => setModalReprocessar(false)} showButton setValue={setReprocessar} />
        </>
    );
}