export interface LoginProps { 
}

export type FormValues = {
  username: string;
  password: string;
};

export const initialValues: FormValues = {
  username: "",
  password: "",
  };

