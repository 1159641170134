import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "./Button.types";

export const Button = styled(ButtonBase)<ButtonProps>(
    ({ theme, size = "md", variant = "normal", color = "primary" }) => ({
        fontWeight: 600,
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        lineHeight: theme.spacing(1.0625),
        borderRadius: theme.spacing(40),

        ...(variant === "normal" && {
            color: theme.palette[color].contrastText,
            backgroundColor: theme.palette[color].main,
            ":hover": {
                backgroundColor: theme.palette[color].dark,
            },
        }),

        ...(variant === "outline" && {
            border: `${theme.spacing(0.0625)} solid ${theme.palette[color].main}`,
            backgroundColor: "transparent",
            color: theme.palette[color].main,
            ":hover": {
                border: `${theme.spacing(0.0625)} solid ${theme.palette[color].light}`,
                color: theme.palette[color].light
            },
        }),

        ...(variant === "ghost" && {
            border: "none",
            backgroundColor: "transparent",
            color: theme.palette[color].main,
            borderRadius: 0,
            ":hover": {
                color: theme.palette[color].light,
            },
        }),

        ...(size === "sm" && {
            fontSize: 12,
            padding: theme.spacing(0.5),
        }),

        ...(size === "md" && {
            padding: theme.spacing(0.75),
            fontSize: 14,
        }),

        ...(size === "lg" && {
            padding: theme.spacing(1),
            fontSize: 16,
        }),
    })
);
