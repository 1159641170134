import { Layout } from "components/Layout";
import Routes from "route";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setIsLoggedIn, getUserCredentials, setToken } from "reducers/authentication";


export default function App() {
  const { token, refreshToken } = useSelector(getUserCredentials);
  const dispatch = useDispatch();

  useEffect(() => {
    if (token && refreshToken) {
      dispatch(setIsLoggedIn(true))
    }
  }, [token, refreshToken]);

  useEffect(() => {
    dispatch(setToken());
  }, []);

  return (
    <Layout>
      <Routes />
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        style={{ width: "400px" }}
        pauseOnHover
      />

    </Layout>
  );
}