import { Grid, FormHelperText, Avatar } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setHeaderEnable } from "reducers/layout";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Styled from  "./RegistrarAdmin.styled";
import { ValuesRegistrarAdmin } from "./RegistrarAdmin.types";
import { toast } from "react-toastify";
import { registrarNovoAdmin } from "api/controllers/auth";
import { RegistrarNovoAdminInterface } from "api/models/Admin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { AxiosError } from "axios";

  export function RegistrarAdmin() {
    const initialValues: ValuesRegistrarAdmin = {  
        username: "",
        password: "",
        email: ""}
    const navigate = useNavigate();
    const { handleSubmit, handleChange, handleBlur, errors, touched,  values, resetForm } = useFormik({
        initialValues,
        onSubmit
    
    });
    useEffect(() => {   
        resetForm()
    },[])

    async function onSubmit(values: RegistrarNovoAdminInterface): Promise<void> {
        try {
        const {data} = await registrarNovoAdmin({
            username:values.username,
            password:values.password,
            email:values.email
        })
        toast.success(data.message)
        resetForm()
        } catch (error){
            if ((error as AxiosError)?.response?.status == 409){
                toast.error('Conflito. Usuário ou Email já utilizados.')
                resetForm()
            }
            if ((error as AxiosError)?.response?.status == 401){
                toast.error('Erro ao autenticar administrador atual. Faça o login novamente')
                resetForm()
            }
            else{
                toast.error('Erro não esperado de status '+(error as AxiosError)?.response?.status)
            }
        }
            
        }
        

    
    return(
        <>
            
            <Styled.ItemContainer >
                    <form onSubmit={handleSubmit}>                           
                <Styled.AlinhamentoTextos > 
                    <Grid marginBottom="20%" width="20%">
                        <Styled.TypographyTitle>
                            <ArrowBackIcon onClick={() => navigate('/statusGerencial')} className="iconCursor"  style={{marginRight:'15px'}}/>
                            Registrar Novo Administrador
                            </Styled.TypographyTitle> 
                        </Grid>
                
                        
                <Grid container style={{ display: "flex", justifyContent: "center" }}>
                    <Grid xs={12} md={4} sm={12} >
                    <Grid container>
                        </Grid>
                        <Styled.FormControlGeneric>
                            <Styled.FormLabelGeneric htmlFor="username">Username</Styled.FormLabelGeneric>
                            <Styled.InputTextEmail
                                name="username"
                                type="text"
                                id="username"
                                placeholder="username"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                                error={touched.username && !!errors.username}
                                inputProps={{
                                    "aria-labelledby": "nome-label"
                                }}
                            />
                            {touched.username && !!errors.username && (
                                <FormHelperText id="nemailome-label" error={touched.username && !!errors.username}>
                                    {errors.username}
                                </FormHelperText>
                            )}
                            <Styled.FormLabelGeneric htmlFor="password">Password</Styled.FormLabelGeneric>
                            <Styled.InputTextEmail
                                name="password"
                                type="text"
                                id="password"
                                placeholder="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={touched.password && !!errors.password}
                                inputProps={{
                                    "aria-labelledby": "nome-label"
                                }}
                            />
                            {touched.password && !!errors.password && (
                                <FormHelperText id="nemailome-label" error={touched.password && !!errors.password}>
                                    {errors.password}
                                </FormHelperText>
                            )}
                            <Styled.FormLabelGeneric htmlFor="email">E-mail</Styled.FormLabelGeneric>
                            <Styled.InputTextEmail
                                name="email"
                                type="text"
                                id="email"
                                placeholder="exemplo@hotmail.com"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && !!errors.email}
                                inputProps={{
                                    "aria-labelledby": "nome-label"
                                }}
                            />
                            {touched.email && !!errors.email && (
                                <FormHelperText id="nemailome-label" error={touched.email && !!errors.email}>
                                    {errors.email}
                                </FormHelperText>
                            )}

                        </Styled.FormControlGeneric>
                    </Grid>

                    <Grid container style={{display: 'flex', justifyContent:'center', margin: 'auto'}}>
                        <Grid style={{display: 'grid'}}>
                            <Styled.BotaoContinuar type="submit"> Registrar <Styled.IconEdit /></Styled.BotaoContinuar>
                        </Grid>
                    </Grid>
                                    
                </Grid>
                </Styled.AlinhamentoTextos>
                </form>                
           </Styled.ItemContainer>
      
            
        </>
    )
}