import { Alert, FormControlLabel, Grid, MenuItem, Modal, Radio, RadioGroup, Select, Typography } from "@mui/material";
import { ModalPagamentoProps } from "./ModalPagamento.types";
import { BoxModal } from "pages/Dashboard/ModalAdicionarSecretaria/ModalAdicionarSecretaria.styled";
import { useEffect, useState } from "react";
import { gerarPagamentoGestao, obterOrcamentoCalculo } from "api/controllers/admin";
import { CloseOutlined } from "@mui/icons-material";
import { OrcamentoGestaoDTO } from "api/models/gestao";
import { options } from "../Orcamentos.type";
import * as Styled from "./ModalPagamento.styled";
import { InputMask } from "components/Inputs/InputMask";
import { Button } from "components/Button";
import { toast } from "react-toastify";

export const ModalPagamento = ({ handleClose, idOrcamento, open }: ModalPagamentoProps) => {
    const [orcamento, setOrcamento] = useState<OrcamentoGestaoDTO>();

    const [parcelas, setParcelas] = useState(0);

    const [documento, setDocumento] = useState("");

    const [tipoDocumento, setTipoDocumento] = useState<"CNPJ" | "CPF">("CNPJ");

    useEffect(() => {
        (async () => {
            if (open) {
                const { data } = await obterOrcamentoCalculo(idOrcamento);

                setOrcamento(data);
            }
        })()
    }, [idOrcamento, open]);

    useEffect(() => {
        if (orcamento) {
            setDocumento(orcamento.gestao.documento);
        }
    }, [orcamento])

    const menuItensParcelas = () => {
        if (orcamento) {
            const valorTotal = orcamento.orcamento.plano.valorTotal || 0;

            const menuItens = [
                <MenuItem value={1} key={1}>À vista de&nbsp;<b>R${valorTotal.toLocaleString("pt-BR", options)}</b></MenuItem>
            ];

            if (orcamento.orcamento.boletoRecorrente) {
                for (let i = 2; i <= orcamento.orcamento.duracao; i++) {
                    menuItens.push(
                        <MenuItem value={i} key={i}>{i}x de&nbsp;<b>R${(valorTotal / i).toLocaleString("pt-BR", options)}</b></MenuItem>
                    )
                }
            }

            return menuItens;
        }
    }

    const gerarPagamento = async () => {
        try {
            await gerarPagamentoGestao(idOrcamento, {
                documento,
                tipoDocumento,
                parcelas
            });

            toast.success("Pagamento gerado com sucesso!");
            handleClose();
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    return (
        <Modal open={open}>
            <BoxModal style={{ maxHeight: "80vh", width: "40vw" }}>
                <Grid container display="flex" justifyContent="space-between">
                    <h3>Gerar pagamento</h3>
                    <CloseOutlined onClick={handleClose} />
                </Grid>
                {orcamento && (!orcamento.gestao.parceira ?
                    <>
                        <Grid xs={12}>
                            CNPJ:
                            <InputMask
                                mask={tipoDocumento === "CNPJ" ? "99.999.999/9999-99" : "999.999.999-99"}
                                placeholder={`Ex.: ${tipoDocumento === "CNPJ" ? "99.999.999/9999-99" : "999.999.999-99"}`}
                                name="documento"
                                maskPlaceholder=""
                                type="text"
                                value={documento}
                                onChange={((e) => setDocumento(e.target.value))} />

                            <RadioGroup row value={tipoDocumento} onChange={(e) => { setTipoDocumento(e.target.value as "CNPJ" | "CPF"); setDocumento("") }}>
                                <FormControlLabel value="CNPJ" control={<Radio size="small" />} label="CNPJ" />
                                <FormControlLabel value="CPF" control={<Radio size="small" />} label="CPF" />
                            </RadioGroup>
                        </Grid>
                        <Grid xs={12} mt={1}>
                            Parcelas:
                            <Styled.SelectParcelas value={parcelas} onChange={(e) => setParcelas(e.target.value as number)}>
                                <MenuItem disabled value={0}>-- Selecione uma opção --</MenuItem>
                                {menuItensParcelas.call(this)}
                            </Styled.SelectParcelas>
                        </Grid>
                    </>
                    :
                    <Styled.Alerta severity="info">Gestão parceira</Styled.Alerta>
                )}

                <Button onClick={async () => await gerarPagamento()}>{orcamento?.gestao.parceira ? "Ativar plano" : "Gerar pagamento"}</Button>
            </BoxModal>
        </Modal>
    )
}