import { Autocomplete, FormHelperText, Grid, MenuItem, TextField, useMediaQuery } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { SelectEstadosCidadesProps } from "./SelectEstadosCidades.types";
import * as Styled from "./SelectEstadosCidades.styled";

const estadosIbge = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";

export function SelectEstadosCidades({ onEstadoChange, onCidadeChange, cidadeSelecionada, estadoSelecionado, ElementoDescricao, errorCidade = false, errorEstado = false, disabled = false, ...props }: SelectEstadosCidadesProps) {
  const [estados, setEstados] = useState<{ id: number, nome: string, sigla: string }[]>([]);
  const [cidades, setCidades] = useState<{ id: number, nome: string }[]>([]);
  const [searchCidade, setSearchCidade] = useState<string>('');


  useEffect(() => {
    axios.get(estadosIbge).then(response => setEstados(response.data));
  }, []);

  useEffect(() => {
    axios.get(`${estadosIbge}/${estadoSelecionado}/municipios`).then(response => setCidades(response.data));
  }, [estadoSelecionado]);

  return (
    <>
      <Grid {...props}>
        <ElementoDescricao error={errorEstado}>Estado</ElementoDescricao>

        <Styled.StyledAutocomplete
          options={estados}
          freeSolo
          getOptionLabel={(estado: any) => estado.nome}
          value={estados.find((estado) => estado.sigla === estadoSelecionado) || null}
          onChange={(_, newValue: any) => onEstadoChange(newValue ? newValue.sigla : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="-- Selecione o estado --"
            />
          )}
        />

        <Styled.StyledSelect value={estadoSelecionado} onChange={onEstadoChange} error={errorEstado} disabled={disabled}>
          <MenuItem value="">-- Selecione o estado --</MenuItem>
          {estados.sort((a, b) => a.nome.localeCompare(b.nome)).map((estado) =>
            <MenuItem key={estado.id} value={estado.sigla}>
              {estado.nome}
            </MenuItem>
          )}
        </Styled.StyledSelect>

        {errorEstado && <FormHelperText error>Obrigatório</FormHelperText>}
      </Grid>

      <Grid {...props}>
        <ElementoDescricao error={errorCidade}>Cidade</ElementoDescricao>

        <Styled.StyledAutocomplete
          options={cidades}
          freeSolo
          getOptionLabel={(cidade: any) => cidade.nome}
          value={cidades.find((cidade) => cidade.nome === cidadeSelecionada) || null}
          onChange={(_, newValue: any) => onCidadeChange(newValue ? newValue.nome : '')}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="-- Selecione a cidade --"
              onChange={(e) => setSearchCidade(e.target.value)}
            />
          )}
        />

        <Styled.StyledSelect value={cidadeSelecionada} onChange={onCidadeChange} error={errorCidade} disabled={disabled}>
          <MenuItem value="">-- Selecione a cidade --</MenuItem>
          {cidades.sort((a, b) => a.nome.localeCompare(b.nome)).map((cidade) =>
            <MenuItem key={cidade.id} value={cidade.nome}>
              {cidade.nome}
            </MenuItem>
          )}
        </Styled.StyledSelect>
        {errorCidade && <FormHelperText error>Obrigatório</FormHelperText>}
      </Grid>
    </>
  );
};