import { styled, Grid, Typography} from "@mui/material";

export const Status = styled("span")<{ status: "Ativo" | "Inativo" }>(({ theme, status }) => ({
    padding: theme.spacing(0.4, 0.8),
    borderRadius: theme.spacing(0.4),
    ...(status === "Ativo" && {
        background: theme.palette.success.light,
        color: theme.palette.success.dark
    }),
    ...(status === "Inativo" && {
        background: theme.palette.error.light,
        color: theme.palette.error.dark
    })
}));

export const GridBotoes = styled(Grid)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    width: 'fit-content',
    marginTop: theme.spacing(1),
 }));
 
 export const TextoTipos = styled(Typography)<{ active: boolean}>(({ theme, active }) => ({
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    fontWeight: 500,
    fontSize: theme.spacing(0.95),
    cursor: 'pointer',
    color: theme.palette.grey[500],
    display: 'flex',
    textAlign: 'initial',
    transition: 'border-bottom 0.3s ease',
    borderBottom: `solid ${theme.spacing(0.063)} transparent`,
 }), ({ active, theme }) => active && {
    borderBottom: `solid ${theme.spacing(0.188)} ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
 });

 export const Carregando = styled(Typography)(({theme}) => ({
    fontWeight: 500,
    marginRight: theme.spacing(1)
 }))