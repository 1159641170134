import { Autocomplete, Select } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({

  underline: {
    "&&&:before": {
      borderBottom: "none"
    },
    "&&:after": {
      borderBottom: "none"
    }
  },

  '&.Mui-selected': {
    outline: 'none',
    display: 'flex !important'
  },

  '& .MuiSelect-outlined': {
    display: 'flex !important'
  },

  "& .css-1hdgpyg-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: theme.spacing(1.875),
    width: theme.spacing(15),
    height: theme.spacing(2.5),
    backgroundColor: theme.palette.secondary.contrastText,
    justifyContent: "center",
    fontSize: theme.spacing(0.875),
    alignContent: "center"
  },

  [theme.breakpoints.up("md")]: {
    display: 'none'
  },
  [theme.breakpoints.down("md")]: {
    display: 'flex'
  }
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  display: 'none !important',
  width: theme.spacing(10),
  borderRadius: theme.spacing(1.25),
  
  [theme.breakpoints.up("md")]: {
    display: 'flex !important'
  }
}));

