import { RegistrarNovoAdminInterface } from "api/models/Admin";
import { Autenticacao, LoginInterface } from "api/models/login";
import {api} from "api/services/axios";
import { apiBackEnd } from "api/services/axiosApiCria";


export const execLogin = async(data:LoginInterface) => await api.post('auth/login',data)

export const ObterDadosDoUsuario = async () => {
    const { data } = await api.get('auth/user')
    return data
};

export const registrarNovoAdmin = async (data:RegistrarNovoAdminInterface) => {
    return await api.post('auth/register',data)
};

export const loginBackEnd = async (data: Autenticacao) => await apiBackEnd.post("token", data);