import { IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import { StyledInputPassword, StyledRiEyeFill, StyledRiEyeOffFill } from "./InputPassword.styles";
import { InputPasswordProps } from "./InputPassword.types";

export function InputPassword({ seePasswordOption = true, ...props }: InputPasswordProps) {
    const [isPasswordShowing, setIsPasswordShowing] = useState(false);

    const togglePasswordType = () => {
        setIsPasswordShowing(prev => !prev);
    }

    return (
        <StyledInputPassword
            {...props}
            type={isPasswordShowing ? "text" : "password"}
            endAdornment={seePasswordOption && (
                <InputAdornment position="end">
                    <IconButton onClick={togglePasswordType} onMouseDown={(e) => e.preventDefault()}>
                        {isPasswordShowing && <StyledRiEyeOffFill />}
                        {!isPasswordShowing && <StyledRiEyeFill />}
                    </IconButton>
                </InputAdornment>
            )}
        />
    )
}