import { styled } from "@mui/material";
import { EnumStatusPlano } from "api/models/enum";

export const Status = styled("span")<{ status: EnumStatusPlano }>(({ theme, status }) => ({
    padding: theme.spacing(0.4, 0.8),
    borderRadius: theme.spacing(0.4),
    ...(status === EnumStatusPlano.Ativo && {
        background: theme.palette.success.light,
        color: theme.palette.success.dark
    }),
    ...(status === EnumStatusPlano.Finalizado && {
        background: theme.palette.error.light,
        color: theme.palette.error.dark
    }),
    ...(status === EnumStatusPlano.Pendente && {
        background: theme.palette.warning.light,
        color: theme.palette.warning.main
    })
}));