import { Box, FormControl, FormHelperText, FormLabel, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Button } from "components/Button";
import { InputPassword, InputText } from "components/Inputs";
import { BsFacebook } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Logo } from "components/Logo";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { theme } from "styles/theme";

export const Container = styled(Grid)({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    Direction: "row",


});

export const GridCentralizacao = styled(Grid)({
    margin: "auto"
});

export const GridBotaoCadastro = styled(Grid)({
    margin: theme.spacing(1.25)
});

export const ContainerBotaoEntrar = styled(Grid)(({ theme }) => ({
    margin: theme.spacing(0.625),
}));

export const ImageContainer = styled(Grid)({
    display: "flex",
    justifyContent: "space-around",
    margin: 0

});

export const GridLogo = styled(Grid)({
    display: "flex",
    justifyContent: "center"

});

export const PermanecerConectado = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"

});


export const texto = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.625),
    fontSize: theme.spacing(0.875),

}));

export const textoLink = styled(Typography)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 600,
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.primary.main,


}));

export const FormControlGeneric = styled(FormControl)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(0.625),

}));

export const FormLabelGeneric = styled(FormLabel)(({ theme }) => ({
    fontWeight: 500,
    color: theme.palette.grey[500] 

}));
export const FormLabelGeneric2 = styled(FormLabel)(({ theme }) => ({
    fontWeight: 500,
    marginTop: theme.spacing(0.625),
    color: theme.palette.grey[500] 

}));

export const InputTextGeneric = styled(InputText)(({ theme }) => ({
    background: theme.palette.secondary.contrastText,
    border: `${theme.spacing(0.063)} solid ${theme.palette.grey[200]}`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(20.375),
    padding: theme.spacing(0.313),
    marginTop: theme.spacing(0.313),
    fontSize: theme.spacing(0.875),
    height: theme.spacing(2.375),


}));

export const InputPasswordGeneric = styled(InputPassword)(({ theme }) => ({
    background: theme.palette.secondary.contrastText,
    border: `${theme.spacing(0.063)} solid ${theme.palette.grey[200]}`,
    boxSizing: 'border-box',
    borderRadius: theme.spacing(20.375),
    padding: theme.spacing(0.313),
    marginTop: theme.spacing(0.313),
    height: theme.spacing(2.375),
    fontSize: theme.spacing(0.875),
    "svg ": {
        fill: theme.palette.secondary.main,
    },


}));

export const LinkResetarSenha = styled(Link)(({ theme }) => ({
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: theme.spacing(0.875),
    lineHeight: theme.spacing(0.938),
    color: theme.palette.primary.main,
    textAlign: 'right',
    "&:hover": {
        color: theme.palette.primary.light,
    },


}));

export const BotaoEnviar = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    marginTop: theme.spacing(0.625),
    marginBottom: theme.spacing(0.625),
    color: theme.palette.secondary.contrastText,
    height: theme.spacing(2.375),

    "&:hover": {
        backgroundColor: theme.palette.primary.light,
    },

}));

export const BotaoCadastro = styled(Button)(({ theme }) => ({
    backgroundColor: "transparent",
    width: "100%",
    marginTop: theme.spacing(0.625),
    marginBottom: theme.spacing(0.625),
    color: theme.palette.primary.main,
    border: `solid ${theme.spacing(0.063)}  ${theme.palette.primary.main}`,
    height: theme.spacing(2.375),

    "&:hover": {
        backgroundColor: "transparent",
        color: theme.palette.primary.light,
    },

}));

export const Label = styled(FormHelperText)(({ theme }) => ({
    marginLeft: 0

}));

export const BoxGoogleBotao = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: theme.spacing(0.625),

}));

export const ContainerFaceGogoole = styled(Grid)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

});

export const ParagrafoInformativo = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.938),
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

export const InformacaoCPF = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.688),
    marginTop: theme.spacing(0.313),
    marginBottom: theme.spacing(0.625),
    color: theme.palette.grey[600],
}));

export const FacebookIcone = styled(BsFacebook)(({ theme }) => ({
    color: "#3B5998",
    width: theme.spacing(3.438),
    height: theme.spacing(3.5),
    padding: theme.spacing(0.625),
    cursor: "pointer",


}));

export const TextoBtnFacebook = styled(Typography)(({ theme }) => ({
    color: "white",
    textAlign: "center",
    fontSize: theme.spacing(0.875),
    fontWeight: 500,

}));

export const CorFundo = styled(Grid)(({ theme }) => ({
    height: "100%",
    backgroundColor: theme.palette.secondary.light,
    display: "flex",

    [theme.breakpoints.down('lg')]: {
        display: "none"
    }
}));

export const Imagem = styled("img")(({ theme }) => ({
    position: "absolute",
    bottom: theme.spacing(0),
    left: theme.spacing(3.125),
    height: "80%",
    ['@media (min-width: 1200px) and (max-width: 1299px)']: {
        left: theme.spacing(-0.625),
    },

    
}));

export const LogoCria = styled(Logo)(({ theme }) => ({
    marginTop: theme.spacing(0.813),

}));

export const Voltar = styled(ArrowBackIcon)(({ theme }) => ({
    margin: theme.spacing(0.625),
    fontSize: theme.spacing(2.188),
    cursor: "pointer",
    color: theme.palette.grey[500] ,

}));

export const Message = styled(Grid)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    alignSelf: "auto",
    width: "65%",
    height: "80%",
    margin: "auto",
    paddingRight: theme.spacing(15.625),

    ['@media (min-width: 1200px) and (max-width: 1299px)']: {
        paddingLeft: theme.spacing(23.75),

    },

    ['@media (min-width: 1300px) and (max-width: 1399px)']: {
        paddingLeft: theme.spacing(28.125),
    },

    ['@media (min-width: 1400px) and (max-width: 1499px)']: {
        paddingLeft: theme.spacing(26.25),
    },
    ['@media (min-width: 1500px) and (max-width: 1599px)']: {
        paddingLeft: theme.spacing(21.875),
    },

    ['@media (min-width: 1600px)']: {
        paddingLeft: theme.spacing(21.875),
    },

    "img": {
        display: "flex",
        justifyContent: "end",
        margin: 0,
        paddingBottom: theme.spacing(15.625)
    }

}));

export const Texto = styled(Typography)(({ theme }) => ({
    color: "black",
    fontSize: theme.spacing(0.875)
}));


export const Botao = styled("button")(({ theme }) => ({
    width: "100%",
    display: "flex",
    marginTop: theme.spacing(0.3125),
    marginBottom: theme.spacing(0.3125),
    height: theme.spacing(2.625),
    borderRadius: theme.spacing(0.3125),
    background: theme.palette.secondary.light,
    color: theme.palette.secondary.contrastText,
    fontSize: theme.spacing(1.125),
    fontWeight: 300,
    paddingLeft: theme.spacing(0.5),
    justifyContent: "flex-start",
    alignItems: "center",
    "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
    },
    border: "none"
}));

export const Titulo = styled("h2")({
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
})