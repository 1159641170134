import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { DataGridComponentProps } from "./DataGrid.types";
import { Pagination } from "@mui/material";

export const DataGridComponent = ({ quantidadePaginas, data, columns }: DataGridComponentProps) => {
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [ordem, setOrdem] = useState("");

    const Paginacao = <Pagination count={quantidadePaginas} onChange={(e, page) => setPaginaAtual(page)} page={paginaAtual} />;

    function isSortModelValid(sortModel: GridSortModel) {
        const hasSortModel = !!sortModel && !!sortModel[0];
        const hasFieldSorted = sortModel[0]?.field;
        const hasSortDirection = sortModel[0]?.sort;

        return hasSortModel && hasFieldSorted && hasSortDirection;
    }

    const sortModel = useMemo(() => {
        if (!ordem)
            return [];

        const [fieldSplit, sortSplit] = ordem.split(":");

        const newSortModel: GridSortModel = [{
            field: fieldSplit,
            sort: sortSplit
                ? sortSplit.toLowerCase() === "asc" ? "asc" : "desc"
                : "asc"
        }];

        return newSortModel;
    }, [ordem]);

    const handleSortModelChange = (newModel: GridSortModel) => {
        if (!setOrdem) return;

        if (!isSortModelValid(newModel)) {
            setOrdem("");
            return;
        }

        setOrdem(`${newModel[0].field}:${newModel[0].sort}`);
    }

    return {
        Componente: () => (
            <DataGrid
                components={{ Pagination: () => Paginacao }}
                onSortModelChange={handleSortModelChange}
                sortModel={sortModel}
                rows={data}
                columns={columns}
            />
        ),
        ordem,
        paginaAtual
    };
};
