import { Grid, MenuItem } from "@mui/material"
import { Button } from "components/Button"
import { PageContainer } from "components/PageContainer"
import { TituloPrincipal } from "components/Titulos/Titulos.styled"
import { useEffect, useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { PlanoGestao } from "./PlanoEscola/PlanoGestao";
import { GridColDef } from "@mui/x-data-grid";
import { obterOrcamentos } from "api/controllers/admin";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import * as Styled from "./Orcamentos.styled";
import { ModalPagamento } from "./ModalPagamento/ModalPagamento";

export const Orcamentos = () => {
    const [periodo, setPeriodo] = useState(0);

    const [modalOrcamento, setModalOrcamento] = useState(false);

    const [orcamentos, setOrcamentos] = useState<{ itens: any, config: any }>();

    const [idOrcamento, setIdOrcamento] = useState(0);

    const [modalPagamento, setModalPagamento] = useState(false);

    const openModalPagamento = (id: number) => {
        setIdOrcamento(id);
        setModalPagamento(true);
    }

    const columns: GridColDef[] = [
        {
            field: "dataCadastro",
            headerName: "Data",
            valueGetter: (e) => new Date(e.value).toLocaleDateString("pt-BR"),
            flex: 1
        },
        {
            field: "nome",
            headerName: "Gestão",
            flex: 1
        },
        {
            field: "email",
            headerName: "E-mail",
            flex: 1
        },
        {
            field: "descricao",
            headerName: "Plano",
            flex: 1
        },
        {
            field: "id",
            headerName: "Ações",
            flex: 1,
            renderCell: (e) => {
                return <>
                    <Styled.IconePagamento onClick={() => openModalPagamento(e.value)} />
                    &nbsp;
                    <Styled.IconeOlho />
                </>
            }
        }
    ];
    
    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: orcamentos?.itens,
        columns,
        quantidadePaginas: orcamentos?.config.quantidadePaginas
    });

    useEffect(() => {
        (async () => {
            if (!modalOrcamento && !modalPagamento) {
                setIsLoading(true);
                const resposta = await obterOrcamentos({
                    filtro: "",
                    itensPorPagina: 10,
                    ordem: ordem,
                    paginaAtual: paginaAtual
                });
    
                setOrcamentos(resposta);
                setIsLoading(false);
            }
        })()
    }, [paginaAtual, ordem, modalOrcamento, modalPagamento])

    const [isLoading, setIsLoading] = useState(false);

    return (
        <PageContainer>
            <Grid container justifyContent="space-between">
                <TituloPrincipal>Orçamentos</TituloPrincipal>
                <Grid xs={6} container justifyContent="end">
                    <Button color="secondary" onClick={() => setModalOrcamento(true)}><IoMdAdd fontSize={20} /> &nbsp; Novo orçamento</Button>
                    <Styled.SelectFiltro value={periodo} onChange={(e) => setPeriodo(e.target.value as number)}>
                        <MenuItem value={0} disabled>-- Filtro por período --</MenuItem>
                        <MenuItem value={1}>Hoje</MenuItem>
                        <MenuItem value={7}>Últimos 7 dias</MenuItem>
                        <MenuItem value={15}>Últimos 15 dias</MenuItem>
                        <MenuItem value={30}>Últimos 30 dias</MenuItem>
                        <MenuItem value={60}>Últimos 60 dias</MenuItem>
                        <MenuItem value={90}>Últimos 90 dias</MenuItem>
                    </Styled.SelectFiltro>
                </Grid>
            </Grid>
            <Grid container>
                {!isLoading && orcamentos &&
                    <Grid container mt={5}>
                        {Componente()}
                    </Grid>
                }
            </Grid>
            <PlanoGestao open={modalOrcamento} handleClose={() => setModalOrcamento(false)} />
            <ModalPagamento open={modalPagamento} handleClose={() => setModalPagamento(false)} idOrcamento={idOrcamento} />
        </PageContainer>
    )
}