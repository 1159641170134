import { Box, Grid, Modal, Typography } from "@mui/material"
import { PageContainer } from "components/PageContainer"
import * as Styled from "./TelaMoedasB2C.styled";
import { useEffect, useState } from "react";
import { ObterDadosMoedasPorAlunoAdmin, obterGridTodosAlunosUsuarios, ObterUsuarioMoeda } from "api/controllers/admin";
import { toast } from "react-toastify";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import React from "react";
import { DataGridComponent } from "components/DataGrid/DataGrid";
import { useDebounce } from "hooks/useDebounce";
import { Usuario } from "api/models/Usuarios";
import { UsuarioMoeda } from "api/models/UsuarioMoeda";

export const MoedasB2C = () => {

    const [alunosUsu, setAlunosUsu] = useState<{ itens: any, config: any }>();

    const [isLoading, setIsLoading] = useState(false);

    const [filtro, setFiltro] = useState("");
    const filtroDebounced = useDebounce(filtro);

    const columns: GridColDef[] = [
        {
            field: 'nome',
            headerName: 'Aluno',
            description: 'Nome do Aluno',
            type: 'ellipsis',
            align: 'left',
            flex: 2.5,
        },
        {
            field: 'moedasRecebidas',
            headerName: 'Moedas recebidas',
            description: 'Quantidade de Moedas recebidas',
            type: 'ellipsis',
            align: 'left',
            flex: 2.5,
        },
        {
            field: 'moedasGastas',
            headerName: 'Moedas usadas',
            description: 'Quantidade de moedas gastas',
            type: 'ellipsis',
            align: 'left',
            flex: 2.5,
        },
        {
            field: 'qtdeMoedas',
            headerName: "Disponíveis",
            description: 'Quantidade de Moedas disponíveis',
            align: 'left',
            flex: 1.5,

        }
    ];

    const { Componente, ordem, paginaAtual } = DataGridComponent({
        data: alunosUsu?.itens,
        columns,
        quantidadePaginas: alunosUsu?.config.quantidadePaginas,
    });

    useEffect(() => {
        (async () => {
            const resposta = await ObterDadosMoedasPorAlunoAdmin({
                itensPorPagina: 5,
                ordem: ordem,
                paginaAtual: paginaAtual,
                filtro: filtroDebounced ? `Nome:${filtroDebounced}` : "",
                idEscola: 1
            });

            setAlunosUsu(resposta);
        })()
    }, [paginaAtual, ordem, filtroDebounced])

    return (
        <PageContainer>
            <Styled.TituloPrincipalStyled>Distribuição de moedas B2C</Styled.TituloPrincipalStyled>

            <Styled.BotaoPesquisar
                value={filtro}
                placeholder="Pesquisar por nome do aluno..."
                setValue={setFiltro}
            />

            <Grid container>
                {!isLoading && alunosUsu &&
                    <Styled.GridContainer item>
                        {Componente()}
                    </Styled.GridContainer>
                }
            </Grid>
        </PageContainer>
    )
}
