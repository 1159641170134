import { useFormik } from "formik";
import { initialValues, ModalAdicionarProps } from "./ModalAdicionar.types";
import * as Yup from "yup";
import * as Styled from "./ModalAdicionarVestibular.styled";
import { adicionarVestibular } from "api/controllers/admin";
import { toast } from "react-toastify";
import { Grid, Modal } from "@mui/material";
import { Button } from "components/Button";
import { CloseOutlined } from "@mui/icons-material";
import { InputText } from "components/Inputs";
import { Vestibular } from "api/models/vestibular";


const validationSchema = Yup.object({
    descricao: Yup.string().required("Insira um vestibular ou universidade!"),
})

export const ModalAdicionarVestbular = ({ open, handleClose, refetchVestibular }: ModalAdicionarProps) => {

    const { handleSubmit, values, errors, touched, handleChange, isSubmitting, resetForm } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    async function onSubmit(values: Vestibular) {
        try {     
            await adicionarVestibular(values);
            toast.success("Vestibular adicionado com sucesso!")
            handleClose();
            resetForm();
            refetchVestibular()
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    return (
        <Modal open={open}>
        <Styled.BoxModal>
            {!isSubmitting ?
                <>
                    <Grid container display="flex" justifyContent="space-between" item={true}>
                        <h3>Cadastrar vestibular ou universidade</h3>
                        <CloseOutlined onClick={() => { handleClose(); resetForm() }} />
                    </Grid>
                    <form onSubmit={handleSubmit}>
                        <Grid container item={true}>
                            <Styled.GridFormControl xs={12} md={12}>
                                Descrição:
                                <InputText
                                    placeholder="Ex.: Enem"
                                    name="descricao"
                                    type="text"
                                    value={values.descricao}
                                    onChange={handleChange}
                                    error={!!errors.descricao && touched.descricao} />
                            </Styled.GridFormControl>
                            <br />
                            <Grid xs={12} md={6} display="flex" alignItems="end" padding="0.5rem">
                                <Button style={{ width: "100%" }} color="primary" type="submit">Adicionar</Button>
                            </Grid>
                        </Grid>
                    </form>
                </> :
                "Adicionando..."
            }
        </Styled.BoxModal>
    </Modal>
    )

}