export type ModalAdicionarProps = {
    open: boolean;
    handleClose: () => void;
    refetchPropostas: () => Promise<void>;
}

export type FormValues = {
    idAssunto: number;
    tema: string;
    instrucoes: string;
    compartilhar?: boolean;
    vestibularId?: number;
}

export const initialValues: FormValues = {
    idAssunto: 0,
    tema: "",
    instrucoes: "",
    compartilhar: undefined,
    vestibularId: 0
}