import { Alert, Select, styled } from "@mui/material";

export const SelectParcelas = styled(Select)(({ theme }) => ({
    borderRadius: theme.spacing(10),
    fontSize: 14,
    width: "100%",
    height: theme.spacing(2.5),
    marginBottom: theme.spacing(1) 
}));

export const Alerta = styled(Alert)(
    ({ theme, severity = "info"}) => ({
        color: "black",
        background: theme.palette[severity].light,
        borderRadius: theme.spacing(0.5),
        marginBottom: theme.spacing(1),
        textAlign: "justify",
        "svg": {
            color: theme.palette[severity].main
        },
        fontSize: theme.spacing(0.75)
}));
