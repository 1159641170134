import { Grid, Typography } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import { SearchBar } from 'components/SearchBar';

export const Status = styled("span")<{ status: boolean }>(({ theme, status }) => ({
    padding: theme.spacing(0.4, 0.8),
    borderRadius: theme.spacing(0.4),
    ...(status && {
        background: theme.palette.success.light,
        color: theme.palette.success.dark
    }),
    ...(!status && {
        background: theme.palette.error.light,
        color: theme.palette.error.dark
    })
 }));

 export const BoxAlinhamento = styled("div")(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
       display: "flex",
    }
 }));

 export const GridResultados = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "center"
 }));
 
 const rotate = keyframes`
 from { transform: rotate(0deg);}
 to {transform: rotate(360deg);}`;
 
 export const AguardeLoading = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[600],
    fontWeight: 500,
    alignItems: "center",
    display: "flex",
 
    "svg": {
       animation: `${rotate} 2s linear infinite`,
       fontSize: theme.spacing(1.563)
    }
 }));

 export const GridSelect = styled(Grid)(({ theme }) => ({
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(0.313),
    [theme.breakpoints.up("md")]: {
       display: "flex",
    }
 }));
 
 export const BotaoPesquisar = styled(SearchBar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(3.125),
    padding: theme.spacing(0.5),
    marginTop: "1.5rem",
    width: "100%",
    height: "2.5rem", 
    "& svg": {
       color: theme.palette.secondary.main,
    },
 }));