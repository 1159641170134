import { Grid, Modal, Typography } from "@mui/material";
import { FormValues, ModalEditProfessorProps } from "./ModalEditProfessor.types";
import * as Styled from "../ModalEdit/ModalEdit.styles";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { editarUsuarioProfessorAdm, obterInfoProfessorUsario } from "api/controllers/admin";
import { AdmUsuarioEditDTO } from "api/models/Usuarios";
import { telefoneMaskFormatter } from "util/mask-formatters";
import { EnderecoForm } from "components/EnderecoForm/EnderecoForm";
import { initialValues } from "components/EnderecoForm/EnderecoForm.types";

export function ModalEditProfessor({ idUsu, open, handleClose, fetchData }: ModalEditProfessorProps) {
    const [professorUsu, setProfessorUsu] = useState<AdmUsuarioEditDTO>();

    useEffect(() => {
        const fetchInfoProfessor = async () => {
            try {
                const professor = await obterInfoProfessorUsario(idUsu);
                setProfessorUsu(professor);
                resetForm({ values: professor });
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        };

        if (open) {
            fetchInfoProfessor();
        }
    }, [idUsu, open]);

    const onSubmit = async (values: FormValues) => {
        if (values.idUsuario === professorUsu?.idUsuario &&
            values.nome === professorUsu?.nome &&
            values.cpf === professorUsu?.cpf &&
            values.telefone === professorUsu?.telefone &&
            values.cep === professorUsu?.cep &&
            values.rua === professorUsu?.rua &&
            values.bairro === professorUsu?.bairro &&
            values.cidade === professorUsu?.cidade &&
            values.estado === professorUsu?.estado &&
            values.numero === professorUsu?.numero) {
            toast.error("Nenhum campo foi alterado!");
        } else {
            const request = {
                idUsuario: values.idUsuario ?? 0,
                nome: values.nome ?? "",
                cpf: values.cpf ?? "",
                telefone: values.telefone ?? "",
                cep: values.cep?.trim() ?? "",
                rua: values.rua?.trim() ?? "",
                bairro: values.bairro?.trim() ?? "",
                cidade: values.cidade?.trim() ?? "",
                estado: values.estado?.trim() ?? "",
                numero: values.numero?.trim() ?? ""
            };

            try {
                await editarUsuarioProfessorAdm(request);
                toast.success("Dados alterados com sucesso!");
                handleClose();
                fetchData();
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        }
    }

    const formik = useFormik({
        initialValues,
        onSubmit
    });
    
    const { handleSubmit, handleChange, handleBlur, values, resetForm, setFieldValue } = formik;

    return (
        <Modal open={open} onClose={handleClose}>
            <Styled.Container>
                <Typography variant="h6" component="h2">
                    Editar perfil
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Styled.GridDados>
                        <Grid>
                            <Styled.TextoInformacao>Nome completo</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="nome"
                                type="text"
                                id="nome"
                                value={values.nome || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>CPF</Styled.TextoInformacao>
                            <Styled.InputTextGeneric
                                name="cpf"
                                type="text"
                                id="cpf"
                                value={values.cpf || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <Grid>
                            <Styled.TextoInformacao>Celular</Styled.TextoInformacao>
                            <Styled.InputTextGenericTelefone
                                mask={telefoneMaskFormatter(values.telefone ?? "")}
                                name="telefone"
                                type="text"
                                id="telefone"
                                value={values.telefone || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </Grid>
                        <EnderecoForm formik={formik} />
                        <Styled.mtTop2 xs={12}>
                            <Grid container justifyContent="space-evenly">
                                <Styled.BotaoCancelar
                                    onClick={() => {
                                        handleClose();
                                        resetForm();
                                    }}>
                                    Cancelar
                                </Styled.BotaoCancelar>
                                <Styled.BotaoSalvar color="secondary" variant="normal" type="submit">
                                    Salvar alterações
                                </Styled.BotaoSalvar>
                            </Grid>
                        </Styled.mtTop2>
                    </Styled.GridDados>
                </form>
            </Styled.Container>
        </Modal>
    );
}
