import { Collapse, Grid, ListItemIcon, ListItemText, styled } from "@mui/material";
import { SubMenuProps } from "./Sidebar.types";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";

export const SidebarGrid = styled(Grid)(({ theme }) => ({
    background: theme.palette.secondary.main,
    height: "100vh",
    padding: theme.spacing(1.25),
    paddingTop: theme.spacing(3.125)
}))

export const ItemLista = styled(Grid)<{ ativo: boolean }>(({ theme, ativo }) => ({
    color: "white",
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(0.4, 0.5),
    borderRadius: "10px",
    transition: "all ease 0.2s",
    marginBottom: theme.spacing(1),
    ...(ativo && {
        color: theme.palette.secondary.main,
        background: "white"
    }),
    ":hover": {
        color: theme.palette.secondary.main,
        background: "white"
    }
}));

export const Sair = styled(Grid)(({ theme }) => ({
    color: theme.palette.error.main,
    alignItems: "center",
    cursor: "pointer",
    padding: theme.spacing(0.4, 0.5),
}))

export const CollapseItens = styled(Collapse)(({ theme }) => ({
    paddingLeft: theme.spacing(1.5)
}))

export const GridLista = styled(Grid)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: `${theme.spacing(0.625)} ${theme.spacing(0.625)} ${theme.spacing(0.625)} ${theme.spacing(0.625)}`,
}));

export const Icones = styled(ListItemIcon)(({ theme }) => ({
    minWidth: "15%",
}));


export const ItemListaTexto = styled(ListItemText)<SubMenuProps>(({ theme, isActive }) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",

    "& .css-1tfeiaa-MuiTypography-root": {
        color: isActive ? theme.palette.grey[700] : theme.palette.grey[500],
        fontSize: theme.spacing(0.938),
        fontWeight: isActive ? 600 : 500,
        cursor: "pointer",
    },
    "&::before": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        height: theme.spacing(3.438),
        width: isActive ? theme.spacing(0.125) : 0,
        background: theme.palette.grey[700],
        right: theme.spacing(-0.688)
    },
}));

export const IconeAbrir = styled(MdKeyboardArrowUp)(({ theme }) => ({
    cursor: "pointer"
}));

export const IconeFechar = styled(MdKeyboardArrowDown)(({ theme }) => ({
    cursor: "pointer"
}));

