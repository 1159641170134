import { UsuarioEndereco } from "./../../api/models/Usuarios"
import { FormikContextType } from "formik"
import * as Yup from "yup"

export type EnderecoFormProps = {
    formik: FormikContextType<UsuarioEndereco>
}

export const initialValues: UsuarioEndereco = {
    cep: "",
    rua: "",
    complemento:"",
    cidade: "",
    estado: "",
    bairro: "",
    numero: "",
};