import { CredentialsResponse, UserCredentials } from "api/models/token";
import axios, { AxiosError } from "axios";
import { logout } from "reducers/authentication";

let isRefreshing = false;
let failedRequestsQueue: Array<{
    onSuccess: (token: string) => void;
    onFailure: (error: AxiosError) => void;
}> = [];

export const apiBackEnd = axios.create({
    baseURL: process.env.REACT_APP_CRIA_BACK_END_URL?.toString(),
    headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
});
  
apiBackEnd.interceptors.response.use(
    (response) => response,
    (error: AxiosError<any>) => {
        if (error.response?.status === 401) {
           
            if (error?.response?.data?.type !== "login.invalido") {
                const refreshToken = localStorage.getItem("refreshToken") || "";
                const jwtToken = localStorage.getItem("access_token") || "";

                const originalConfig = {...error.config!};
                if (!isRefreshing && refreshToken && jwtToken) {
                    isRefreshing = true;
                    apiBackEnd.post<UserCredentials>("/token/validar", {
                        jwtToken,
                        refreshToken,
                    })
                        .then(({ data }) => {
                            const {
                                token, refreshToken
                            } = data;

                            localStorage.setItem("refreshToken", refreshToken);
                            localStorage.setItem("access_token", token);

                            apiBackEnd.defaults.headers[
                                "Authorization"
                            ] = `Bearer ${token}`;

                            failedRequestsQueue.forEach((request) =>
                                request.onSuccess(token)
                            );
                            failedRequestsQueue = [];
                        })
                        .catch((err) => {
                            failedRequestsQueue.forEach((request) =>
                                request.onFailure(err)
                            );
                            failedRequestsQueue = [];
                            logout();
                        })
                        .finally(() => {
                            isRefreshing = false;
                        });
                }

                return new Promise((resolve, reject) => {
                    failedRequestsQueue.push({
                        onSuccess: (token: string) => {
                            originalConfig.headers[
                                "Authorization"
                            ] = `Bearer ${token}`;

                            resolve(apiBackEnd(originalConfig));
                        },
                        onFailure: (error: AxiosError) => {
                            reject(error);
                        },
                    });
                });
            }
        }

        return Promise.reject(error);
    }
);
