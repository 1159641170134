import { FormHelperText, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as Styled from './Login.styled';
import { FormValues, initialValues } from "./Login.types";
import Balao from "../../assets/Images/TelaLogin/Balao.svg";
import Professora from "../../assets/Images/NovasTelasCadastro/ProfessoraIcone.svg";
import { useFormik } from "formik";
import { execLogin, loginBackEnd } from "api/controllers/auth";
import { useDispatch } from "react-redux";
import { setUserCredentials } from "reducers/authentication";
import jwt_decode from "jwt-decode";
import { api } from "api/services/axios";
import { useState } from "react";
import { Button } from "components/Button";
import { ArrowBack } from "@mui/icons-material";

const validationSchema = Yup.object({
    username: Yup.string()
        .required("Obrigatório"),
    password: Yup.string()
        .required("Obrigatório")
});

export function Login() {

    const [tipoAdm, setTipoAdm] = useState<"AdminIa" | "AdminBackEnd">();

    const navigate = useNavigate();
    const { handleSubmit, values, errors, touched, handleChange, handleBlur, isSubmitting } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });
    const dispatch = useDispatch()

    async function onSubmit(values: FormValues): Promise<void> {
        if (tipoAdm === "AdminIa") {
            const { data } = await execLogin({
                username: values.username,
                password: values.password
            })

            var decodeToken: any = jwt_decode(data.access_token)

            dispatch(setUserCredentials(
                {
                    username: decodeToken.sub,
                    token: data.acess_token,
                    refreshToken: decodeToken.exp,
                    perfil: "AdminIa"
                }
            ))
            api.defaults.headers["Authorization"] = `Bearer ${data.access_token}`;
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('username', decodeToken.sub)
            localStorage.setItem('refreshToken', decodeToken.exp)
            navigate('/statusGerencial')
        }

        if (tipoAdm === "AdminBackEnd") {
            const { data } = await loginBackEnd({
                login: values.username,
                perfil: "Admin",
                senha: values.password
            })

            dispatch(setUserCredentials(
                {
                    username: data.nome,
                    token: data.token,
                    refreshToken: data.refreshToken,
                    perfil: "AdminBackEnd"
                }
            ))

            api.defaults.headers["Authorization"] = `Bearer ${data.token}`;
            localStorage.setItem('access_token', data.token)
            localStorage.setItem('username', data.nome)
            localStorage.setItem('refreshToken', data.refreshtoken)
        }

        localStorage.setItem("perfil", tipoAdm ?? "")
    };

    return (

        <Styled.Container container item xs={12}>
            <Styled.CorFundo item md={6} sm={12}>
                <Styled.ImageContainer container>
                    <Grid xs={4} sm={4} md={4} lg={1} xl={7}>
                        <Styled.Imagem src={Professora} />
                    </Grid>

                    <Styled.Message xs={5} sm={5} md={8} lg={8} xl={5}>
                        <img src={Balao}></img>
                    </Styled.Message>
                </Styled.ImageContainer>
            </Styled.CorFundo>

            <Styled.GridCentralizacao item xs={10} md={8} lg={3.5} sm={10}>
                {!tipoAdm &&
                    <Grid container>
                        <Styled.Botao onClick={() => setTipoAdm("AdminIa")}>Admin IA</Styled.Botao>
                        <Styled.Botao onClick={() => setTipoAdm("AdminBackEnd")}>Admin BackEnd</Styled.Botao>
                    </Grid>
                }
                {tipoAdm &&
                    <Grid container>
                        <Grid container>
                            <Styled.Titulo onClick={() => setTipoAdm(undefined)}><ArrowBack /> Voltar</Styled.Titulo>
                            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                                <Grid item xs={12} md={12}>
                                    <Styled.FormLabelGeneric error={!!errors.username && touched.username} htmlFor="username">Usuario</Styled.FormLabelGeneric>
                                    <Styled.InputTextGeneric
                                        name="username"
                                        type="text"
                                        id="username"
                                        placeholder="Digite seu nome de usuário"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.username}
                                        error={touched.username && !!errors.username}

                                        inputProps={{
                                            "aria-labelledby": "username-label"
                                        }}
                                    />
                                    {touched.username && !!errors.username && (
                                        <FormHelperText id="username-label" error={touched.username && !!errors.username}>
                                            {errors.username}
                                        </FormHelperText>
                                    )}

                                    <Styled.FormControlGeneric>
                                        <Styled.FormLabelGeneric2 error={!!errors.password && touched.password} htmlFor="password">Senha</Styled.FormLabelGeneric2>
                                        <Styled.InputPasswordGeneric
                                            name="password"
                                            id="password"
                                            placeholder="Digite sua senha"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                            error={touched.password && !!errors.password}
                                            inputProps={{
                                                "aria-labelledby": "password-label"
                                            }}
                                        />
                                        {touched.password && !!errors.password && (
                                            <Styled.Label id="password-label" error={touched.password && !!errors.password}>
                                                {errors.password}
                                            </Styled.Label>
                                        )}

                                    </Styled.FormControlGeneric>
                                </Grid>
                                <Styled.BotaoEnviar
                                    type="submit"
                                    disabled={isSubmitting}
                                    isLoading={isSubmitting}
                                    color="primary"> Entrar</Styled.BotaoEnviar>
                            </form>
                        </Grid>
                    </Grid>
                }
            </Styled.GridCentralizacao>
        </Styled.Container>

    );
};