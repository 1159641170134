import { Grid, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import * as Styled from "./B2CgraficoLine.styles";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, TooltipProps, Legend } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { toast } from 'react-toastify';
import { ObterInfoGraficoRedacaoSimpleseCompleta } from 'api/controllers/admin';
import { RedacoesFeitasDTO } from 'api/models/Admin';

const CustomTooltip = ({
    active,
    payload
}: TooltipProps<ValueType, NameType>) => {
    if (active && payload) {
        const day = payload[0].payload;
        return (
            <div
                key={day.name}
                style={{
                    padding: "6px",
                    backgroundColor: "white",
                    border: "1px solid grey"
                }}
            >
                <b>{day.name}</b>
                <div style={{ display: "flex" }}>
                    <div style={{ marginRight: "10px" }}>
                        <p style={{ color: "#2D9CDB" }}>Detalhadas: {day.detalhada}</p>
                        <p style={{ color: "#03db03" }}>Simples: {day.simples}</p>
                    </div>
                </div>
            </div>
        );
    }
    return null;
};

export const B2CgraficoLine = () => {
    const [obterInfoGraficoRedacaoSimpleseCompleta, setObterInfoGraficoRedacaoSimpleseCompleta] = useState<RedacoesFeitasDTO[]>([]);
    const [filtro, setfiltroOutros] = useState(365);

    useEffect(() => {
        const dadosGraficoOutros = async () => {
            try {
                const data = await ObterInfoGraficoRedacaoSimpleseCompleta();
                setObterInfoGraficoRedacaoSimpleseCompleta(data.data);
            } catch (error: any) {
                toast.error(error.response.data.message);
            }
        };
        dadosGraficoOutros();
    }, []);

    const data = obterInfoGraficoRedacaoSimpleseCompleta?.map(item => ({
        detalhada: item.detalhada,
        simples: item.simples,
        name: `${item.mes}/${item.ano}`,
    })); 

    return (
        <Styled.Container container xs={12} md={12} sm={12}>
            <Styled.TitleContentRedacao>Uso da plataforma</Styled.TitleContentRedacao>
            <>
                {obterInfoGraficoRedacaoSimpleseCompleta && obterInfoGraficoRedacaoSimpleseCompleta.length > 0 ? (
                    <>
                        <Styled.GridResponsivo width={400} height={300}>
                            <AreaChart data={data}
                                margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                                <defs>
                                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#9030DD" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#9030DD" stopOpacity={0} />
                                    </linearGradient>
                                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset="5%" stopColor="#00E800" stopOpacity={0.8} />
                                        <stop offset="95%" stopColor="#00E800" stopOpacity={0} />
                                    </linearGradient>
                                </defs>
                                <XAxis dataKey="name" />
                                <YAxis />
                                <CartesianGrid strokeDasharray="1 1" />
                                <Tooltip />
                                <Legend/>
                                <Area type="monotone" dataKey="detalhada" name="detalhada" stroke="#9030DD" fillOpacity={1} fill="url(#colorUv)" />
                                <Area type="monotone" dataKey="simples" name="simples" stroke="#00E800" fillOpacity={1} fill="url(#colorPv)" />
                            </AreaChart>
                        </Styled.GridResponsivo>
                    </>
                ) : (
                    <Styled.ContainerSemDados>
                        <p>Ainda não há dados no sistema.</p>
                    </Styled.ContainerSemDados>
                )}
            </>
        </Styled.Container>
    );
};
