import { MeusDados } from "pages/MeuDados";
import { RegistrarAdmin } from "pages/RegistrarAdmin";
import { MoedasB2C } from "pages/TelaMoedasB2C/TelaMoedasB2C";
import { Vocabularios } from "pages/Vocabularios/vocabulario";
import { StatusGerencial } from "pages/statusGerencial/statusGerencial";
import { Route, Routes } from "react-router-dom";


const AdminRoute: React.FC = () => {
    return (
        <Routes>
            <Route path="/statusGerencial" element={<StatusGerencial/>} />,
            <Route path="/meusDados" element={<MeusDados/>} />
            <Route path="/registrarAdmin" element={<RegistrarAdmin/>} />
            <Route path="/vocabularios" element={<MoedasB2C/>} />
        </Routes>
    );
};

export default AdminRoute;