import { Delete } from "@mui/icons-material";
import { styled } from "@mui/material";
import { MdAdd, MdEdit } from "react-icons/md";

export const IconeDelete = styled(Delete)(({ theme }) => ({
    background: theme.palette.error.main,
    color: "white",
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    cursor: "pointer"
}));

export const IconeAdd = styled(MdAdd)(({ theme }) => ({
    background: theme.palette.success.main,
    color: "white",
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    cursor: "pointer"
}));

export const IconeEdit = styled(MdEdit)(({ theme }) => ({
    background: theme.palette.secondary.main,
    color: "white",
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    cursor: "pointer"
}));