import { Checkbox, Grid, Typography, styled } from "@mui/material";

export const GridGestao = styled(Grid)(({ theme }) => ({
    background: "white",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1.5, 1),
    marginTop: theme.spacing(1.5)
}));

export const GridScroll = styled(Grid)(({ theme }) => ({
    overflowX: "scroll",
    marginTop: theme.spacing(1),
    "::-webkit-scrollbar": {
        height: theme.spacing(0.4),
        width: theme.spacing(0.5)
    },

    "::-webkit-scrollbar-track": {
        borderRadius: theme.spacing(0.2),
        backgroundColor: "transparent"
    },
    "::-webkit-scrollbar-thumb": {
        borderRadius: theme.spacing(0.4),
        backgroundColor: theme.palette.secondary.light
    }
}))

export const GridItens = styled(Grid)(({ theme }) => ({
    display: "flex",
    width: "max-content",
    paddingBottom: theme.spacing(0.5)
}))

export const Opcao = styled(Typography)<{ ativo: boolean }>(({ theme, ativo }) => ({
    color: theme.palette.grey[400],
    cursor: "pointer",
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(1),
    fontWeight: 600,
    textAlign: "center",
    paddingBottom: theme.spacing(1),
    ...(ativo && {
        textDecoration: `underline ${theme.palette.secondary.main} ${theme.spacing(0.15)}`,
        textUnderlineOffset: theme.spacing(0.5),
        color: theme.palette.secondary.main
    })
}));

export const GridEntre = styled(Grid)({
    justifyContent: "space-between",
    alignItems: "center"
});

export const GridFinal = styled(Grid)({
    justifyContent: "end"
});

export const GridCentro = styled(Grid)({
    display: "flex",
    alignItems: "center"
});

export const CheckBoxLegenda1 = styled(Checkbox)({
    color: "#9747FF",
    paddingRight: 0
});

export const CheckBoxLegenda2 = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.primary.main,
    paddingRight: 0
}))

export const Legenda = styled("p")(({ theme }) => ({
    fontSize: theme.spacing(0.65),
    color: theme.palette.grey[700]
}))