import { Box, Grid, Input, MenuItem, Modal, Select, Typography, inputBaseClasses } from '@mui/material';
import { styled } from '@mui/material/styles'; 
import { Button } from 'components/Button';
import { InputText } from 'components/Inputs';
import { InputMask } from 'components/Inputs/InputMask';
export const Descricao = styled("span")(({ theme }) => ({ 
   display: "flex",
   color: theme.palette.grey[500],
   fontSize: theme.spacing(1),
   fontWeight: 600,
   marginTop: theme.spacing(1.25),
 }));

 export const InputTurma = styled("input")(({ theme }) => ({ 
   borderRadius: theme.spacing(3.125),
   width: "100%",
   padding: theme.spacing(0.625),
   border: `${theme.spacing(0.063)} solid ${theme.palette.grey[200]}`,
   fontSize: theme.spacing(1),
   height: theme.spacing(2.5), 

}));

export const SelectTurma = styled(Select)(({ theme }) => ({ 
   borderRadius: theme.spacing(3.125),
   width: "100%",
   border: `${theme.spacing(0.063)} solid ${theme.palette.grey[200]}`,
   height: theme.spacing(2.5), 
   fontSize: theme.spacing(1),
}));

export const OptionTurma = styled(MenuItem)(({ theme }) => ({ 
   padding: theme.spacing(0.625),
      
}));

export const Container = styled(Grid)(({ theme }) => ({ 
   backgroundColor: theme.palette.common.white, 
   margin: "auto",
   padding: theme.spacing(3),
   width: "70%",
   borderRadius: theme.spacing(3.2), 
   maxWidth: theme.spacing(55),
}));

export const GridDados = styled(Grid)(({ theme }) => ({ 
   display:"flex",
   flexDirection:"row",
   flexWrap:"wrap",
   gap:"15px"
}));

export const BotaoCancelar = styled(Button)(({ theme }) => ({
   backgroundColor: "#ffff",
   fontWeight: 600,
   color: theme.status.pink.light,
   height: theme.spacing(2.188),
   margin: theme.spacing(0.60),
   border: `solid ${theme.spacing(0.063)} ${theme.status.pink.light}`,

   "&:hover": {
     color: theme.status.pink.light,
     border: `solid ${theme.spacing(0.063)} ${theme.status.pink.dark}`,
     backgroundColor:theme.status.pink.dark
   }
}))

export const BotaoSalvar = styled(Button)(({theme}) => ({
   backgroundColor: "#ffff",
   fontWeight: 600,
   color: theme.status.pink.light,
   height: theme.spacing(2.188),
   margin: theme.spacing(0.60),
   border: `solid ${theme.spacing(0.063)} ${theme.status.pink.light}`,
 
   "&:hover": {
     color: theme.status.pink.light,
     border: `solid ${theme.spacing(0.063)} ${theme.status.pink.dark}`,
     backgroundColor:theme.status.pink.dark
   }

}));

export const ModalUsu = styled(Modal)(({ theme }) => ({
   width: "100%",
   height: theme.spacing(100),
   padding: theme.spacing(15), 
   position: "fixed",
   overflowY: "scroll",
   inset: 0,
   display: "flex",
   alignItems: "center",
   justifyContent: "center",
   minHeight: "80vh",
   zIndex: 100,
   backgroundColor: theme.palette.grey[800],
   opacity : 0.9,
   
}));

export const TextoInformacao = styled(Typography)(({ theme }) => ({
   color: theme.palette.secondary.dark,
   fontSize: theme.spacing(1),
   padding: theme.spacing(0.775),
}));



export const InputTextGeneric = styled(InputText)(({ theme }) => ({
   border:"none",
   padding: theme.spacing(0.315),
   height: theme.spacing(2.5),
   width: theme.spacing(15),
   color: theme.palette.grey[900],
   fontSize: theme.spacing(0.875),
   borderBottom: `2px solid #9030DD`,
   "&::-webkit-calendar-picker-indicator": {
       filter: 'brightness(0) saturate(100%) invert(41%) sepia(89%) saturate(1039%) hue-rotate(174deg) brightness(98%) contrast(80%)',
   },
   "&:focus": {
       outline: "none",
       borderBottom: `2px solid #9030DD`,
   },
}));

export const InputTextGenericTelefone = styled(InputMask)(({ theme }) => ({
   border:"none",
   padding: theme.spacing(0.315),
   height: theme.spacing(2.5),
   width: theme.spacing(15),
   color: theme.palette.grey[900],
   fontSize: theme.spacing(0.875),
   borderBottom: `2px solid #9030DD`,
   "&::-webkit-calendar-picker-indicator": {
       filter: 'brightness(0) saturate(100%) invert(41%) sepia(89%) saturate(1039%) hue-rotate(174deg) brightness(98%) contrast(80%)',
   },
   "&:focus": {
       outline: "none",
       borderBottom: `2px solid #9030DD`,
   },
}));

export const mtTop2 = styled(Grid)(({ theme }) => ({
   marginTop: theme.spacing(1.25)
}))
