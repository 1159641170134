export enum EnumStatusPlano {
    "Ativo" = 1,
    "Pendente" = 2,
    "Finalizado" = 3
}

export enum EnumTipoDesconto {
    "Moeda" = 1,
    "Preço" = 2
}

export enum EnumTipoCompra {
    "Plano" = 1,
    "Pacote" = 2,
    "Plano Escola" = 3,
    "Plano Gestão" = 4
}

export enum EnumPerfilUsuario {
    "Aluno" = 1,
    "Escola" = 2,
    "Professor" = 3,
    "Gestão" = 4,
    "Admin" = 5
}