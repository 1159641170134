import { FormValues, initialValues, ModalEditarProps } from "./ModalEditarProposta.types";
import * as Styled from "./ModalEditarProposta.styled"
import { FormControlLabel, FormHelperText, Grid, MenuItem, Modal, Radio, RadioGroup, Select } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { arquivoAmazon, atualizarTextoMotivador, atualizarVinculoTextoMotivadorVestibular, enviarProcessaPDF, obterTodosVestibulares } from "api/controllers/admin";
import { TextoMotivadorDTO } from "api/models/textoMotivador";
import { InputText } from "components/Inputs";
import { Button } from "components/Button";
import { GiPunchBlast } from "react-icons/gi"
import { GrTechnology } from "react-icons/gr"
import PeopleIcon from '@mui/icons-material/People'
import PaidIcon from '@mui/icons-material/Paid'
import ShieldIcon from '@mui/icons-material/Shield'
import NatureIcon from '@mui/icons-material/Nature'
import WorkIcon from '@mui/icons-material/Work'
import GavelIcon from '@mui/icons-material/Gavel'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import SportsHandballIcon from '@mui/icons-material/SportsHandball'
import { FaCross, FaBookReader, FaTheaterMasks } from 'react-icons/fa'
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied'
import MedicalServicesIcon from '@mui/icons-material/MedicalServices'
import { Vestibular } from "api/models/vestibular";
import { ModalTextoMotivador } from "../ModalTextoMotivador/ModalTextoMotivador";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';


const validationSchema = Yup.object({
    idAssunto: Yup.number().test("is-0", "Selecione uma opção!", (value) => Promise.resolve(value !== 0)),
    tema: Yup.string().required("Insira um tema!"),
    disponivel: Yup.boolean().oneOf([true, false], "Selecione uma opção!")
});

export const ModalEditarProposta = ({ open, handleClose, refetchPropostas, dadosEditar }: ModalEditarProps) => {

    const { handleSubmit, values, errors, touched, handleChange, handleBlur, setFieldValue, isSubmitting, resetForm } = useFormik({
        initialValues,
        onSubmit,
        validationSchema
    });

    const [documento, setDocumento] = useState(false);
    const [arquivo, setArquivo] = useState<File>();
    const [textoMotivador, setTextoMotivador] = useState<TextoMotivadorDTO>();
    const [novoPDF, setNovoPDF] = useState<boolean>();

    const converterParaKb = (bytes: number) => Math.trunc(bytes / 1024);
    const [vestibulares, setVestibulares] = useState<Array<Vestibular>>();

    const [modalTextoMotivador, setModalTextoMotivador] = useState(false);
    const [idTextoMotivador, setIdTextoMotivador] = useState(0);
    const [textoApoio, setTextoApoio] = useState("");

    const abrirModalTextoMotivador = (idTextoMotivador: number, textoApoio: string) => {
        setIdTextoMotivador(idTextoMotivador);
        setTextoApoio(textoApoio);
        setModalTextoMotivador(true);
    }

    const fecharModal = () => {
        handleClose();
        setArquivo(undefined);
        setNovoPDF(undefined);
        setTextoMotivador(undefined)
        setValues(null);
        resetForm();
    }

    const setValues = (data: any) => {
        if (data) {
            setFieldValue("idAssunto", data.idDominioTema)
            setFieldValue("tema", data.tema)
            setFieldValue("vestibularId", data.vestibularId)
            setFieldValue("disponivel", data.disponivel)

            setTextoMotivador(data)
        }
    }

    useEffect(() => {
        if (open && dadosEditar){
            setValues(dadosEditar)
        }
    }, [open, dadosEditar])

    async function EnviarArquivoAmazonS3(file: FormData, idTextoMotivador: number) {
        return await arquivoAmazon({ file, idTextoMotivador });
    }

    function validarEdicao(valorAntigo: any | undefined, valorAtual: any) {
        if (!valorAntigo) { return true; }
        if (!valorAtual) { return false; }
        let editado = true;
        const keys = Object.keys(valorAntigo);
        keys.forEach(item => {
            editado = editado && (valorAntigo[item] === valorAtual[item]);
        });

        return !editado;
    }

    async function onSubmit(values: FormValues) {
        if (!textoMotivador?.textoExtraido && (novoPDF && !arquivo || novoPDF === undefined)) {
            toast.error("Carregue um documento!")
            return;
        }
        try {
            let linkArquivo: string | undefined = undefined;
            let enviarPdf = false;

            if (arquivo && novoPDF) {
                const formData = new FormData();
                formData.append("file", arquivo);
                const { data } = await EnviarArquivoAmazonS3(formData, textoMotivador?.id ?? 0)
                linkArquivo = data;
                enviarPdf = true;
            }
            const antigo = {
                tema: textoMotivador?.tema,
                textoApoio: textoMotivador?.textoApoio,
                disponivel: textoMotivador?.disponivel,
                idDominioTema: textoMotivador?.idDominioTema,
                vestibularId: textoMotivador?.vestibularId,
            }

            if (typeof novoPDF === "boolean" || (novoPDF === undefined && validarEdicao(antigo, values))) {
                await atualizarTextoMotivador({
                    id: textoMotivador?.id,
                    idDominioTema: values.idAssunto ?? 0,
                    disponivel: values.disponivel,
                    tema: values.tema ?? "",
                    textoApoio: linkArquivo,
                    vestibularId: values.vestibularId
                });

                if (typeof novoPDF === "boolean" && enviarPdf) {
                    await enviarProcessaPDF(textoMotivador?.id ?? 0);
                }

            } else {
                toast.error("Nenhuma alteração feita!")
            }

            if ((values?.vestibularId !== textoMotivador?.vestibularId)) {
                await atualizarVinculoTextoMotivadorVestibular(textoMotivador?.id as number, textoMotivador?.vestibularId ?? 0, values.vestibularId ?? 0)
            }

            await refetchPropostas();
            resetFormulario();
            toast.success("Proposta editada com sucesso!");
        } catch (error: any) {
            toast.error(error.response.data.message)
        }
    }

    useEffect(() => {
        obterTodosVestibulares().then((response) => setVestibulares(response.data))
    }, [])

    useEffect(() => {
        if (arquivo) {
            setDocumento(false);
        }
    }, [arquivo]);

    const resetFormulario = () => {
        fecharModal();
    }

    return (
        <>
            <Modal open={open}>
                <Styled.BoxModal>
                    {!isSubmitting ?
                        <>
                            <Grid container display="flex" justifyContent="space-between" item={true}>
                                <h3>Cadastrar texto motivador</h3>
                                <CloseOutlined onClick={() => { handleClose(); fecharModal() }} />
                            </Grid>
                            <form onSubmit={handleSubmit}>
                                {!textoMotivador?.textoExtraido && <Styled.AlertPDF severity="warning">Verifique o PDF enviado e tente novamente</Styled.AlertPDF>}
                                <Grid container item={true}>
                                    <Styled.GridFormControl xs={12} md={6}>
                                        Tema:
                                        <InputText
                                            name="tema"
                                            type="text"
                                            id="tema"
                                            placeholder="Digite o tema da redação"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.tema}
                                            error={touched.tema && !!errors.tema}
                                        />
                                    </Styled.GridFormControl>

                                    <Styled.GridFormControl xs={12} md={6}>
                                        Do que se trata o tema?
                                        <Select
                                            name="idAssunto"
                                            type="number"
                                            id="idAssunto"
                                            value={values.idAssunto}
                                            onChange={handleChange}
                                            style={{ width: "100%", fontSize: 14, borderRadius: "100px", height: "2.5rem" }}
                                            error={!!errors.idAssunto && touched.idAssunto}
                                        >
                                            <MenuItem value={0}>-- Selecione --</MenuItem>
                                            <MenuItem value={7}> <Styled.IconGenerico icon={FaTheaterMasks} /> Cultura </MenuItem>
                                            <MenuItem value={8}> <Styled.IconGenerico icon={PaidIcon} /> Economia</MenuItem>
                                            <MenuItem value={9}> <Styled.IconGenerico icon={FaBookReader} /> Educação</MenuItem>
                                            <MenuItem value={10}> <Styled.IconGenerico icon={SportsHandballIcon} /> Lazer</MenuItem>
                                            <MenuItem value={11}> <Styled.IconGenerico icon={NatureIcon} /> Meio Ambiente</MenuItem>
                                            <MenuItem value={12}> <Styled.IconGenerico icon={GavelIcon} /> Politica</MenuItem>
                                            <MenuItem value={13}> <Styled.IconGenerico icon={SentimentVeryDissatisfiedIcon} /> Preconceito</MenuItem>
                                            <MenuItem value={14}> <Styled.IconGenerico icon={FaCross} /> Religião</MenuItem>
                                            <MenuItem value={15}> <Styled.IconGenerico icon={ShieldIcon} /> Segurança</MenuItem>
                                            <MenuItem value={16}> <Styled.IconGenerico icon={PeopleIcon} /> Social</MenuItem>
                                            <MenuItem value={17}> <Styled.IconGenerico icon={GrTechnology} /> Tecnologia</MenuItem>
                                            <MenuItem value={18}> <Styled.IconGenerico icon={WorkIcon} /> Trabalho</MenuItem>
                                            <MenuItem value={19}> <Styled.IconGenerico icon={GiPunchBlast} /> Violência</MenuItem>
                                            <MenuItem value={20}> <Styled.IconGenerico icon={MedicalServicesIcon} /> Saúde</MenuItem>
                                            <MenuItem value={21}> <Styled.IconGenerico icon={MoreHorizIcon} />  Outros</MenuItem>
                                        </Select>
                                    </Styled.GridFormControl>


                                    <Styled.GridFormControl xs={12} md={6}>
                                        Vestibular:
                                        <Select
                                            name="vestibularId"
                                            type="number"
                                            value={values.vestibularId}
                                            onChange={handleChange}
                                            style={{ width: "100%", fontSize: 14, borderRadius: "100px", height: "2.5rem" }}
                                        >
                                            <MenuItem value={0}>-- Selecione --</MenuItem>
                                            {vestibulares !== null && vestibulares !== undefined && vestibulares.map(function (v) {
                                                return <MenuItem id="vestibularId" key={v.id} value={v.id}>{v.descricao}</MenuItem>
                                            })}
                                        </Select>
                                    </Styled.GridFormControl>

                                    <Styled.GridFormControl xs={12} md={6}>
                                        <Grid mt="1rem" item={true}>
                                            {!textoMotivador?.textoExtraido &&
                                                <Grid container alignItems="end">
                                                    <Grid item={true}>
                                                        <Styled.Descricao>
                                                            O que você deseja fazer?
                                                        </Styled.Descricao>
                                                    </Grid>
                                                    <Grid>
                                                        <RadioGroup
                                                            name="radio-buttons-group"
                                                            value={novoPDF}
                                                        >
                                                            <Styled.GridRadio container>
                                                                <Styled.LabelRadio value={false} control={<Radio size="small" onClick={() => setNovoPDF(false)} />} label="" />
                                                                Usar o PDF já cadastrado. &nbsp;
                                                                {!novoPDF && <Styled.TextoPDF onClick={() => abrirModalTextoMotivador(textoMotivador?.id as number, textoMotivador?.textoApoio as string)} >Verificar PDF &nbsp;<Styled.IconeOlho /></Styled.TextoPDF>}
                                                            </Styled.GridRadio>
                                                            <Styled.GridRadio container>
                                                                <Styled.LabelRadio value={true} control={<Radio size="small" onClick={() => setNovoPDF(true)} />} label="" /> Cadastrar outro documento
                                                            </Styled.GridRadio>
                                                        </RadioGroup>
                                                    </Grid>

                                                    {novoPDF &&
                                                        <>
                                                            {arquivo &&
                                                                <Styled.ArquivoGrid xs={12} container>
                                                                    <Grid xs={1} item={true}>
                                                                        <InsertDriveFileIcon color="disabled" fontSize="medium" />
                                                                    </Grid>
                                                                    <Grid xs={8} item={true}>
                                                                        <Styled.NomeArquivo>
                                                                            {arquivo.name}
                                                                        </Styled.NomeArquivo>
                                                                        <Styled.Textos>
                                                                            {converterParaKb(arquivo.size)}KB
                                                                        </Styled.Textos>
                                                                    </Grid>
                                                                    <Grid xs={3} item={true}>
                                                                        <Styled.TextoTrocarArquivo onClick={() => document.getElementById("carregarDocumento")?.click()}>Trocar</Styled.TextoTrocarArquivo>
                                                                    </Grid>
                                                                </Styled.ArquivoGrid >
                                                            }
                                                            {!arquivo &&
                                                                <>
                                                                    <Grid item={true}>
                                                                        <Styled.BotaoCarregar color="secondary" variant="outline" size="sm" onClick={() => document.getElementById("carregarDocumento")?.click()}>
                                                                            <Styled.IconeProposta /> Carregar documento
                                                                        </Styled.BotaoCarregar>
                                                                    </Grid>
                                                                    {documento &&
                                                                        <Grid xs={12} item={true}>
                                                                            <FormHelperText error>
                                                                                Nenhum arquivo selecionado!
                                                                            </FormHelperText>
                                                                        </Grid>
                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    <input onChange={(e: any) => { setArquivo(e.target.files[0]) }} type="file" hidden id="carregarDocumento" accept="application/pdf" />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Styled.GridFormControl>

                                    <Styled.GridFormControl xs={12} md={6} item={true}>
                                        <Styled.GridCentro container item={true}>
                                            <Grid container item={true}>
                                                Você deseja compartilhar sua proposta?
                                                <RadioGroup
                                                    name="radio-buttons-group"
                                                    value={values.disponivel}
                                                >
                                                    <FormControlLabel value={true} control={<Radio size="small" onClick={() => { setFieldValue("disponivel", true) }} />} label="Sim" />
                                                    <FormControlLabel value={false} control={<Radio size="small" onClick={() => { setFieldValue("disponivel", false) }} />} label="Não" />
                                                </RadioGroup>
                                            </Grid>
                                        </Styled.GridCentro>
                                    </Styled.GridFormControl>

                                    <Grid xs={12} md={6} display="flex" alignItems="end" padding="0.5rem" item={true}>
                                        <Button style={{ width: "100%" }} color="primary" type="submit">Editar proposta</Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </> :
                        "Editando..."
                    }

                </Styled.BoxModal>
            </Modal>
            <ModalTextoMotivador open={modalTextoMotivador} handleClose={() => setModalTextoMotivador(false)} idTextoMotivador={idTextoMotivador} textoApoio={textoApoio} />
        </>
    )
}