import { Box, Grid, Button, Select, Typography, IconButton } from '@mui/material';
import { keyframes, styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import AddTaskIcon from '@mui/icons-material/AddTask';
import InfoIcon from '@mui/icons-material/Info';
import { FaBookReader, FaCross, FaTheaterMasks } from 'react-icons/fa';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import PaidIcon from '@mui/icons-material/Paid';
import SportsHandballIcon from '@mui/icons-material/SportsHandball';
import NatureIcon from '@mui/icons-material/Nature';
import GavelIcon from '@mui/icons-material/Gavel';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import ShieldIcon from '@mui/icons-material/Shield';
import PeopleIcon from '@mui/icons-material/People';
import { GrTechnology } from "react-icons/gr";
import { GiPunchBlast } from "react-icons/gi";
import WorkIcon from '@mui/icons-material/Work';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import { SearchBar } from 'components/SearchBar';
import { IoEye } from 'react-icons/io5';
import { RiRefreshLine } from 'react-icons/ri';

export const Titulo = styled('h1')(({ theme }) => ({
   color: theme.palette.secondary.dark,
   fontSize: theme.spacing(1.5),
   fonteWight: 600,
   marginBottom: theme.spacing(0.938),
   marginTop: theme.spacing(0.938)
}));

export const BoxAlinhamento = styled("div")(({ theme }) => ({
   width: "100%",
   [theme.breakpoints.up("md")]: {
      display: "flex",
   }
}));

export const GridSelect = styled(Grid)(({ theme }) => ({
   justifyContent: "center",
   alignItems: "center",
   margin: theme.spacing(0.313),
   [theme.breakpoints.up("md")]: {
      display: "flex",
   }
}));

export const BotaoPesquisar = styled(SearchBar)(({ theme }) => ({
   backgroundColor: theme.palette.secondary.contrastText,
   borderRadius: theme.spacing(3.125),
   padding: theme.spacing(0.5),
   margin: '1rem',
   width: "100%",
   height: "2.5rem",
   "& svg": {
      color: theme.palette.secondary.main,
   },
}));

export const IconeInformacao = styled(InfoIcon)(({ theme }) => ({
   cursor: "pointer",
   marginTop: theme.spacing(1.25),
   color: theme.palette.info.main
}));

export const SelectProposta = styled(Select)(({ theme }) => ({
   borderRadius: theme.spacing(1.875),
   height: theme.spacing(2.5),
   display: 'flex ',
   backgroundColor: theme.palette.secondary.contrastText,
   alignItems: "center",
   justifyContent: "center",
   margin: theme.spacing(0.5),
   width: "50%"
}));

export const IconeAdicao = styled(AddIcon)(({ theme }) => ({
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.5)
}));

export const IconeCheck = styled(AddTaskIcon)(({ theme }) => ({
   fontSize: theme.spacing(1.25),
   marginRight: theme.spacing(0.5)
}));


export const BotaoGrid = styled(Grid)(({ theme }) => ({
   height: theme.spacing(1.563),
   fontSize: theme.spacing(1),
   fontWeight: 600,
   cursor: "pointer",
   transition: "all ease 0.2s",
   margin: theme.spacing(0.625),
   "&.ativo": {
      borderBottom: `${theme.spacing(0.125)} solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main
   }
}));

export const BoxFlex = styled(Box)({
   display: "flex"
});

export const BotaoAddProposta = styled(Button)(({ theme }) => ({
   height: theme.spacing(2.813),
   marginTop: theme.spacing(0.5),
   borderRadius: theme.spacing(1.5),
   // backgroundColor: theme.status.pink.light,
   "&:hover": {
      //    backgroundColor: theme.status.pink.dark,

   }
}));

export const GridResultados = styled(Grid)(({ theme }) => ({
   display: "flex",
   alignItems: "center"
}));

export const GridFinal = styled(Grid)({
   display: "flex",
   alignItems: "center",
   justifyContent: "end"
});

const rotate = keyframes`
 from { transform: rotate(0deg);}
 to {transform: rotate(360deg);}`;

export const AguardeLoading = styled(Typography)(({ theme }) => ({
   color: theme.palette.grey[600],
   fontWeight: 500,
   alignItems: "center",
   display: "flex",

   "svg": {
      animation: `${rotate} 2s linear infinite`,
      fontSize: theme.spacing(1.563)
   }
}));

export const SubTitulo = styled(Typography)(({ theme }) => ({
}));

export const Aguardando = styled(Typography)(({ theme }) => ({
   color: theme.palette.info.main,
   fontSize: theme.spacing(0.875),
}));

export const Processado = styled(Typography)(({ theme }) => ({
   color: theme.palette.success.main,
   fontSize: theme.spacing(0.875),
}));

export const Erro = styled(Typography)(({ theme }) => ({
   color: theme.palette.error.main,
   fontSize: theme.spacing(0.875),
}));

export const Texto = styled(Typography)(({ theme }) => ({
   fontSize: theme.spacing(0.875),
   whiteSpace: "nowrap",
   overflow: "hidden",
   textOverflow: "ellipsis",

}));

export const StatusTexto = styled(Typography)(({ theme }) => ({
   fontSize: theme.spacing(0.875),
   whiteSpace: "nowrap",
   overflow: "hidden",
   textOverflow: "ellipsis",

}));


export const DescricaoStatus = styled(IconButton)(({ theme }) => ({
   color: theme.palette.grey[700],
   outline: 'none',

}));

export const Cultura = styled(FaTheaterMasks)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Economia = styled(PaidIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Educacao = styled(FaBookReader)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Lazer = styled(SportsHandballIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const MeioAmbiente = styled(NatureIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Politica = styled(GavelIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Preconceito = styled(SentimentVeryDissatisfiedIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Religiao = styled(FaCross)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Seguranca = styled(ShieldIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Social = styled(PeopleIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Tecnologia = styled(GrTechnology)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Trabalho = styled(WorkIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Violencia = styled(GiPunchBlast)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Saude = styled(MedicalServicesIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const Outros = styled(MoreHorizIcon)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(1.375),
   marginRight: theme.spacing(0.625),
}));

export const TextoInformacao = styled(Typography)(({ theme }) => ({
   color: theme.palette.grey[900],
   fontSize: theme.spacing(0.9),
   fontWeight: 500,
   display: "flex",
   justifyContent: "center",
   padding: theme.spacing(0.3)
}));

export const Close = styled(Typography)(({ theme }) => ({
   cursor: 'pointer',
   fontSize: theme.spacing(0.9),
   fontWeight: 800,

}))

export const GridTexto = styled(Grid)(({ theme }) => ({
   backgroundColor: theme.palette.info.light,
   marginTop: theme.spacing(0.625),
   borderRadius: theme.spacing(0.5),
   padding: theme.spacing(0.625),
   justifyContent: 'space-between',
   display: 'flex'
}));

export const Status = styled("span")<{ status: boolean }>(({ theme, status }) => ({
   padding: theme.spacing(0.4, 0.8),
   borderRadius: theme.spacing(0.4),
   ...(status && {
      background: theme.palette.success.light,
      color: theme.palette.success.dark
   }),
   ...(!status && {
      background: theme.palette.error.light,
      color: theme.palette.error.dark
   })
}));

export const SimNao = styled("span")<{ status: "sim" | "nao" | "textoNaoExtraido" }>(({ theme, status }) => ({
   padding: theme.spacing(0.4, 0.8),
   borderRadius: theme.spacing(0.4),
   ...(status === "sim" && {
      background: theme.palette.success.light,
      color: theme.palette.success.dark
   }),
   ...(status === "nao" && {
      background: theme.palette.error.light,
      color: theme.palette.error.dark
   }),
   ...(status === "textoNaoExtraido" && {
      background: theme.palette.warning.light,
      color: theme.palette.warning.dark
   })
}));

export const Validado = styled("span")<{ status: number }>(({ theme, status }) => {
   let styles;
   switch (status) {
      case 1: // Aprovado
         styles = {
            background: theme.palette.success.light,
            color: theme.palette.success.dark
         };
         break;
      case 2: // Recusado
         styles = {
            background: theme.palette.error.light,
            color: theme.palette.error.dark
         };
         break;
      default: // Aguardando
         styles = {
            background: theme.palette.warning.light,
            color: theme.palette.warning.dark
         };
         break;
   }
   return {
      padding: theme.spacing(0.4, 0.8),
      borderRadius: theme.spacing(0.4),
      ...styles
   };
})


export const IconeEditar = styled(EditOutlinedIcon)<{ disabled?: boolean }>(({ theme, disabled = false }) => ({
   ...(disabled && {
      backgroundColor: theme.palette.grey[300],
      cursor: "default"
   })
}))

export const IconeOlho = styled(IoEye)(({ theme }) => ({
   background: theme.palette.secondary.main,
   borderRadius: 50,
   padding: 5,
   fontSize: 30,
   color: "white",
   cursor: "pointer"
}));

export const IconeReprocessar = styled(RiRefreshLine)(({ theme }) => ({
   backgroundColor: theme.palette.warning.main,
   color: theme.palette.secondary.contrastText,
   fontSize: theme.spacing(1.875),
   padding: theme.spacing(0.3),
   borderRadius: theme.spacing(1.5),
   cursor: "pointer",
}));
