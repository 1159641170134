import { Box, Grid, Modal } from "@mui/material";
import { ModalAlertProps } from "./ModalAlert.types";
import * as Styled from "./ModalAlert.styles";
import { IconeDelete } from "components/Icons/Icons";

export function ModalAlert({ showButton, texto, tipo, open, handleClose, setValue, textoBotao }: ModalAlertProps) {
    return (
        <Modal
            open={open}
        >
            <Styled.BoxModal>
                <Grid container>
                    <Grid xs={12} display="flex" alignItems="center" justifyContent="center">
                        {tipo === "excluir" && <IconeDelete />}
                        <Styled.ConteudoModalAlert>
                            {texto}
                        </Styled.ConteudoModalAlert>
                    </Grid>
                    <Styled.GridModalAlert container>
                        {showButton &&
                            <Grid container>
                                {tipo === "sucesso" &&
                                    <Grid xs={6} sm={4} md={6} display="flex">
                                        <Styled.Botao onClick={() => handleClose()} color="secondary" variant="contained">{textoBotao ?? "Fechar"}</Styled.Botao>
                                    </Grid>
                                }
                                {tipo === "excluir" && setValue &&
                                    <>
                                        <Grid xs={6} sm={4} md={6} display="flex">
                                            <Styled.Botao onClick={() => handleClose()} color="secondary" variant="outlined">Cancelar</Styled.Botao>
                                        </Grid>
                                        <Grid xs={6} sm={4} md={6} display="flex">
                                            <Styled.Botao color="error" onClick={() => { handleClose(); setValue(true) }} variant="contained">{textoBotao ?? "Exluir"}</Styled.Botao>
                                        </Grid>
                                    </>
                                }
                                {tipo === "info" &&
                                    <>
                                        <Grid xs={6} sm={4} md={6} display="flex">
                                            <Styled.Botao color="secondary" variant="outlined" onClick={() => { handleClose(); }} >Cancelar</Styled.Botao>
                                        </Grid>
                                        <Grid xs={6} sm={4} md={6} display="flex">
                                            <Styled.BotaoInfo color="secondary" variant="contained" onClick={() => { if (setValue) setValue(true) }} >{textoBotao ?? "Continuar"}</Styled.BotaoInfo>
                                        </Grid>
                                    </>
                                }
                            </Grid>
                        }
                    </Styled.GridModalAlert>
                </Grid>
            </Styled.BoxModal>
        </Modal>

    );

}
