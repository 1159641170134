import { Grid, Typography, styled } from "@mui/material";

const cores = ['rgba(131, 200, 239, 0.3)', 'rgba(43, 108, 74, 0.2)', 'rgba(242, 201, 76, 0.3)', 'rgba(235, 87, 87, 0.2)']

export const Card = styled("div")<{ index: number }>(({ theme, index }) => ({
    background: cores[index % cores.length],
    width: theme.spacing(20),
    marginRight: theme.spacing(1),
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1, 1.5)
}));

export const TituloCard = styled(Typography)(({ theme }) => ({
    color: theme.palette.secondary.main,
    fontSize: theme.spacing(1.25),
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
}));

export const Id = styled(Typography)(({ theme }) => ({
    color: theme.palette.grey[500],
    fontSize: theme.spacing(0.875),
    fontWeight: 400
}));

export const TituloQuantidade = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.875),
    color: theme.palette.grey[700],
    fontWeight: 400
}));

export const Quantidade = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(1.5),
    color: theme.palette.grey[700],
    fontWeight: 500
}));