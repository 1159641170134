export const documentoMaskFormatter = (value: string) => {
    if (!value || value.length === 0) return "999999999999999999";

    if (value.replace(/\D/g, "").length <= 11) {
        return "999.999.999-999999";
    } else {
        return "99.999.999/9999-99";
    }
};

export const validaTipoDocumento = (value: string) => {
    if (!value || value.length === 0) return "";

    if (value?.replace(/\D/g, "").length <= 11) {
        return "CPF";
    } else {
        return "CNPJ";
    }
}

export const telefoneMaskFormatter = (value: string) => {
    if (!value || value.length === 0) return "55 (99) 99999-9999";

    if (value.replace(/\D/g, "").length <= 12) {
        return "55 (99) 9999-99999";
    }

    return "55 (99) 99999-9999";
};
export const dataMaskFormatter = (value: string) => {
    if (!value || value.length === 0) return "9999999999999999999";

    if (value.replace(/\D/g, "").length <= 10) {
        return "99/99/9999";
    }

    return "99/99/9999";
};

export const documentoCpfMaskFormatter = (value: string) => {
    if (!value || value.length === 0) return "999999999999999999";

    if (value.replace(/\D/g, '').length <= 11) {
        return "999.999.999-99";
    } else {
        return "999.999.999-99";
    }
};

export const vencimentoCartaoMaskFormatter = (value: string) => {
    if (!value || value.length === 0) return "9999";

    return "99/99";
};

export const formatarCNPJ = (numero: string) => numero.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");

export const formatarCPF = (numero: string) => numero.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");