import { alpha, styled } from "@mui/material/styles";
import { Grid, Select, Typography } from "@mui/material";
import { ResponsiveContainer } from "recharts";

export const Container = styled(Grid)(({ theme }) => ({
    background: "white",
    padding: theme.spacing(0.4),
    border: `${theme.spacing(0.063)} solid #d1d1d180`,
    borderRadius: theme.spacing(0.625),
    boxShadow: `${alpha(theme.palette.grey[900], 0.2)} 0px ${theme.spacing(0.25)} ${theme.spacing(1)},${alpha(theme.palette.grey[900], 0.2)} 0px ${theme.spacing(0.25)} ${theme.spacing(1)}`,
    marginBottom: theme.spacing(3),
    width: "100%",
    overflow: "auto"
}));

export const TitleContentRedacao = styled(Typography)(({ theme }) => ({
    color: "#13293D",
    fontSize: "20px",
    fontWeight: 600,
    flexBasis: "80%",
    padding: theme.spacing(1)
}));

export const ContainerSemDados = styled(Grid)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    "p": {
        fontSize: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    "img": {
        width: "6%",
    }
}));

export const Filtro = styled(Select)(({ theme }) => ({
    borderRadius: "50px",
    margin: "5px",
    height: "45px",
}));

export const GridResponsivo = styled(ResponsiveContainer)(({ theme }) => ({
    [theme.breakpoints.down("lg")]: {
        minWidth: 600
    },
    "& svg": {
        overflow: "visible",
    },
}));

export const GridSelect = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("xs")]: {
        width: "100%",
    },
    margin: theme.spacing(0.5)
}));

export const ContainerSelect = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        display: 'flex',
        justifyContent: "space-between"

    },
}));
