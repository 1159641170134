import { CardEscolaProps } from "./CardEscolas.types";
import * as Styled from "./CardEscolas.styled"
import { Grid, Typography } from "@mui/material";
import { MdOutlineEdit } from "react-icons/md";

export const CardEscolas = ({ item, index }: CardEscolaProps) => {
    return (
        <Styled.Card index={index}>
            <Grid container justifyContent="space-between" alignItems="center">
                <Styled.TituloCard>
                    {item.nomeEscola}
                </Styled.TituloCard>

                <MdOutlineEdit fontSize="1.25rem" cursor="pointer" />
            </Grid>
            <Grid>
                <Styled.Id>ID: {item.id}</Styled.Id>
            </Grid>
            <Grid container mt="1rem">
                <Grid xs={6}>
                    <Styled.TituloQuantidade>TURMAS</Styled.TituloQuantidade>
                    <Styled.Quantidade>{item.turmas}</Styled.Quantidade>
                </Grid>
                <Grid xs={6}>
                    <Styled.TituloQuantidade>ALUNOS</Styled.TituloQuantidade>
                    <Styled.Quantidade>{item.alunos}</Styled.Quantidade>
                </Grid>
            </Grid>
        </Styled.Card>
    )
}