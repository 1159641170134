import { Button, Select, styled } from "@mui/material";
import { SearchBar } from "components/SearchBar";
import { IoEye } from "react-icons/io5";
import { MdPayment } from "react-icons/md";
import { SubMenuProps } from "./Usuarios.types";

export const IconePagamento = styled(MdPayment)(({ theme }) => ({
    background: theme.palette.primary.main,
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    cursor: "pointer"
}));

export const IconeOlho = styled(IoEye)(({ theme }) => ({
    background: theme.palette.secondary.main,
    borderRadius: 50,
    padding: 5,
    fontSize: 30,
    color: "white",
    cursor: "pointer"
}));

export const SelectFiltro = styled(Select)(({ theme }) => ({
    borderRadius: theme.spacing(10),
    fontSize: 14,
    marginLeft: 20,
    width: theme.spacing(13),

}))

export const ButtonUsuarios = styled(Button)<SubMenuProps>(({ theme, isActive }) => ({
    color: "#7D33D8",
    position: "relative",
    "& .MuiTypography-root": {
        color: isActive ?  "#7D33D8" : theme.palette.grey[500],
        fontSize: theme.spacing(0.938),
        fontWeight: isActive ? 600 : 500,
        cursor: "pointer",
    },
    "&::before": {
        content: '""',
        position: "absolute",
        top: "75%",
        transform: "translateY(-50%)",
        height: theme.spacing(0.118),
        width: isActive ? theme.spacing(4) : 0,
        background:  "#7D33D8",
    },
}));

export const BotaoPesquisar = styled(SearchBar)(({ theme }) => ({
    backgroundColor: theme.palette.secondary.contrastText,
    borderRadius: theme.spacing(3.125),
    padding: theme.spacing(0.313),
    width: theme.spacing(22.188),

    "& svg": {
        color: theme.palette.secondary.main,
    },

}));
