


import axios, { AxiosError } from "axios";

let isRefreshing = false;
let failedRequestsQueue: Array<{
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}> = [];

export const api = axios.create({
  baseURL: process.env.REACT_APP_IA_BACK_END_URL?.toString(),
  headers: {
    Authorization:`Bearer ${localStorage.getItem("access_token")}` ,
  },
});

export async function fetchApi<T>(path: string): Promise<T> {
    const response = await api.get<T>(path);
    return response.data;
}

api.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.response?.status === 401) {
           
            if (error.response.data !== "Unauthorized") {
                const jwtToken = localStorage.getItem("access_token");
                console.log(jwtToken)
                redirectToLogin();
            }}
            
        return Promise.reject(error);
    }
);

function redirectToLogin() {
  localStorage.removeItem("access_token");
  window.location.href = '/'; 
}