import { Grid, MenuItem, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import * as Styled from "./PlanoGestao.styled";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { InputText } from "components/Inputs";
import { useDispatch } from "react-redux";
import { Gestao, PlanoCalculadoDTO, PlanoUsuarioGestao } from "api/models/gestao";
import { calcularPlanos, gerarOrcamento, obterListaGestao } from "api/controllers/admin";
import { BoxModal } from "pages/Dashboard/ModalAdicionarSecretaria/ModalAdicionarSecretaria.styled";
import { Button } from "components/Button";
import { CloseOutlined } from "@mui/icons-material";
import { ModalProps } from "pages/Dashboard/ModalAdicionarSecretaria/ModalAdicionarSecretaria.types";
import { toast } from "react-toastify";
import { options } from "../Orcamentos.type";

export const PlanoGestao = ({ open, handleClose }: ModalProps) => {
    const [ensinoFundamental, setEnsinoFundamental] = useState(false);
    const [ensinoMedio, setEnsinoMedio] = useState(false);
    const [cursinho, setCursinho] = useState(false);

    const [planosCalculados, setPlanosCalculados] = useState<Array<PlanoCalculadoDTO>>();

    const [plano, setPlano] = useState<PlanoUsuarioGestao>();

    const [idGestao, setIdGestao] = useState(0);

    const [listaGestao, setListaGestao] = useState<Array<Gestao>>();

    useEffect(() => {
        (async () => {
            const { data } = await obterListaGestao();

            setListaGestao(data);
        })()
    }, [])

    const gestao = listaGestao?.find(x => x.id === idGestao);

    const [periodicidade, setPeriodicidade] = useState<1 | 3 | 6 | 12>(1);

    const containerCalculo = useRef<HTMLDivElement>(null);

    const fecharModal = () => {
        handleClose();
        setPlano(undefined);
        setPlanosCalculados(undefined);
        setIdGestao(0);
    }

    const escolherPlano = async (item: PlanoUsuarioGestao) => {
        try {
            item.idGestao = idGestao;
            
            await gerarOrcamento(item);

            fecharModal()
        } catch (erro: any) {
            toast.error(erro.response.data.message);
        }
    }

    const calcularPlano = async () => {
        if (gestao) {
            const numberInputs = document.querySelectorAll("input[type=number]") as NodeListOf<HTMLInputElement>;
        
            const valores: PlanoUsuarioGestao & { parceira: boolean } = {
                periodicidadeMoedas: periodicidade,
                parceira: gestao.parceira 
            }
        
            type ObjectKey = keyof typeof valores;
        
            for (const input of numberInputs) {
                const id = input?.id as ObjectKey;
        
                valores[id] = parseInt(input?.value || "0") as never;
            }

            try {
                const { data } = await calcularPlanos(valores);
        
                setPlanosCalculados(data);
            } catch (erro: any) {
                toast.error(erro.response.data.message)
            }
        }
    }

    useEffect(() => {
        if (planosCalculados)
            containerCalculo?.current?.scrollIntoView({ behavior: "smooth" })

    }, [planosCalculados])

    useEffect(() => {
        if (gestao?.parceira) {
            const valores: PlanoUsuarioGestao = {
                qtdeCorrecaoCursinho: 4,
                qtdeCorrecaoFundamental: 4,
                qtdeCorrecaoPrimeiro: 4,
                qtdeCorrecaoSegundo: 4,
                qtdeCorrecaoTerceiro: 4,
                qtdeCorrecaoQuarto: 4
            }

            setPlano(valores);
        }
    }, [])

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (plano) {
            const valores = { ...plano! };

            type ObjectKey = keyof typeof valores;

            const id = e.target.id as ObjectKey;

            valores[id] = parseInt(e.target.value) as never;

            setPlano(valores);
        }
    }

    return (
        <>
            <Modal open={open}>
                <BoxModal style={{ maxHeight: "80vh", overflowY: "scroll", width: "70vw" }}>
                    <Grid container>

                        <Grid container display="flex" justifyContent="space-between" mb="1rem">
                            <h3>Cadastrar nova secretaria</h3>
                            <CloseOutlined onClick={fecharModal} />
                        </Grid>

                        <Grid>
                            <Grid container>
                                <Styled.LegendaCheckBox>Gestão</Styled.LegendaCheckBox>
                            </Grid>
                            <Select style={{ borderRadius: "60px", fontSize: "14px", width: "300px" }} value={idGestao} onChange={(e) => setIdGestao(e.target.value as number)}>
                                <MenuItem value={0}>-- Selecione uma gestão --</MenuItem>
                                {listaGestao?.map(item => <MenuItem value={item.id}>{item.nome}</MenuItem>)}
                            </Select>
                        </Grid>
                        {!gestao?.parceira &&
                            <Grid mb="2rem" ml="1rem">
                                <Grid container>
                                    <Styled.LegendaCheckBox>Periodicidade moedas</Styled.LegendaCheckBox>
                                </Grid>
                                <Select style={{ borderRadius: "60px", fontSize: "14px", width: "300px" }} value={periodicidade} onChange={(e) => setPeriodicidade(e.target.value as 1 | 3 | 6 | 12)}>
                                    <MenuItem value={1} key={1}>Mensal</MenuItem>
                                    <MenuItem value={3} key={3}>Trimestral</MenuItem>
                                    <MenuItem value={6} key={6}>Semestral</MenuItem>
                                    <MenuItem value={12} key={12}>Anual</MenuItem>
                                </Select>
                                <Styled.DescricaoInput>Frequência de entrega das moedas</Styled.DescricaoInput>
                            </Grid>
                        }
                        <Grid xs={12}>
                            <Styled.Explicacao>Selecione de acordo com os eixos que os alunos pertencem: </Styled.Explicacao>
                        </Grid>

                        <Grid container justifyContent="space-between">
                            <Styled.GridItem mt="2rem">
                                <Grid container>
                                    <Styled.CheckBox size="medium" checked={ensinoFundamental} onChange={(e) => setEnsinoFundamental(e.target.checked)} />
                                    <Styled.LegendaCheckBox>Ensino fundamental</Styled.LegendaCheckBox>
                                </Grid>
                                {ensinoFundamental &&
                                    <Grid container mt="1rem">
                                        <Grid xs={12}>
                                            <Styled.Texto>Insira a quantidade de alunos total no ensino fundamental e quantas redações corrigidas pretende-se fazer</Styled.Texto>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Styled.LegendaInput>Alunos ens. fundamental</Styled.LegendaInput>
                                            <Grid container justifyContent="space-between">
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeAlunoFundamental"
                                                        type="number"
                                                        id="qtdeAlunoFundamental"
                                                        value={plano?.qtdeAlunoFundamental}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                    />
                                                    <Styled.DescricaoInput>Quantidade de alunos</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeCorrecaoFundamental"
                                                        type="number"
                                                        id="qtdeCorrecaoFundamental"
                                                        value={plano?.qtdeCorrecaoFundamental}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                        disabled={gestao?.parceira}
                                                    />
                                                    <Styled.DescricaoInput>Correções por aluno</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Styled.GridItem>

                            <Styled.GridItem mt="2rem">
                                <Grid container>
                                    <Styled.CheckBox size="medium" checked={ensinoMedio} onChange={(e) => setEnsinoMedio(e.target.checked)} />
                                    <Styled.LegendaCheckBox>Ensino médio</Styled.LegendaCheckBox>
                                </Grid>
                                {ensinoMedio &&
                                    <Grid container mt="1rem">
                                        <Grid xs={12}>
                                            <Styled.Texto>Insira a quantidade de alunos em cada uma das séries e quantas correções por aluno pretende-se fazer</Styled.Texto>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Styled.LegendaInput>1º Ano</Styled.LegendaInput>
                                            <Grid container justifyContent="space-between">
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeAlunoPrimeiro"
                                                        type="number"
                                                        id="qtdeAlunoPrimeiro"
                                                        value={plano?.qtdeAlunoPrimeiro}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                    />
                                                    <Styled.DescricaoInput>Quantidade de alunos</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeCorrecaoPrimeiro"
                                                        type="number"
                                                        id="qtdeCorrecaoPrimeiro"
                                                        value={plano?.qtdeCorrecaoPrimeiro}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                        disabled={gestao?.parceira}
                                                    />
                                                    <Styled.DescricaoInput>Correções por aluno</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Styled.LegendaInput>2º Ano</Styled.LegendaInput>
                                            <Grid container justifyContent="space-between">
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeAlunoSegundo"
                                                        type="number"
                                                        id="qtdeAlunoSegundo"
                                                        value={plano?.qtdeAlunoSegundo}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                    />
                                                    <Styled.DescricaoInput>Quantidade de alunos</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeCorrecaoSegundo"
                                                        type="number"
                                                        id="qtdeCorrecaoSegundo"
                                                        value={plano?.qtdeCorrecaoSegundo}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                        disabled={gestao?.parceira}
                                                    />
                                                    <Styled.DescricaoInput>Correções por aluno</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Styled.LegendaInput>3º Ano</Styled.LegendaInput>
                                            <Grid container justifyContent="space-between">
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeAlunoTerceiro"
                                                        type="number"
                                                        id="qtdeAlunoTerceiro"
                                                        value={plano?.qtdeAlunoTerceiro}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                    />
                                                    <Styled.DescricaoInput>Quantidade de alunos</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeCorrecaoTerceiro"
                                                        type="number"
                                                        id="qtdeCorrecaoTerceiro"
                                                        value={plano?.qtdeCorrecaoTerceiro}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                        disabled={gestao?.parceira}
                                                    />
                                                    <Styled.DescricaoInput>Correções por aluno</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                            </Grid>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Styled.LegendaInput>4º Ano</Styled.LegendaInput>
                                            <Grid container justifyContent="space-between">
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeAlunoQuarto"
                                                        type="number"
                                                        id="qtdeAlunoQuarto"
                                                        value={plano?.qtdeAlunoQuarto}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                    />
                                                    <Styled.DescricaoInput>Quantidade de alunos</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeCorrecaoQuarto"
                                                        type="number"
                                                        id="qtdeCorrecaoQuarto"
                                                        value={plano?.qtdeCorrecaoQuarto}
                                                        placeholder="00"
                                                        onChange={handleChange}
                                                        disabled={gestao?.parceira}
                                                    />
                                                    <Styled.DescricaoInput>Correções por aluno</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Styled.GridItem>

                            <Styled.GridItem mt="2rem">
                                <Grid container>
                                    <Styled.CheckBox size="medium" checked={cursinho} onChange={(e) => setCursinho(e.target.checked)} />
                                    <Styled.LegendaCheckBox>Cursinho</Styled.LegendaCheckBox>
                                </Grid>
                                {cursinho &&
                                    <Grid container mt="1rem">
                                        <Grid xs={12}>
                                            <Styled.Texto>Insira a quantidade de alunos total de cursinho e quantas redações corrigidas pretende-se fazer</Styled.Texto>
                                        </Grid>
                                        <Grid xs={12}>
                                            <Styled.LegendaInput>Alunos de cursinho</Styled.LegendaInput>
                                            <Grid container justifyContent="space-between">
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeAlunoCursinho"
                                                        type="number"
                                                        id="qtdeAlunoCursinho"
                                                        value={plano?.qtdeAlunoCursinho}
                                                        onChange={handleChange}
                                                        placeholder="00"
                                                    />
                                                    <Styled.DescricaoInput>Quantidade de alunos</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                                <Styled.GridInput>
                                                    <InputText
                                                        name="qtdeCorrecaoCursinho"
                                                        type="number"
                                                        id="qtdeCorrecaoCursinho"
                                                        value={plano?.qtdeCorrecaoCursinho}
                                                        onChange={handleChange}
                                                        placeholder="00"
                                                        disabled={gestao?.parceira}
                                                    />
                                                    <Styled.DescricaoInput>Correções por aluno</Styled.DescricaoInput>
                                                </Styled.GridInput>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }
                            </Styled.GridItem>
                        </Grid>

                        {(ensinoFundamental || ensinoMedio || cursinho) &&
                            <Styled.GridFinal container>
                                <Styled.GridFinal xs={12} sm={12} md={8} lg={6} xl={4}>
                                    <Styled.BotaoContinuar color="secondary" onClick={calcularPlano}>{planosCalculados ? "Recalcular planos" : "Calcular planos"}</Styled.BotaoContinuar>
                                </Styled.GridFinal>
                            </Styled.GridFinal>
                        }

                        {planosCalculados &&
                            <Grid container ref={containerCalculo}>
                                <Grid xs={12}>
                                    <Styled.TituloPlanos>Confira nossos planos exclusivos para sua necessidade</Styled.TituloPlanos>
                                </Grid>
                                <Grid container>
                                    <Styled.GridCentro container>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell align="center">Plano</TableCell>
                                                        <TableCell align="center">Preço correção</TableCell>
                                                        <TableCell align="center">Ensino fundamental</TableCell>
                                                        <TableCell align="center">Ensino médio</TableCell>
                                                        <TableCell align="center">Cursinho</TableCell>
                                                        <TableCell align="center">Valor Total</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {planosCalculados.map(item => {
                                                        if (item) {
                                                            return (
                                                                <TableRow>
                                                                    <TableCell align="center">{item.descricao}</TableCell>
                                                                    <TableCell align="center">R$ {item.valorCorrecao.toLocaleString("pt-BR", options)}</TableCell>
                                                                    <TableCell align="center">R$ {item.valorTotalEnsinoFundamental.toLocaleString("pt-BR", options)}</TableCell>
                                                                    <TableCell align="center">R$ {item.valorTotalEnsinoMedio.toLocaleString("pt-BR", options)}</TableCell>
                                                                    <TableCell align="center">R$ {item.valorTotalCursinho.toLocaleString("pt-BR", options)}</TableCell>
                                                                    <TableCell align="center">R$ {item.plano.valorTotal?.toLocaleString("pt-BR", options)}</TableCell>
                                                                    <TableCell align="center" style={{ borderBottom: "none" }}>
                                                                        <Button onClick={() => escolherPlano(item.plano)}>Escolher</Button>
                                                                    </TableCell>
                                                                </TableRow>
                                                            )
                                                        }
                                                    }
                                                    )}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Styled.GridCentro>
                                </Grid>
                            </Grid>
                        }

                    </Grid>
                </BoxModal>
            </Modal>
        </>
    )
}