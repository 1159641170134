import {api} from "api/services/axios";

export const ObterNumeroDeUsuariosRegistrados = async () => {
    const { data } = await api.get('/dashboard/numeroDeUsuariosRegistrados')
    return data
};

export const ObterNumeroDeAlunosRegistrados = async () => {
    const { data } = await api.get('/dashboard/numeroDeAlunosRegistrados')
    return data
};

export const ObterNumeroDeProfessoresRegistrados = async () => {
    const { data } = await api.get('/dashboard/numeroDeProfessoresRegistrados')
    return data
};

export const ObterNumeroDeEscolasRegistradas = async () => {
    const { data } = await api.get('/dashboard/numeroDeEscolasRegistradas')
    return data
};

export const ObterNovosUsuariosTimeSeries = async () => {
    const { data } = await api.get('/dashboard/novosUsuariosTimeSeries')
    return data
};

export const ObterStatusUAT = async () => {
    const { data } = await api.get('/monitoramento/uat_status')
    return data
};

export const ObterStatusPROD = async () => {
    const { data } = await api.get('/monitoramento/prod_status')
    return data
};


export const ObterRedacoesAITimeSeries = async () => {
    const { data } = await api.get('/dashboard/redacoesAIPorTempo')
    return data
};


export const ObterredacoesProfessorPorTempo = async () => {
    const { data } = await api.get('/dashboard/redacoesProfessorPorTempo')
    return data
};