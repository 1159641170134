import { Grid, Menu } from "@mui/material";
import { useDispatch } from "hooks/useRedux";
import { useState } from "react";
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { logout as logoutAction } from "reducers/authentication";
import * as Styled from "./HearderMenu.styled";

export default function HeaderMenu() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [elementoAncora, setElementoAncora] = useState<null | HTMLElement>(null);
    const abrir = Boolean(elementoAncora);
    const abrirHeaderMenu = (event: React.MouseEvent<HTMLElement>) => {
        setElementoAncora(event.currentTarget);
    };
    const fecharHeaderMenu = () => {
        setElementoAncora(null);
    };
    const logoutHandler = async () => {
        sessionStorage.removeItem("access_token");
        localStorage.removeItem("access_token");
        sessionStorage.removeItem("username");
        localStorage.removeItem("username");
        sessionStorage.removeItem("permanecerConectado");
        localStorage.removeItem("permanecerConectado");
        sessionStorage.removeItem("refreshToken");
        localStorage.removeItem("refreshToken");
        sessionStorage.removeItem("token");
        localStorage.removeItem("token");
        dispatch(logoutAction());
        navigate("/");
    };

    return (
        <Grid>
            <Styled.Icon onClick={abrirHeaderMenu}>
                {abrir ? (<RiArrowUpSLine />) : (<RiArrowDownSLine />)}
            </Styled.Icon>

            <Menu
                anchorEl={elementoAncora}
                open={abrir}
                onClose={fecharHeaderMenu}
                onClick={fecharHeaderMenu}
                PaperProps={{
                    elevation: 0
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <Grid>
                    <Styled.MenuItemCentro tipo="normal" onClick={() => navigate("/meusDados")}>Meus dados</Styled.MenuItemCentro>        
                    <Styled.MenuItemCentro tipo="normal" onClick={() => navigate("/registrarAdmin")}>Registrar novo Admin</Styled.MenuItemCentro>
                    <Styled.MenuItemCentro tipo="sair" onClick={logoutHandler}>Sair</Styled.MenuItemCentro>
                </Grid>
            </Menu>
        </Grid>
    );
}
