export const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    minimumIntegerDigits: 1,
    useGrouping: true,
    currency: "BRL"
};

export type FormValues = {
    cvv: string;
    documento: string;
    nomeCartao: string;
    numeroCartao: string;
    vencimento: string;
    parcelas?: number;
}

export const initialValues: FormValues = {
    cvv: "",
    documento: "",
    nomeCartao: "",
    numeroCartao: "",
    vencimento: "",
    parcelas: 0
}