import { getIsLoggedIn, getUserCredentials } from "reducers/authentication";
import AdminBackEndRoute from "./admin_backend_route";
import AdminRoute from "./admin_route";
import DeslogadoRoute from "./deslogado_route"
import { useSelector } from "hooks/useRedux";

const Routes: React.FC = () => {
    const isLoggedIn = useSelector(getIsLoggedIn);
    const { perfil } = useSelector(getUserCredentials);

    if (isLoggedIn) {
        if (perfil === "AdminIa")
            return <AdminRoute />

        if (perfil === "AdminBackEnd")
            return <AdminBackEndRoute />
    }

    return <DeslogadoRoute />
}

export default Routes
