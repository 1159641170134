import { styled } from '@mui/material/styles';
import { Box, Container, Grid } from '@mui/material';

export const StyledLayoutContainer = styled(Box)(({ theme }) => ({
    height: "100%",
    width: "100%",
    margin: 0,
    padding: 0,
    overflowY: "auto",
    background:  "#ffffff",
}));

export const wrapperContainer = styled(Container)(({ theme }) => ({
    height: '100%',
    width: '100% !important',
    margin: '0',
    padding: '0',
    display: 'flex',
    flexDirection: 'column',
    background: '#172935',
    maxWidth: '100% !important',
    paddingLeft: '0 !important',
    paddingRight: '0 !important',

    '&:_content': {
        width: '100%',
        overflowY: 'auto',
    },   
}));

export const wrapperMainDiv = styled('div')(({ theme }) => ({
    display: 'flex',
    height: '100% !important',
}));

export const wrapper_contentDiv = styled('div')({
    
    background: '#EEEEEE',
    overflowX: 'hidden',
    marginTop: '72px',
    flexBasis: "100%",

    ['@media (min-width: 1200px)']: {
        width: '100%',
        display: 'block',
    },
})

export const containerContentAluno = styled(Grid)<{ isMargin: boolean }>(({ theme, isMargin }) => ({
    width: '100% !important',
    background: theme.palette.grey[100],
    ...(isMargin && {
        marginTop: theme.spacing(5),
    }),
    [theme.breakpoints.down("lg")]: {
        height: theme.spacing(5)
    }
}));

export const bodyyy = styled('body')(({ theme }) => ({
    backgroundColor: '#F8F9FA',
}));

export const wrapper_content_notLoggedd = styled('div')(({ theme }) => ({
    flexBasis: '100%',
    background: 'white',
    overflowx: 'hidden',

}));

export const wrapper_content_loadingg = styled('div')(({ theme }) => ({
    color: 'white',
    textAlign: 'center',
    paddingTop: '400px',
    background: '#87878761',

}));

export const wrapper_content_notLogge = styled("div")(({ theme }) => ({


    "&::-webkit-scrollbar": {
        width: '10px',
        backgroundColor: '#F5F5F5',
    },

    "&::-webkit-scrollbar-thumb": {
        borderRadius: '10px',
        webkitBoxShadow: 'inset 0 0 6px #172935',
        backgroundColor: '#555',
    },
}));