import { FormHelperText } from "@mui/material"
import { InputText } from "components/Inputs"
import { InputMask } from "components/Inputs/InputMask"
import { SelectEstadosCidades } from "components/SelectEstadosCidades"
import { EnderecoFormProps } from "./EnderecoForm.types"
import axios from "axios"
import { useEffect } from "react"
import { toast } from "react-toastify"
import * as Styled from "./EnderecoForm.styled";

export const EnderecoForm = ({ formik: { errors, values, touched, handleChange, handleBlur, setFieldValue } }: EnderecoFormProps) => {
    useEffect(() => {
        if (values?.cep?.replace(/\s/g, '').length === 9) {
            axios.get(`https://viacep.com.br/ws/${values.cep}/json`)
                .then((response) => {
                    if (response?.data?.erro) {
                        toast.error('CEP Inválido!')
                    } else {
                        setFieldValue("rua", response?.data?.logradouro)
                        setFieldValue("cidade", response?.data?.localidade)
                        setFieldValue("estado", response?.data?.uf)
                        setFieldValue("bairro", response?.data?.bairro)
                    }
                });
        }
    }, [values.cep])

    return (
        <>
            <Styled.FormControlGeneric xs={12} md={3}>
                <Styled.FormLabelGeneric error={!!errors.cep && touched.cep}>CEP</Styled.FormLabelGeneric>
                <Styled.StyledInputMask
                    mask="99999-999"
                    maskChar=""
                    placeholder="Ex.: 00000-00"
                    name="cep"
                    id="cep"
                    type="text"
                    onBlur={handleBlur}
                    value={values.cep}
                    onChange={handleChange}
                />

                {touched.cep && !!errors.cep &&
                    <FormHelperText id="cep-label" error={touched.cep && !!errors.cep}>
                        {errors.cep}
                    </FormHelperText>
                }
            </Styled.FormControlGeneric>

            <Styled.FormControlGeneric xs={12} md={3}>
                <Styled.FormLabelGeneric error={!!errors.rua && touched.rua}>Rua</Styled.FormLabelGeneric>
                <Styled.StyledInputText
                    placeholder="Ex.: Rua João Pessoa"
                    name="rua"
                    id="rua"
                    type="text"
                    onBlur={handleBlur}
                    value={values.rua}
                    onChange={handleChange}
                    error={!!errors.rua && touched.rua}
                />

                {touched.rua && !!errors.rua &&
                    <FormHelperText id="rua-label" error={touched.rua && !!errors.rua}>
                        {errors.rua}
                    </FormHelperText>
                }
            </Styled.FormControlGeneric>

            <Styled.FormControlGeneric xs={12} md={3}>
                <Styled.FormLabelGeneric error={!!errors.numero && touched.numero}>Número</Styled.FormLabelGeneric>
                <Styled.StyledInputText
                    placeholder="Ex.: 0000"
                    name="numero"
                    id="numero"
                    type="text"
                    onBlur={handleBlur}
                    value={values.numero}
                    onChange={handleChange}
                    error={!!errors.numero && touched.numero}
                />

                {touched.numero && !!errors.numero &&
                    <FormHelperText id="numero-label" error={touched.numero && !!errors.numero}>
                        {errors.numero}
                    </FormHelperText>
                }
            </Styled.FormControlGeneric>

            <Styled.FormControlGeneric xs={12} md={3}>
                <Styled.FormLabelGeneric>Complemento</Styled.FormLabelGeneric>
                <Styled.StyledInputText
                    placeholder="Ex.: Casa 3"
                    name="complemento"
                    id="complemento"
                    type="text"
                    onBlur={handleBlur}
                    value={values.complemento}
                    onChange={handleChange}
                />
            </Styled.FormControlGeneric>

            <SelectEstadosCidades xs={12} md={3} padding="0.5rem"
                ElementoDescricao={Styled.FormLabelGeneric}
                cidadeSelecionada={values.cidade ?? ""}
                estadoSelecionado={values.estado ?? ""}
                onCidadeChange={(e) => setFieldValue("cidade", e.target.value)}
                onEstadoChange={(e) => setFieldValue("estado", e.target.value)}
                errorCidade={!!errors.cidade && touched.cidade}
                errorEstado={!!errors.estado && touched.estado}
            /></>
    )
}