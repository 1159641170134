import { Button, Card, Checkbox, Grid, TableCell, Typography, styled } from "@mui/material";
import { BsFillCheckCircleFill } from "react-icons/bs";
import InfoIcon from "@mui/icons-material/Info";

export const GridCentro = styled(Grid)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

export const BotaoContinuar = styled(Button)(({ theme, disabled }) => ({
    marginTop: theme.spacing(1.25),
    width: "50%",

    [theme.breakpoints.down("sm")]: {
        width: "100%"
    },
    ...(disabled && {
        filter: "grayscale(1)"
    })
}));

export const GridFinal = styled(Grid)({
    display: "flex",
    justifyContent: "end",
    alignItems: "end"
});

export const Explicacao = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(1.25),
    color: theme.palette.grey[700]
}));

export const GridItem = styled(Grid)(({ theme }) => ({
    [theme.breakpoints.up("md")]: {
        width: "32%"
    },
    [theme.breakpoints.down("md")]: {
        width: "100%"
    }
}));

export const CheckBox = styled(Checkbox)(({ theme }) => ({
    padding: 0,
    marginRight: theme.spacing(0.5)
}));

export const IconeInformacao = styled(InfoIcon)(({ theme }) => ({
    cursor: "pointer",
    color: theme.palette.info.main,
    width: theme.spacing(1.0625),
    marginLeft: theme.spacing(0.3125)
}));

export const LegendaCheckBox = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.grey[700]
}));

export const Texto = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.875),
    color: theme.palette.grey[600]
}));

export const LegendaInput = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(1),
    fontWeight: 600,
    color: theme.palette.grey[700],
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
}));

export const GridInput = styled(Grid)({
    width: "49%"
});

export const DescricaoInput = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(0.75),
    color: theme.palette.grey[700]
}));

export const TituloPlanos = styled(Typography)(({ theme }) => ({
    fontSize: theme.spacing(1.75),
    color: theme.palette.secondary.dark,
    marginTop: theme.spacing(2),
    textAlign: "justify"
}));