import React from "react";
import * as Styled from "./Logo.styled";
import { LogoProps } from "./Logo.types";

export const Logo = ({ variant, size = "sm", className }: LogoProps) => {
    return (
        <Styled.Wrapper variant={variant} size={size} className={className}>
       <svg className="SVGLogo" xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 119.49">
            <g id="Camada_2" data-name="Camada 2">
                <g id="Camada_1-2" data-name="Camada 1">
                    <path 
                    className="cls-1"                   
                    d="M10.74,8.7A30.16,30.16,0,0,1,31.8.65H133.32v26h-90a11.11,11.11,0,0,0-1.62.16V58.65h-9.6V84.6a11,11,0,0,0,3.33,8,10.71,10.71,0,0,0,7.89,3.25h90v23.51H31.8A30.16,30.16,0,0,1,15.86,115H126.08l-3.5-2.61H12.12a32.07,32.07,0,0,1-8.87-10.9A30.32,30.32,0,0,1,0,87.52V55.15H11V29H.24A32,32,0,0,1,10.74,8.7Z"/>
                    <path 
                    className="cls-1" 
                    d="M267,98.34h12.53v8.22h-8l6.59,12H247.28L237,99.89h-4.4L218.48,75.73h5.13L220,69.22H171.47V118.6H140.64V103.06h6.18V75.57h-6.18V0h107a28.81,28.81,0,0,1,14.89,4.07A30.91,30.91,0,0,1,273.23,14.8a28.41,28.41,0,0,1,4,14.89v9.92a28.45,28.45,0,0,1-7.48,19.69,29.94,29.94,0,0,1-18.87,9.76l.08.16h3.5L261.27,81h-3.82ZM148.2,19.44H146V69.55h2.19Zm98.35,11.39a6.67,6.67,0,0,0-6.67-6.67H178.63v6.91h-7.16V43.76h68.41a6.67,6.67,0,0,0,6.67-6.67Z"/>
                    <path className="cls-2" 
                    d="M317.31,72.48H325V88.42h-7.65v31.07h-30.5V90.13h7.73V73h-7.73V.73h30.5ZM312.92,7h-2V67.27h2Z"/>
                    <path className="cls-2" 
                    d="M354.4,75.16,403,.81V.41h19.6v.4l22.13,33.84h24.56l-6,7.57H449.65l8,12.2h4.64l30.83,46.28h-5.21L500,119.33H470.07L454,97.69h7.73L433.06,54.5l-9-.16L412.8,37.17l-24.4,38ZM384,81.91l-.65,1h6.26v9.27h-12.2L360,119.33H325.52l24.41-37.42Z"/>
                </g>
            </g>
        </svg>
        </Styled.Wrapper>
    );
};

